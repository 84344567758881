import styled from 'styled-components';

const Container = styled.div`
    position: fixed;
    left: 0;
    right: auto;
    height: 100%;
    width: ${props => props.isVisible ? (props.isSubActive ? '320px' : '290px') : '0'};
    opacity: ${props => props.isVisible ? '1' : '0'};
    visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
    padding-top: ${props => props.headerHeight ? props.headerHeight : '75px'};
    background: #FFF;
    box-shadow: 0px 6px 6px rgb(0 0 0 / 40%);
    transition: .7s width ease, left .7s ease, opacity .7s ease, visibility .7s ease ;
    z-index: 10;

    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
        display: none;
    }
    @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
        right: 0;
        left: auto;
        padding-top: 75px;
    }
`;

const PrimaryBtn = styled.a`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 51px;
    padding: 16px 32px;
    margin: 12px 0;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: solid 2px transparent;
    color: #FFF;
    background: #001EFF;
    cursor: pointer;

    &:hover {
        color: #000;
        background: #FFF;
        border: solid 2px #000;
    }
`;

const Nav = styled.div`
    position: relative;
    height: 100%;
    padding: 16px 32px;
`;

const Menu = styled.div``;

const MenuItem = styled.a`
    display: block;
    padding: 12px 0;
    line-height: 16px;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
        color: #001EFF;
    }

    i {
        padding-left: 6px;
        font-weight: 600;
    }
`;

const SubMenu = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 290px;
    right: ${props => props.isVisible ? '30px' : '290px'};
    opacity: ${props => props.isVisible ? '1' : '0'};
    visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
    padding: 16px 32px;
    background-color: #EDEDEE
    transition: .7s left ease, .7s right ease, visibility ease .7s, opacity ease .7s;
    overflow-y: scroll
    cursor: pointer;

    @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
        left: ${props => props.isVisible ? '30px' : '290px'};
    }
`;

const SubContainer = styled.div`
    @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
        padding-bottom: 90px;
    }
`;

const SubHeading = styled.div`
    margin-bottom: 24px;
    font-family: Anton;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;

`;

const Category = styled.div`
    margin: 0;
    font-weight: 700;
`;

const CatHeading = styled.div`
`;

const CatList = styled.div`
    margin-top: 12px;
    margin-bottom: 24px;
    border-left: 1px solid #9F9FA0;
`;

const CatItem = styled.a`
    display: block;
    padding: 12px;
    color: #1F2440;
    text-decoration: none;
    cursor: pointer;

    i {
        padding-left: 6px;
        font-size: 14px;
        font-weight: 600;
    }

     &:hover {
        color: #001EFF;
    }
`;

const StorySection = styled.div`
    padding-left: 24px;
    padding-bottom: 24px;
`;

const StoryItem = styled.div`
    a {
        display: block;
        padding-bottom: 8px;
        font-size: ${props => props.isPrimary ? '24px' : '16px'};
        text-decoration: none;
        color: #1F2440;
        transition: color .3s ease,background-color .3s ease;
    
        &:hover {
            span {
                color: #001EFF;
                border-bottom: solid 3px #000EFF;
            }
        }
    }
`;

const Back = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Icon = styled.a`
    text-align: right;

    i {
        padding-right: 6px;
        font-size: 14px;
        font-weight: 600;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
        display: block;
    }
`
;
export { Container, PrimaryBtn, Nav, Menu, MenuItem, SubMenu, SubContainer, SubHeading, Category, CatHeading, CatList, CatItem, StorySection, StoryItem, Back, Icon };