import styled, { css } from 'styled-components';

const Container = styled.div<{ backgroundColor: string, isVideo:boolean, hasMedia: boolean }>`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        flex-direction: row;
    }

    ${props=> props.backgroundColor !== 'light' && getStyles('container', props.backgroundColor, props.isVideo, undefined, props.hasMedia)};
`;

const MediaContainer = styled.div<{ backgroundColor: string, isVideo:boolean, imageAlignment: string }>`
    ${props=> getStyles('mediaContainer', props.backgroundColor, props.isVideo, props.imageAlignment)};
`;

const Media = styled.div<{ backgroundColor: string, isVideo:boolean, imageAlignment: string }>`
    flex: 1.4;

    ${props=> props.backgroundColor !== 'light' && getStyles('media', props.backgroundColor, props.isVideo, props.imageAlignment)};
`;

const Image = styled.div<{ backgroundColor: string, imageAlignment: string }>`
    position: relative;
    padding-bottom: 56%;
    img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }

    ${props=> props.backgroundColor !== 'light' && getStyles('image', props.backgroundColor, false, props.imageAlignment)};
`;

const Video = styled.div<{ backgroundColor: string }>`
    position: relative;
    padding-bottom: 56%;
    img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }

    ${props=> props.backgroundColor !== 'light' && getStyles('video', props.backgroundColor)};
`;

const Play = styled.a<{ backgroundColor: string }>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    background-color: ${props => props.backgroundColor === 'light' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(22, 23, 25, 0.8)'};
    cursor: pointer;

    svg {
        font-size: 19px;
        color: ${props => props.backgroundColor === 'light' ? props.theme.palette.primary.normal : props.theme.palette.secondary.normal};
    }
`;

const VideoContainer = styled.iframe`
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const ContentContainer = styled.div<{ backgroundColor: string, isVideo:boolean, hasMedia: boolean }>`
    ${props=> getStyles('contentContainer', props.backgroundColor, props.isVideo, undefined, props.hasMedia)};
`;

const Content = styled.div<{ backgroundColor: string, isVideo:boolean, hasMedia: boolean }>`
    flex: 1;
    padding: 16px;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        padding: 36px;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
        padding: 58px;
    }

    ${props=> props.backgroundColor !== 'light' && getStyles('content', props.backgroundColor, props.isVideo, undefined, props.hasMedia)};
`;

const Title = styled.h1<{ backgroundColor: string, isVideo:boolean, hasMedia: boolean }>`
    font-family: Anton;
    font-size: 44px;
    font-weight: 400;
    line-height: 95%;
    text-transform: uppercase;
    margin: 0;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        font-size: 50px;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
        font-size: 68px;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
        font-size: 86px;
    }

    ${props=> props.backgroundColor !== 'light' && getStyles('title', props.backgroundColor, props.isVideo, undefined, props.hasMedia)};
`;

const Description = styled.div<{ backgroundColor: string, isVideo:boolean, hasMedia: boolean }>`
    margin: 20px 0;
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
        font-size: 18px;
    }

    a, a:visited {
        color: inherit;

    }

    ${props=> props.backgroundColor !== 'light' && getStyles('description', props.backgroundColor, props.isVideo, undefined, props.hasMedia)};
`;

const SubHeading = styled.h3<{ backgroundColor: string, isVideo:boolean, hasMedia: boolean }>`
    font-family: Overpass;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    margin: 0;
    padding: 0px 0px 24px 0px;

    ${props=> props.backgroundColor !== 'light' && getStyles('subHeading', props.backgroundColor, props.isVideo, undefined, props.hasMedia)};
`;

const ButtonContainerBottom = styled.div<{ backgroundColor: string, isVideo:boolean, imageAlignment: string }>`
    ${props=> getStyles('buttonContainerBottom', props.backgroundColor, props.isVideo, props.imageAlignment)};
`;

const ButtonContainerTop = styled.div<{ backgroundColor: string, isVideo:boolean, imageAlignment: string }>`
    ${props=> getStyles('buttonContainerTop', props.backgroundColor, props.isVideo, props.imageAlignment)};
`;

const BackgroundVideoContainerMobile = styled.div`
    position: absolute;
    overflow: hidden;
    z-index: -1;
    width: 100%;
    height: 100%

    video {
        min-width: 100%; 
        max-height: 100%;
        object-fit: cover;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.xs}){
        display: none;
    }
`;

const BackgroundVideoContainerDesktop = styled.div`
    position: absolute;
    overflow: hidden;
    z-index: -1;
    width: 100%;
    height: 100%
    display: none;

    video {
        min-width: 100%; 
        max-height: 100%;
        object-fit: cover;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.xs}){
        display: block;
    }
`;

const BackgroundImageMobile = styled.div<{isBackgroundVideoLoaded: boolean}>`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    ${props => props.isBackgroundVideoLoaded? 'display: none;' : ''}

    img {
        width: 100%;
        height: 100%;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.xs}){
        display: none;
    }
`;

const BackgroundImageDesktop = styled.div<{isBackgroundVideoLoaded: boolean}>`
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;

    img {
        width: 100%;
        height: 100%;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.xs}){
        ${props => props.isBackgroundVideoLoaded? 'display: none;' : 'display: block;'}
    }
`;


const getStyles = (property: string, theme: string, isVideo?: boolean, imageAlignment?: string, hasMedia?: boolean) => {
    const styles = {
      light: {
        buttonContainerTop(){
            return css`
                display: none;
            `;
        },
      },
      default: { // dark mode
        container(){
            return css`
                color:#FFFFFF;
                background-color:#161719;
                flex-direction: column-reverse;
            `;
        },
        title(){
            return css`
                font-family: Anton;
                font-size: ${hasMedia ? '60px' : '48px'};
                font-weight: 400;
                line-height: ${hasMedia ? '0.95' : '50px'};
                -webkit-font-smoothing: antialiased;

                @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.xs}){
                    font-size:  ${hasMedia ? '60px' : '48px'};
                }

                @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
                    font-size:  ${hasMedia ? '60px' : '48px'};
                }
            
                @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
                    font-size:  ${hasMedia ? '60px' : '48px'};
                }
            `;
        },
        subHeading(){
            return css`
                ${hasMedia? '' : 'padding: 0px 0px 12px 0px;'}
            `;
        },
        contentContainer(){
            return css`
               flex: 1;

               @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.xs}){
                    display: flex;
                    align-items: center;
               }

               @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
                    ${hasMedia? '' : 'justify-content: center; align-self: stretch;'}
               }
            `;
        },
        content(){
            return css`
                padding: ${hasMedia? '48px 32px 32px 32px': '48px 32px 64px 32px'};

                @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
                    padding: ${hasMedia? '48px' : '48px 48px 64px 48px'};
                    ${hasMedia? '' : 'max-width: 600px;'};
                }
            
                @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
                    padding: ${hasMedia? '48px' : '48px 48px 64px 48px'};
                }
            `;
        },
        description(){
            return css`
                margin: ${hasMedia? '24px 0px 0px 0px': '32px 0px 0px 0px'};

                p{
                    font-family: Overpass;
                    font-size: ${hasMedia? '20px': '16px'};
                    font-weight: 300;
                    line-height: ${hasMedia? '28px': '24px'};
                    letter-spacing: 0em;
                    text-align: left;
                    margin: 0px;
                    ${hasMedia? '' : 'margin-bottom: 32px;'}
                }

                p:last-child {
                    ${hasMedia? '' : 'margin-bottom: 0px;'}
                }
                
            `;
        },
        media(){
            return css`
                flex: 1;
                padding: ${isVideo || imageAlignment === 'center'? '32px':  '24px 0px 0px 0px;'};

                @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
                    ${isVideo || imageAlignment === 'center'? 'padding: 48px;': ''}
                }
            `;
        },
        mediaContainer(){
            return css`
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: ${isVideo || imageAlignment === 'center'? 'center':'flex-end'};
                width: 100%;
            `;
        },
        video(){
            return css`
                padding-bottom: 100%;

                @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
                    padding-bottom: 0;
                    height: 320px;
                    width: 320px;
                    margin: 0 auto;
                }
            `;
        },
        image(){
            return css`
                padding-bottom: 100%;

                @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
                    padding-bottom: 0;
                    width: ${imageAlignment === 'center'? '320px': '480px'};
                    height: ${imageAlignment === 'center'? '320px': '480px'};
                    margin: auto;
                }
            `;
        },
        buttonContainerTop(){
            return css`
                display: ${isVideo || imageAlignment === 'center' ? 'grid':'none'};
                ${isVideo || imageAlignment === 'center'? 'padding: 48px 0px;': ''}
                a {
                    left: 0;
                    right: 0;
                    text-wrap: nowrap;
                    height: 48px;
                    border-radius: 24px;
                    padding: 10px 22px;
                    border: 0;
                }

                @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.xs}){
                    display: none;
                }
            `;
        },
        buttonContainerBottom(){
            return css`
                display: ${isVideo || imageAlignment === 'center'? 'none':'grid'};
                margin: 24px 0px 32px 0px;

                a {
                    text-wrap: nowrap;
                    height: 48px;
                    border-radius: 24px;
                    padding: 10px 22px;
                    border: 0;
                }

                span {
                    font-family: Overpass;
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: 600;
                }

                @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.xs}){
                    display: block;
                    margin: 24px 0px 0px 0px;
                }
            `;
        },
      },
      campaign: {
        container(){
            return css`
                ${styles.default.container()}
                background-color: transparent;
                position: relative;
            `;
        },
        title(){
            return css`
                ${styles.default.title()}
                font-family: 'DAMN';
                font-weight: 400;
                font-size: 75px;
                line-height: 0.82;

                @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
                    font-size: 75px;
                }
            
                @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
                    font-size: 75px;
                }
            
                @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
                    font-size: 75px;
                }
            `;
        },
        subHeading(){
            return css`
                ${styles.default.subHeading()}
            `;
        },
        contentContainer(){
            return css`
                ${styles.default.contentContainer()}
                z-index: 5;
            `;
        },
        content(){
            return css`
                ${styles.default.content()}
            `;
        },
        description(){
            return css`
                ${styles.default.description()}
            `;
        },
        media(){
            return css`
                ${styles.default.media()}
            `;
        },
        mediaContainer(){
            return css`
                ${styles.default.mediaContainer()}
                z-index: 5;
            `;
        },
        video(){
            return css`
                ${styles.default.video()}
            `;
        },
        image(){
            return css`
                ${styles.default.image()}
            `;
        },
        buttonContainerTop(){
            return css`
                ${styles.default.buttonContainerTop()}
            `;
        },
        buttonContainerBottom(){
            return css`
                ${styles.default.buttonContainerBottom()}
            `;
        },
      },
      tcam : {
        container(){
            return css`
                ${styles.default.container()}
                color: ${props => props.theme.palette.mo.greyTone};
                background-image: url('/resources/images/content/tcam/background-mobile.png');
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;

                @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
                    background-image: url('/resources/images/content/tcam/background.png');
                }
            `;
        },
        title(){
            return css`
                ${styles.default.title()}
            `;
        },
        subHeading(){
            return css`
                ${styles.default.subHeading()}
            `;
        },
        contentContainer(){
            return css`
                ${styles.default.contentContainer()}
                z-index: 5;
            `;
        },
        content(){
            return css`
                ${styles.default.content()}
            `;
        },
        description(){
            return css`
                ${styles.default.description()}
            `;
        },
        media(){
            return css`
                ${styles.default.media()}
            `;
        },
        mediaContainer(){
            return css`
                ${styles.default.mediaContainer()}
                z-index: 5;
            `;
        },
        video(){
            return css`
                ${styles.default.video()}
            `;
        },
        image(){
            return css`
                ${styles.default.image()}
            `;
        },
        buttonContainerTop(){
            return css`
                ${styles.default.buttonContainerTop()}
            `;
        },
        buttonContainerBottom(){
            return css`
                ${styles.default.buttonContainerBottom()}
            `;
        },
      }
    };
  
    const selectedStyles = styles[theme as keyof typeof styles];
  
    return selectedStyles[property as keyof typeof selectedStyles] || '';
  };


export { 
    Container, 
    Media, 
    Image, 
    Video, 
    Play, 
    VideoContainer, 
    Content, 
    Title, 
    Description, 
    SubHeading, 
    ButtonContainerBottom, 
    ButtonContainerTop, 
    ContentContainer, 
    MediaContainer,
    BackgroundVideoContainerMobile,
    BackgroundVideoContainerDesktop,
    BackgroundImageMobile,
    BackgroundImageDesktop,
};