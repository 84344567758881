import styled from 'styled-components';

const CardContainer = styled.div`
    display: flex;
    flex-direction: ${ props => props.reverse ? 'row-reverse' : 'row'};
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
`;

const NewsImage = styled.div`
    flex: 1;
    height: 100%;
    width: 100%;

`;

const NewsContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 400px;
    padding: 2rem 0;
`;

const ContentTitle = styled.div`
    font-family: Anton;
    font-size: 36px;
    text-transform: uppercase;
    margin-top: 2rem;
    max-width: 500px;
`;

const ContentBody = styled.div`
    font-family: Overpass;
    font-size: 20px;
    margin-top: 2rem;
    max-width: 500px;
    text-align: center;
`;

const ContentButton = styled.button`
    font-family: Anton;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: 2rem;
    padding: 0 1rem;
    height: 50px;
    border: solid 2px black;

    &:hover {
        background-color: black;
        color: white;
    }
`;

export { CardContainer, NewsImage, NewsContent, ContentTitle, ContentBody, ContentButton } ;