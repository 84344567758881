import React from 'react'
import { 
    ContainerWrapper,
    Container, 
    BackgroundImageWrapperMobile,
    BackgroundImageWrapperTablet,
    BackgroundImageWrapperDesktop,
    SvgImageWrapper,
    HeaderTextContainer,
    Header } from './PageHeader.style';
import { renderImage } from 'components/utils/render'
import DOMPurify from 'dompurify';

interface Props {
    backgroundImageDesktop: string;
    backgroundImageMobile: string;
    backgroundImageTablet: string;
    foregroundSvg?: string;
    headerHeightStyle: string;
    text?: string;
}

const PageHeader = ({ backgroundImageDesktop, backgroundImageTablet, backgroundImageMobile, foregroundSvg, headerHeightStyle, text }: Props) => {
    const sanitizedSvg = DOMPurify.sanitize(foregroundSvg ?? '');
  return (
    <ContainerWrapper>
        <Container headerHeightStyle={headerHeightStyle}>
            <BackgroundImageWrapperMobile>
                {renderImage(backgroundImageMobile)}
            </BackgroundImageWrapperMobile>
            <BackgroundImageWrapperTablet>
                {renderImage(backgroundImageTablet)}
            </BackgroundImageWrapperTablet>
            <BackgroundImageWrapperDesktop>
                {renderImage(backgroundImageDesktop)}
            </BackgroundImageWrapperDesktop>
            <SvgImageWrapper dangerouslySetInnerHTML={{ __html: sanitizedSvg }} />
        </Container>
        {text && <HeaderTextContainer>
                    <Header>{text}</Header>
                </HeaderTextContainer>
        }
    </ContainerWrapper>
  )
}

export default PageHeader