const typography = {
    font: {
        primary: 'Overpass',
        secondary: 'DAMN'
    },
    text: {
        normal: {
            fontSize: {
                xxs: '16px',
                xs: '16px',
                sm: '20px',
                md: '20px',
                lg: '20px',
                xl: '20px'
            },
            lineHeight: {
                xxs: '24px',
                xs: '24px',
                sm: '28px',
                md: '28px',
                lg: '28px',
                xl: '28px'
            },
            fontWeight: {
                xxs: '300'
            }
        },
        small: {
            fontSize: {
                xxs: '12px',
                xs: '12px',
                sm: '12px',
                md: '14px',
                lg: '16px',
                xl: '20px'
            },
            lineHeight: {
                xxs: '20px',
                xs: '20px',
                sm: '20px',
                md: '22px',
                lg: '24px',
                xl: '28px'
            }
        }
    },
    heading: {
        h1: {
            fontSize: {
                xxs: '37px',
                xs: '44px',
                sm: '50px',
                md: '68px',
                lg: '86px',
                xl: '86px'
            },
            lineHeight: {
                xxs: '95%'
            },
            fontWeight: {
                xxs: '400'
            }
        },
        h2: {
            fontSize: {
                xxs: '24px',
                xs: '24px',
                sm: '28px',
                md: '36px',
                lg: '36px',
                xl: '36px'
            },
            lineHeight: {
                xxs: '33px',
                xs: '33px',
                sm: '39px',
                md: '48px',
                lg: '48px',
                xl: '48px'
            },
            fontWeight: {
                xxs: '500'
            }
        },
        h3: {
            fontSize: {
                xxs: '20px',
                xs: '20px',
                sm: '24px',
                md: '24px',
                lg: '24px',
                xl: '24px'
            },
            lineHeight: {
                xxs: '24px',
                xs: '24px',
                sm: '28px',
                md: '28px',
                lg: '28px',
                xl: '28px'
            },
            fontWeight: {
                xxs: '700'
            }
        },
        h4: {
            fontSize: {
                xxs: '22px',
                xs: '22px',
                sm: '28px',
                md: '36px',
                lg: '44px',
                xl: '44px'
            },
            lineHeight: {
                xxs: '140%'
            },
            fontWeight: {
                xxs: '500'
            }
        },
        h5: {
            fontSize: {
                xxs: '18px',
                xs: '18px',
                sm: '22px',
                md: '28px',
                lg: '36px',
                xl: '36px'
            },
            lineHeight: {
                xxs: '140%'
            },
            fontWeight: {
                xxs: '500'
            }
        },
        h6: {
            fontSize: {
                xxs: '16px',
                xs: '16px',
                sm: '18px',
                md: '22px',
                lg: '28px',
                xl: '28px'
            },
            lineHeight: {
                xxs: '140%'
            },
            fontWeight: {
                xxs: '500'
            }
        }
    },
    fontStyles: {
        "anton-large":{
            fontFamily: 'Anton',
            fontSize: '60px',
            lineHeight: '63px',
            textTransform: 'uppercase'
        },
        "anton-medium":{
            fontFamily: 'Anton',
            fontSize: '48px',
            lineHeight: '50px',
            textTransform: 'uppercase'
        },
        "anton-small":{
            fontFamily: 'Anton',
            fontSize: '36px',
            lineHeight: '38px',
            textTransform: 'uppercase'
        },
        "lora-large":{
            fontFamily: 'Lora',
            fontSize: '40px',
            lineHeight: '48px'
        },
        "lora-medium":{
            fontFamily: 'Lora',
            fontSize: '32px',
            lineHeight: '38px'
        },
        "lora-small":{
            fontFamily: 'Lora',
            fontSize: '24px',
            lineHeight: '28px'
        },
        "overpass-large-cap":{
            fontFamily: 'Overpass',
            fontSize: '28px',
            lineHeight: '36px',
            fontWeight: 700,
            textTransform: 'uppercase'
        },
        "overpass-medium":{
            fontFamily: 'Overpass',
            fontSize: '24px',
            lineHeight: '32px',
            fontWeight: 700,
            textTransform: 'none'
        },
        "overpass-small-cap":{
            fontFamily: 'Overpass',
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 700,
            textTransform: 'uppercase'
        }
    }
};

export default typography;