import React from "react";
import { renderParagraph, renderImage } from 'components/utils/render';
import { Container, Title, TitleTabletView, Image, Content, Quote, SideBar, QuoteIcon, Bar, Message, Link, LinkIcon, Text  } from './Testimonial.style';
import { pushAnyClickEvent } from '../../../tracking/events';

interface Props {
    title: string;
    text: string;
    btnText: string;
    btnLink: string;
    image: string;
}

const Testimonial: React.FC<Props>  = ({title, text, btnText, btnLink, image}) => {

    return (
       <Container>
           <Title>{title}</Title>
           <Image>
                {renderImage(image)}
           </Image>
           <Content>
                <TitleTabletView>{title}</TitleTabletView>
                <Quote>
                        <SideBar>
                            <QuoteIcon/>
                            <Bar/>
                        </SideBar>
                        
                        <Message>{renderParagraph(text)}</Message>
                    </Quote>
                {btnText &&
                    <Link>
                        <LinkIcon />
                        <Text href={btnLink} onClickCapture={() => pushAnyClickEvent({linkText: btnText, linkUrl: btnLink})}>{btnText}</Text>
                    </Link>
                }
           </Content>
       </Container>      
    );
};

export default Testimonial;