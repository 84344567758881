import React from 'react';
import { VideoContainer } from './MoVideo.style';


const MoVideo = () => {
    return ( 
        <VideoContainer>
            {/* <video id="moVideo" autoPlay={true} loop={true} muted={true} poster='/resources/images/homepage/video-banner.png' width="100%">
                <source src='resources/videos/video.mp4' type="video/mp4" />
            </video> */}
            <img src="/resources/images/homepage/video-banner.webp" width="100%" alt="video"/>
        </VideoContainer>
    );
}
 
export default MoVideo;
