import styled from 'styled-components';

const SignupDonate = styled.div`
    background-color: #000;
    color: #fff;
    padding: 64px 0 ;
`;

const ButtonGroup = styled.div`
    display: flex;
    margin-top: 24px;
    margin-bottom: 32px;
`;

const ButtonBlack = styled.a`
    display: block;
    width: 294px;
    color: #000;
    background-color: #fff;
    padding: 20px;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    border: solid 1px #000;
    margin-right: 24px;

    &:hover {
        color: #fff;
        background-color: #000;
        border: solid 1px #fff;
    }

`;

const ButtonPrimary = styled.a`
    display: block;
    color: #FFF;
    width: 294px;
    background-color: #001eff;
    padding: 20px;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
`;

const MovLogo = styled.img`
    width: 200px;
    filter: invert(1);
`;

export { SignupDonate, ButtonGroup, ButtonBlack, ButtonPrimary, MovLogo };