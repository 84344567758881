import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    background-image: url('/resources/images/content/tcam/background-mobile.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    flex-direction: column;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        background-image: url('/resources/images/content/tcam/background.png');
        flex-direction: row-reverse;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    gap: 24px;
    flex: 1;
    padding: 48px 32px 32px 32px;   

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        padding: 48px 48px 60px 48px;
    }
`;

export const SubHeading = styled.h3`
    color: ${props => props.theme.palette.mo.greyTone};
    font-family: Overpass;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    text-transform: uppercase;
    margin: 0;
`;

export const Title = styled.h2`
    color: ${props => props.theme.palette.mo.greyTone};
    font-family: Anton;
    font-size: 60px;
    font-weight: 400;
    line-height: 63px;
    text-align: left;
    text-transform: uppercase;
    margin: 0;
`;

export const Body = styled.div`
    p {
        color: ${props => props.theme.palette.mo.greyTone};
        font-family: Overpass;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 28px;
    }

    p:last-child {
        margin-bottom: 0;
    }
`;

export const PearContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    max-height: 359px;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        max-height: none;
        align-items: flex-end;
        margin-top: 40px;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
        margin-top: auto;
    }
`;

export const Pear = styled.div`
    position: relative;
    top: -19px;
    left: -19px;
    width: 214px;
    height: 433px;
    background-image: url('/resources/images/content/tcam/pear-with-hand.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        top: auto;
        left: 30px;
        bottom: 0;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
        bottom: auto;
        top: 49px
    }
`;