import styled from 'styled-components';

const StoriesContainer = styled.div`
    max-width: 1280px;
    min-width: 320px;
    margin: auto;
    padding: 0;

    .fifth-item {
        display: flex;
        align-items: flex-end;
        & > div {
            width: 105%;
        }
    }
`;

const Breadcrumb = styled.div`
    margin: 28px 0;
    font-size: 24px;

    a {
        text-decoration: none;
        color: ${props => props.theme.palette.link.hover};
    }

    a:last-child {
        color: ${props => props.theme.palette.link.normal};
    }


    i {
        font-size: 15px;
        margin: 0 8px;
        color: ${props => props.theme.palette.link.hover};
    }
`;

const Featured = styled.div`
    width: 100%;
    margin-bottom: 64px;
    cursor: pointer;
    &:hover {
        img {
            transform: scale(1.05,1.05);
            transition-duration: 1000ms;
        }
    }
`;

const HeroContainer = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-bottom: 56.25%;
`;

const HeroImage = styled.a`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
`;

const HeroText = styled.a`
    position: absolute;
    bottom: 20%;
    left: 10%;
    color: #fff;
    text-decoration: none;
`;

const FeaturedHeading = styled.div`
    font-family: Anton;
    font-size: 64px;
    text-transform: uppercase;
`;

const FeaturedTitle = styled.div`
    font-family: Overpass;
    font-size: 24px;
`;

const Story = styled.div`
    display: flex;
    padding: 0;
    margin-bottom: 30px;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        flex-direction: column;
        padding: 0 7px;
        margin-bottom: 60px;
    }

    &:hover {
        .title {
            border-bottom: solid 2px ${props => props.theme.palette.link.hover};
        }
        .image {
            transform: scale(1.1, 1.1);
            transition-duration: 3000ms;
        }
    }
`;

const Media = styled.div`
   flex: 1;
   position: relative;
   width: 100%;
   padding: 8px;
   padding-bottom: 50%;
   overflow: hidden;

   @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        padding-bottom: 100%;
    }

    &:hover {
        .play {
            opacity: 0.8;
            text-shadow: 0px 0px 50px rgb(20 67 239 / 80%);
        }
    }
`;

const Image = styled.div`
    a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
    
        img {
            width: 100%;
            object-fit: cover;
        }
    }
`;

const Play = styled.div`
    a {
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 64px;
        width: 64px;
        font-size: 30px;
        color: ${props => props.theme.palette.primary.normal};
        text-shadow: 0px 0px 50px rgb(20 67 239 / 80%);
        text-decoration: none;
        border-radius: 50px;
        background: #fff;
        opacity: 0.6;
        transform: translate(-50%, -50%);
        cursor: pointer;
    
        @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}) {
            height: 100px;
            width: 100px;
            font-size: 50px;
        }
    }
`;

const Info = styled.div`
    flex: 1;
    padding: 8px;
`;

const Tags = styled.div`
    text-transform: uppercase;
    font-size: 12px;

    a {
        color: ${props => props.theme.palette.link.normal};
        font-weight: 700;
        cursor: pointer;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
            color: ${props => props.theme.palette.link.hover};
        }
    }

    span {
        opacity: 0.25;
        padding: 0 4px;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}) {
        padding-top: 8px;
    }
`;

const Title = styled.div`
    a {
        margin: 0 0 1rem 0;
        font-size: 19px;
        text-decoration: none;      
        display: -webkit-box; // Show ellipse after 2 lines
        -webkit-line-clamp: 2; // number of lines
        -webkit-box-orient: vertical;
        overflow: hidden;
        cursor: pointer;
        color: #000;
    }
`;

const Date = styled.div`
    color: #9F9FA0;
    font-size: 12px;
    text-transform: uppercase;
`;

const LoadMore = styled.div`
    display: flex;
    justify-content: center;
    margin: 24px 0;

    button {
        width: 100%;
        min-height: 48px;
        padding: 0 15px;
        font-size: 16px;
        font-family: "Anton",sans-serif;
        letter-spacing: 2px;
        text-align: center;
        text-transform: uppercase;
        color: #000;
        background-color: transparent;
        font-weight: 400;
        border: solid 2px;
        cursor: pointer;
        outline: none;
        transition: background-color .1s ease, color .1s ease, border-color .1s ease;

        @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}) {
            width: 33%;
        }

        &:hover {
            color: #fff;
            background-color: #000;
        }
    }
`;

export { StoriesContainer, Breadcrumb, Featured, HeroContainer, HeroImage, HeroText, FeaturedHeading, FeaturedTitle, Story, Media, Image, Play, Info, Tags, Title, Date, LoadMore };