import { StyledInput } from './Input.style';

interface Props {
    type?: string
    value?: string
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    placeholder?: string
    checked?: boolean
    hasError?: boolean
    onFocusCapture?: React.FocusEventHandler<HTMLInputElement>
}

const Input: React.FC<Props> = ({ type = 'text', value, onChange, placeholder, checked, hasError, onFocusCapture}) => {
    return (
        <StyledInput
            type={type}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            checked={checked}
            hasError={hasError}
            onFocusCapture={onFocusCapture}
        />
    );
};

export default Input;