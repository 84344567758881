import React, { useState, useEffect } from "react";
import {CountdownColon, CountdownContainer, CountdownList, CountdownMeasure, CountdownValue} from "./CampaignCountDown.style";

const isFrench = window.location.href.indexOf('/fr/') > -1

const CampaignCountdown = (props) => {

    const targetDate = new Date("Nov 1, " + new Date().getFullYear() + " 00:00:00").getTime()
    const [currentDays, setCurrentDays] = useState("00");
    const [currentHours, setCurrentHours] = useState("00");
    const [currentMins, setCurrentMins] = useState("00");
    const [currentSecs, setCurrentSecs] = useState("00");
    const [countdownCopy, setCountdownCopy] = useState(isFrench ? "Séance de rasage" : "Shave down in")
   

    const setCountDown = (targetDate, interval) => {

        // Set default time measures
        const second = 1000
        const minute = second * 60
        const hour = minute * 60;
        const day = hour * 24;

        //Calculate time to end
        const now = new Date().getTime();
        const distance = targetDate - now;
        
        //Stop countown at 0
        if (distance < 0) {
            setCurrentDays("00")
            setCurrentHours("00")
            setCurrentMins("00")
            setCurrentSecs("00")
            if(!isFrench){
                setCountdownCopy("Shave down now")
            }
            clearInterval(interval)
        }
        else{
            //Break time remaining into display values
            const days = Math.floor(distance / (day))
            const hours = Math.floor((distance % (day)) / (hour))
            const mins = Math.floor((distance % (hour)) / (minute))
            const secs = Math.floor((distance % (minute)) / second)
            


            setCurrentDays(days.toLocaleString('en-AU', {minimumIntegerDigits: 2}))
            setCurrentHours(hours.toLocaleString('en-AU', {minimumIntegerDigits: 2}))
            setCurrentMins(mins.toLocaleString('en-AU', {minimumIntegerDigits: 2}))
            setCurrentSecs(secs.toLocaleString('en-AU', {minimumIntegerDigits: 2}))
        }
       
    }
    
    useEffect(() => {
        //Run countown within react lifecycle
        const interval = setInterval(() => {
          setCountDown(targetDate, interval)
        }, 1000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    
    


    return(
        <CountdownContainer>
            <h1>{countdownCopy}</h1>
                <CountdownList>
                    <CountdownMeasure><CountdownValue>{currentDays}</CountdownValue>
                        {isFrench ? 'Jours' : 'Days'}
                    </CountdownMeasure>
                    <CountdownColon>:</CountdownColon>
                    <CountdownMeasure><CountdownValue>{currentHours}</CountdownValue>Hrs</CountdownMeasure>
                    <CountdownColon>:</CountdownColon>
                    <CountdownMeasure><CountdownValue>{currentMins}</CountdownValue>Min</CountdownMeasure>
                    <CountdownColon>:</CountdownColon>
                    <CountdownMeasure><CountdownValue>{currentSecs}</CountdownValue>Sec</CountdownMeasure>
                </CountdownList>
        </CountdownContainer>
        )
    }
    
    export default CampaignCountdown
    
    
    
    