import React from 'react';
// import cms from 'resources/json/cms';

// const initLocalCms = () => {
//     let currentCms = {},
//     countryCode = 'au',
//     hostname = window.location.hostname;
//     if(hostname.charAt(2) === '.'){
//         countryCode = hostname.substring(0,2);
//     }
//     if(cms[countryCode]) {
//         currentCms = cms[countryCode];
//         currentCms.pages = Object.assign(currentCms.pages, { home: { url: "/", title: "Home" }})
//     }
//     return currentCms;
// }

// const CMSContext = React.createContext(initLocalCms());
const CMSContext = React.createContext({});
export default CMSContext;
