import styled from 'styled-components';

const StyledLink = styled.a`
    display: block;
    text-decoration: none;
    color: #000000;
    line-height: 1.5;
    margin-bottom: 12px;

    &:hover {
        cursor: pointer;
        color: ${props => props.theme.palette.link.hover};
    }
`;

const Heading = styled.div`
    margin-bottom: 1rem;
    font-size: 23px;
    line-height: 140%;
    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.md}) {
        font-size: 24px;
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.lg}) {
        font-size: 31px;
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.xl}) {
        font-size: 44px;
    }
`;

const PageRow = styled.div`
    margin: 64px 0;
`;

const SocialRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;
`;

const ContactRow = styled.div`
    opacity: .6;
    margin-bottom: 54px;
`;

const TitleUnderline = styled.div`
    border-bottom: solid 1px;
    margin-bottom: 36px;
`;

const LightText = styled.div`
    font-family: Overpass;
`;

const SocialLink = styled.a`
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: #000000;

    &:hover {
        color: ${props => props.theme.palette.link.hover};
        & > img {
            filter: none;
        }
    }
    
    & > img {
        height: 18px;
        width: auto !important;
        filter: brightness(0);
    }

    & > span {
        margin-left: 12px;
        display: none;

        @media screen and (min-width: 1024px){
            display: inline-block;
        }
    }

`;

export { StyledLink, Heading, PageRow, SocialRow, ContactRow, TitleUnderline, LightText, SocialLink };