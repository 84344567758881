import React from 'react';
import StyledHeading from './Heading.style';
import { checkNested } from 'components/utils';

const Heading = (props) => {
    const { children, level } = props,
    isHeading = (props.level === 'h1' || props.isHeading),
    newProps = {
        ...props,
        fontFamily: getFontFamily(isHeading),
        textTransform: (isHeading || props.level === 'h3') ? 'uppercase' : '',
        letterSpacing: (isHeading) ? '-0.5px' : ''
    };

    function getFontFamily(isHeading) {
        if(isHeading){
            return checkNested(props.theme, 'typography', 'font', 'secondary')? props.theme.typography.font.secondary: "Anton";
        }else{
            return checkNested(props.theme, 'typography', 'font', 'primary')? props.theme.typography.font.primary: "Overpass";
        }
    }

    return (
        <StyledHeading {...newProps} as={level}>
            {children}
        </StyledHeading>
    );
};

/**
 * Definition of default values for component props
 */
Heading.defaultProps = {
    children: undefined,
    level: 'h1',
    truncate: false,
    color: undefined,
    display: 'block',
    align: 'inherit',
    fontFamily: undefined,
    marginTop: '1rem',
    marginBottom: '1rem',
    fontWeight: 'normal'
};

export default Heading;