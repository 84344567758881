import { ColumnItem, ColumnComponent } from '../MultiColumn.models'
import {  ComponentContainer, HeadingContainer, SubHeadingContainer } from './Column.style'
import { DynamicComponent } from '../DynamicComponent/DynamicComponent'
import Heading from 'components/common/Heading/Heading';

export const Column = ({ heading, subHeading, components, hasHeading }: ColumnItem) => {

  return (
    <ComponentContainer> 
        {heading &&
          <HeadingContainer>
             <Heading level='h2'>{heading}</Heading>
          </HeadingContainer>
        }
        {subHeading &&
          <SubHeadingContainer>
            <Heading level='h3'>{subHeading}</Heading>
          </SubHeadingContainer>
        }
        {components && components.map((component: ColumnComponent) => {
          return <DynamicComponent key={component.type} {...component} hasImageOnly={components.length === 1 && !heading && !subHeading} hasHeading={hasHeading}/>
        })}
    </ComponentContainer>
  )
}
