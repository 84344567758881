import React from 'react';
import Grid from '@material-ui/core/Grid';
import getSpacing from './gridSpacing.theme';


const Row = (props) => {
    const {children} = props
    return ( 
        <Grid container spacing={getSpacing()} {...props}>
            {children}
        </Grid>
     );
}
 
export default Row;