import styled from 'styled-components';

/* Wrapper for the wiggling pear, which overlays the next content vertically, and hides the overlay content horizontally in mobile view*/
const Wrapper = styled.div`
 @media screen and (max-width: ${props => props.theme.breakpointsInt && (props.theme.breakpointsInt.sm - 1)}px){
    padding-bottom: 70px;
    margin-bottom: -70px;
    overflow-x: hidden;
    & > div:last-child {
      div {
        left: 230px;
        translate: 0 -95%;
      }
    }
 }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 48px 32px;
    color: #111111;
    background-image: url('/resources/images/content/tcam/background-mobile.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        align-items: center;
        background-image: url('/resources/images/content/tcam/background.png');
        padding: 48px;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
        flex-direction: row;
        align-items: stretch;
    }
`;

const Top = styled.div`
    flex: 1;
    display: flex;
    width: 100%;
`;

const Bottom = styled.div`
    flex: 1;
`;

const Content = styled.div`
    position: relative;
    z-index: 1;
    min-width: 260px;
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        flex: 1;
    }
`;

const Heading = styled.h1`
    font-family: Anton;
    font-size: 60px;
    font-weight: 400;
    line-height: 105%;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 32px;
    max-width: 248px;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        max-width: 320px;
    }
`;

const ButtonContainer = styled.div<{showOnMobile?: boolean}>`
    margin-bottom: 46px;

    a {
        margin-left: -8px;
    }

    ${ props => props.showOnMobile === false && `
        display: none;
        @media screen and (min-width: ${props.theme.breakpoints && props.theme.breakpoints.sm}){
            display: block;
        }      
    `}
          
`;

const ButtonAddText = styled.div`
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 12px;
`;

const Paragraph = styled.div`
    padding-top: 16px;
    padding-bottom: 16px;
    p {
        font-size: 20px;
        line-height: 28px;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        column-count: 2;
        column-gap: 32px;
        padding-top: 48px;
        padding-bottom: 0;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
        column-count: 1;
        padding-top: 0;
        padding-left: 48px;
    }
`;

const PearContainer = styled.div<{showOnMobile?: boolean}>`
    position: relative;
    display: flex;
    flex: 1;
    justify-content: center;

    ${ props => props.showOnMobile === false && `
        display: none;
        @media screen and (min-width: ${props.theme.breakpoints && props.theme.breakpoints.sm}){
            display: block;
        }
    `}
`;

const PearMobile = styled.div`
    display: flex;
    min-height: 180px;
`;

const FloatingDiv = styled.div`
    position: fixed;
    top: 132px;
    right: 64px;
    z-index: 999;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
       top: 74px;
    }
`;

const FloatingContainer = styled.div`
    position: relative;
    div:first-child {
       height: 150px;
    }
    a {
        position: absolute;
        right: 32px;
        top: 20px;    
        width: 260px;
        animation: buttonSlideDown 1s ease;
    }

    @keyframes buttonSlideDown {
        0% {
            top: -40px;
          }
        100% {
            top: 20px;
        }
    }

    &.slide-up {
        animation: floatingDivSlideUp 1s ease forwards;
    }
    
    @keyframes floatingDivSlideUp {
        0% {
            top: 20px;
        }
        100% {
            top: -40px;
        }
    }
`;





export { Wrapper, Container, Top, Bottom, Content, Heading, ButtonContainer, ButtonAddText, Paragraph, PearContainer, PearMobile, FloatingDiv, FloatingContainer };