import styled, {css} from 'styled-components';


export const ContainerWrapper = styled.div<{noBackgroundColor: boolean, backgroundColor: string}>`
  display: flex;
  justify-content: center;
  background-color: ${props=>getStyles('background-color', props.backgroundColor)};
  color: ${props=>getStyles('color', props.backgroundColor)};
  ${props => props.backgroundColor === 'campaign' && getStyles('backgroundImage', props.backgroundColor)};

  a:not([data-attribute='button']) {
    color:  ${props=>getStyles('color', props.backgroundColor)};
  }
`;

export const Container = styled.div<{colCount: number, moduleWidth?: string}>`
  width: 100%;

  ${props => (props.moduleWidth === 'medium' || props.moduleWidth === 'small') && css`
      // module width has 3 options - full, medium, small
      width: ${props.moduleWidth === 'medium' ? '1024px' : '600px'};
  `}
  
  @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
    ${props => (props.colCount < 3 || (props.colCount === 3 && props.moduleWidth === 'full')) && css`
      display: flex;
    `}
  }

`;

export const HeadContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 48px 32px 0px 32px;

  @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
    padding: 48px 48px 0px 48px;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
    padding: 48px;
  }
`;

export const SubHeadContainer = styled.div<{colCount: number}>`
  @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
    ${props => props.colCount === 4 && css`
      display: flex;
      justify-content: space-between;
      > * {
        flex:1
      }
    `}
  }

`;

export const Heading = styled.h3<{colCount: number, background: string}>`
  margin-top: 0;
  margin-bottom: 24px;
  text-transform: uppercase;
  font-size: 36px;
  font-weight: 400;
  line-height: 38px;
  ${props => getStyles('headingFont', props.background)};

  @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
    ${props => props.colCount === 4 && css`
      margin-bottom: 0;
      padding-right: 48px;
    `}
  }
`;

export const Description = styled.div<{colCount: number}>`
  p {
    font-family: Overpass;
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    text-align: left;
    margin-bottom: 0;
  }
  @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
    max-width: 600px;
    ${props => props.colCount === 4 && css`
      padding-left: 48px;
    `}
  }
  @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
    max-width: none;
  }
`;

export const ButtonContainerTop = styled.div<{background: string, colCount: number, buttonType: string}>`
  display: none;
  a {
    ${props => getStyles(getPropertyNameForButtonType(props.buttonType), props.background)}}
  }

  @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
    ${props => props.colCount < 4 && css`
      display: block;
    `}
  }
`;

export const ButtonContainerBottom = styled.div<{ background:string, colCount: number, buttonType: string}>`
  display: block;
  padding: 0 32px 48px 32px;
  
  a {
    width: 100%;
    ${props => getStyles(getPropertyNameForButtonType(props.buttonType), props.background)}}
  }

  @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
    padding: 0 48px 48px 48px;
    a {
      width: auto;
    }
  } 

  @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
    ${props => props.colCount < 4 && css`
      display: none;
    `}
  }
`;

export const ColContainer = styled.div<{colCount: number, isCentredColumnLayout: boolean}>`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
    flex-direction: row;
    ${props => props.colCount === 4 && css`
      flex-wrap: wrap;
    `}

    //TCAM design implementations for single column
    ${props => props.isCentredColumnLayout && css`
      justify-content: center;
    `}
  }

  @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
    ${props => props.colCount === 4 && css`
      flex-wrap: inherit;
    `}
  } 

  @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
    ${props => (props.colCount === 3 || props.colCount === 2) && css`
      flex: 4;
    `}
  }
`;

export const StyledColumn = styled.div<{ colCount: number, backgroundColor: string, isCentredColumnLayout: boolean, moduleWidth?: string }>`
  flex: 1;
  padding 48px 32px;

  a[data-attribute='button']{
    span {
      font-size: 16px;
      font-weight: 600;
    }
    &[type='arrow']{
      width: auto;
    }
  }

  p {
    font-family: Overpass;
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
  }

  div[data-attribute='paragraphContainer'] > h4 {
    font-family: Overpass;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 12px;
    margin-top: 0px;
  }

  ${props => (props.backgroundColor === 'default' || props.backgroundColor === 'secondary') && css`
    a[data-attribute='button'][type='secondary']:not([data-overlay=true]) { // Don't set transparent background for overlay secondary buttons ex. dataForm success
      background: transparent;
    }
  `}

  ${props => props.backgroundColor !== 'default' && css`
    h2, h3, p, a[data-attribute='button']:not([type='primary']):not([type='secondary']) {
        ${getStyles('textColor', props.backgroundColor)}
      }
  `}

  ${props => props.backgroundColor === 'campaign' && css`
      h2 {
        font-family: DAMN;
        font-size: 46px;
        line-height: .82;
        font-weight: 400;
      }
    
      h3 {
        font-family: DAMN;
        font-size: 42px;
        line-height: .82;
        font-weight: 400;
      }

      div[data-attribute='imageContainer'] {
        padding-bottom: 100%;
      }
  `}

  //TCAM design implementations for single column
  ${props => props.isCentredColumnLayout && css`
    h2 {
      color: ${props => props.theme.palette.mo.greyTone};
      font-family: Anton;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 38px;
      text-transform: uppercase;
      margin: 0;
    }

    div[data-attribute='paragraphContainer'] > h3 {
      font-family: Overpass;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      text-transform: none;
    }

    div[data-attribute='paragraphContainer'] > h4 {
      font-family: Overpass;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      text-transform: none;
      margin-bottom: 12px;
      margin-top: 24px;
    }

    figcaption[data-attribute='imageCaption']{
      display: block;
    }
  `}

  @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
    padding: 48px;
    ${props => props.colCount === 4 && css`
      flex-basis: 50%;
    `}

    //TCAM design implementations for single column
    ${props => props.isCentredColumnLayout && css`
      max-width: 600px;

      a[data-attribute='button'] {
        width: auto;
      }
    `}
  }

  @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
    ${props => props.colCount === 4 && css`
      flex-basis: 0;
    `}
  }
 
  ${props => props.colCount === 1 && props.moduleWidth === 'full' && css`
      max-width: 824px;
  `}
`;

const getPropertyNameForButtonType = (buttonType: string) => {
  switch (buttonType) {
    case 'arrow':
      return 'arrowButton';
    case 'secondary':
      return 'secondaryButton';
    default:
      return 'primaryButton';
  }
};

const getStyles = (property: string, theme: string) => {
  const styles = {
    default: {
      'color': '#161719',
      headingFont() {
        return css`
          font-family: Anton;
        `;
      },
      primaryButton() {
        return css`
          color: #FCFCFE;
        `;
      },
      secondaryButton() {
        return css`
          color: #161719;
        `;
      }
    },
    secondary: {
      'color': '#161719',
      'background-color':'#F0F0F0',
      headingFont() {
        return css`
          font-family: Anton;
        `;
      },
      primaryButton() {
        return css`
          color: #FCFCFE;
        `;
      },
      arrowButton() {
        return css`
          color: #0C0C0C;
        `;
      },
    },
    dark: {
      'color':'#FCFCFE',
      'background-color':'#161719',
      headingFont() {
        return css`
          font-family: Anton;
        `;
      },
      primaryButton() {
        return css`
          color: #161719;
          background-color: #FCFCFE;
        `;
      },
      textColor() {
        return css`
          color: #FCFCFE;
        `;
      }
    },
    campaign: {
      'color':'#FCFCFE',
      headingFont() {
        return css`
          font-family: DAMN;
          font-size: 45px;
          line-height: .82;
          @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
            font-size: 56px;
          }
        `;
      },
      backgroundImage() {
        return css`
          background-image: url('/resources/images/global/campaign/background-mobile.jpg');
          background-size: auto 100vh;
          background-position: center;
          background-attachment: fixed;

          @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
            background-image: url('/resources/images/global/campaign/background-tablet.jpg');
            background-size: cover;
          }

          @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
            background-image: url('/resources/images/global/campaign/background-desktop.jpg');
          }
        `;
      },
      primaryButton() {
        return css`
          color: #161719;
          background-color: #FCFCFE;
        `;
      },
      textColor() {
        return css`
          color: #FCFCFE;
        `;
      }      
    }
  };

  const selectedStyles = styles[theme as keyof typeof styles];

  return selectedStyles[property as keyof typeof selectedStyles] || '';
};
