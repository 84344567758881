const breakpoints = {
    xs: '480px',
    sm: '768px',
    md: '1024px',
    lg: '1366px',
    xl: '1920px'
};

const breakpointsInt = {
    xs: 480,
    sm: 768,
    md: 1024,
    lg: 1366,
    xl: 1920
};

const breakpointsMaterialUi = {
    xs: 0,
    sm: 768,
    md: 1024,
    lg: 1366,
    xl: 1920
};


export { breakpoints, breakpointsInt, breakpointsMaterialUi };