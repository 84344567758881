import React from 'react';
import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: 100% 90%;
  }
  100% {
    background-position: 0% -10%;
  }
`;

const StyledSkeleton = styled.div`
    width: ${props => props.width ? props.width : '100%'};
    height: ${props => props.height ? props.height : '1rem'};
    animation: ${shimmer} 1.5s infinite;
    background: linear-gradient(
        312deg,
        rgba(0,0,0, 0.1),
        rgba(0,0,0, 0.1),
        rgba(0,0,0, 0.15),
        rgba(0,0,0, 0.1),
        rgba(0,0,0, 0.1)
    );
    background-size: 400% 100%;
    margin-bottom: 1rem;
`;

const Skeleton = ({width, height}) => {
    return (
        <StyledSkeleton width={width} height={height}/>
    );
}
 
export default Skeleton;