import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${props => (props.fullScreen) ? '100vh': 'auto'}
`;

const Image = styled.img`
    height: 100%;
    max-height: 100vh;
    width: 100%;
    object-fit: cover;
`;

const TextContent = styled.div`
    position: absolute;
    width: 60%;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
  
    & > h1, h6 {
        opacity: .75 !important;
        line-height: 1;
    }

    @media screen and (max-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        width: 80%;
    }
`;

const Button = styled.a`
    display: block;
    color: #FFF;
    width: 294px;
    background-color: #001eff;
    padding: 20px;
    text-decoration: none;
    text-transform: uppercase;
    margin: auto;
    cursor: pointer;
`;

export { Container, Image, TextContent, Button };