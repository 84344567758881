import styled from 'styled-components';
import { breakpoints } from 'themes/breakpoints.theme';

const Container = styled.div`
    max-width: 100VW;
    @media screen and (min-width: ${breakpoints.md}){
        padding: 32px;
    }
    @media screen and (min-width: ${breakpoints.lg}){
        padding: 56px;
    }
`;

const Slide = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${breakpoints.md}){
        flex-direction: row;
    }
`;

const MediumNav = styled.div`
    margin-top: 16px;
    display: none;
    @media screen and (min-width: ${breakpoints.md}){
        display: block;
    }
    @media screen and (min-width: ${breakpoints.lg}){
        display: none;
    }
`;

const OtherNav = styled.div`
    display: block;
    @media screen and (min-width: ${breakpoints.md}){
        display: none;
    }
    @media screen and (min-width: ${breakpoints.lg}){
        display: block;
    }
`;

const ImageContainer = styled.a`
    flex: 1.34;
    z-index: 2;
    &:hover {
        cursor: pointer;
        img {
            transform: scale(1.05, 1.05);
            transition-duration: 2000ms;
        }
    }

    ${props => props.isOnHover && `
        img {
            transform: scale(1.05, 1.05);
            transition-duration: 2000ms;
        }
    `}

    // Added this to fix an issue with the carousel in prod where display: none randomly added into image container style and couldn't found the root cause
    // As for a temporary fix added !important to override any random style added
    display: block !important;
`;

const Image = styled.div`
    padding-bottom: 56%;
    position: relative;
    overflow: hidden;
    img {
        position: absolute;
        width: 100%;
        height: 100%;
    }
`;

const Content = styled.div`
    z-index: 2;
    flex: 1;
    display: flex;
    flex-direction: column-reverse;;
    justify-content: space-between;
    padding: 24px 18px;
  
    @media screen and (min-width: ${breakpoints.md}){
        flex-direction: column;
        padding-top: 8px;
        padding-left: 40px;
    }
    @media screen and (min-width: ${breakpoints.lg}){
        padding: 16px 0 0 56px;
        padding-top: 16px;
    }
`;

const Detail = styled.div`
`;

const Title = styled.a`
    margin-bottom: 8px;
    font-family: Anton;
    font-size: 37px;
    font-weight: normal;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: -0.5px;
    line-height: 0.93;
    color: #000;

    @media screen and (min-width: ${breakpoints.sm}){
        font-size: 49px;
    }

    &:hover {
        font-weight: normal;
        color: rgba(0, 0, 0, 0.4);
        cursor: pointer;
    }

    ${props => props.isOnHover && `
        color: rgba(0, 0, 0, 0.4);
    `}
`;

const Description = styled.div`
    font-size: 15px;
    font-weight: 300;
    @media screen and (min-width: ${breakpoints.sm}){
        font-size: 18px;
    }

`;

const Navigation = styled.div`
    display: flex;
    justify-content: space-between;
    z-index: 2;
    margin-bottom: 24px;
    @media screen and (min-width: ${breakpoints.lg}){
        margin-bottom: 0;
    }

`;

const CTA = styled.a`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    height: 40px;
    padding: 8px 16px;
    margin-right: 16px;
    font-family: Overpass;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    color: ${props => props.theme.palette.secondary.text};
    background-color: ${props => props.theme.palette.secondary.normal};
    letter-spacing: normal;
    line-height: normal;
    border: solid 1px ${props => props.theme.palette.secondary.border};
    border-radius: 24px;
    z-index: 2;

    &:hover, &:active, &:focus {
        background-color: ${props => props.theme.palette.secondary.hover};
    }

    span { 
        display: inline-block;
        margin-left: 10px;
        width: 14px;
        height: 12px;
        border: none;
        background-image: url('/resources/images/icons/arrow-black.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
`;

const ArrowsContainer = styled.div`
    display: inline-flex;
    z-index: 2;
`;

const StepIndicator = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    min-width: 70px;
    padding: 0 16px;
    font-size: 12px;
    font-weight: bold;
    @media screen and (min-width: ${breakpoints.sm}) {
        padding: 0 27px;
    }
`;

const Arrow = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    outline: none;
    position: relative;
    border-radius: 4px;
   
    img {
        width: 14px;
        position: relative;
        z-index: 1;
    }

    ${props => props.direction === 'left' && `
        transform: rotate(180deg);
    `}

    ${props => props.isNavigating && `
        &:after {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            background-color: #000;
            border-radius: 4px;
            animation: navigating;
            animation-duration: 4.5s;
        }
        img {
            animation: arrow;
            animation-duration: 4.5s;
        }

        @keyframes navigating {
            from {
               width: 0;
            }
            to {
                width: 100%;
            }
        }
        @keyframes arrow {
            from {
                filter: invert(0);
            }
            to {
                filter: invert(1);
            }
        }
    `}
    ${props => !props.isNavigating && `
        &:hover {
            background-color: #000;
            img {
                filter: invert(1);
            }
        }
    `}
`;

export { Container, Slide, MediumNav, OtherNav, ImageContainer, Image, Content, Detail, Title, Description, Navigation, CTA, ArrowsContainer, Arrow, StepIndicator };