import React from 'react';
import { Container, ReadTime, Content, Tag } from './Body.style';
import { getWordsPerMin, renderParagraph } from 'components/utils';
import { Link } from 'react-router-dom';
import { pushAnyClickEvent } from '../../tracking/events';

const Body = ({cms, content, quote, quotePosition, wordCount, tags}) => {

    function getReadTime() {
        const urlCountryCode = window.location.hostname.substring(0,2);
        const wordsPerMin = getWordsPerMin(urlCountryCode);
        const time = Math.ceil(wordCount/wordsPerMin);
        return `${time} ${(time > 1) ? 'minutes' : 'minute'}`;
    }

    return (
        <Container>
            <ReadTime> {getReadTime()} {cms.snippets['text-common-read-time'].text}</ReadTime>
            <Content>{renderParagraph(content, quote, quotePosition, 'story')}</Content>
            <div>
                {tags.map((tag, i) =>
                    <Tag key={i}><Link to={tag.url} onClickCapture={() => pushAnyClickEvent({linkUrl: tag.url, linkText: tag.title})}>{tag.title}</Link></Tag>
                )}
            </div>
        </Container>
     );
}
 
export default Body;
