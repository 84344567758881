import { Container, ContentContainer, SubHeading, Title, Body, PearContainer, Pear } from './TcamHeaderUpgrade.style';
import { renderParagraph } from 'components/utils/render';

interface Props {
    title: string;
    subHeading: string;
    description: any;
}

function TcamHeaderUpgrade({title, subHeading, description}: Props) {
  return (
    <Container>
        <ContentContainer>
            <SubHeading>{subHeading}</SubHeading>
            <Title>{title}</Title>
            <Body>{renderParagraph(description)}</Body>
        </ContentContainer>
        <PearContainer>
            <Pear/>
        </PearContainer>
    </Container>
  )
}

export default TcamHeaderUpgrade