import styled from 'styled-components';

const Wrapper = styled.div`
    background-color: rgba(0, 0, 0, 0.05);
    max-width: 100VW;
    overflow: hidden;
`;

const PartnerContainer = styled.div`
   padding: 48px 0;
`;

const PartnerRow = styled.div`
    
`;

const TitleUnderline = styled.div`
    border-bottom: solid 1px;
    opacity: .6;
`;

const LogoContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 180px;
    padding-top: 36px;
    padding-bottom: 36px;
`;

const StyledImg = styled.img<{isActive: boolean}>`
    position: relative;
    width: 100%;
    max-width: 106px;
    height: auto !important;
    max-height: 108px;
    filter: grayscale(100%);
    opacity: ${props => props.isActive ? '1' : '0'};
    visibility: ${props => props.isActive ? 'visible' : 'hidden'};
    transform: ${props => props.isActive ? 'scale(1)' : 'scale(0)'};


    transition: scale,transform, opacity, visibility;
    transition-duration: .5s;

    &:hover {
        filter: grayscale(0%);
    }
`;

const Link = styled.a<{isActive: boolean}>`
  color: #000;
  text-decoration: none;
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;

  pointer-events: ${props => props.isActive ? 'all' : 'none'};
  &:hover {
    color: blue;
  }
`;


export { Wrapper, PartnerContainer, PartnerRow, TitleUnderline, LogoContainer, StyledImg, Link };