import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';


const SanityCmsContainer = styled.div`
  display: none;
`;
const CmsEditIcon = styled.i`
  position: fixed;
  top: 20px;
  left: 170px;
  font-size: 30px;
  color: #001eff;
  z-index: 1000;
`;

const CmsPreview = () => {



    //const cmsBaseUrl = window.location.host === `localhost:3000` ? `http://localhost:3333` : `https://uat.cms.movember.com`;
    const locales = {
        'au': ['en'],
        'ca': ['en', 'fr'],
        'us': ['en'],
        'nz': ['en']
    }

    useEffect(() => {
        const hostname = window.location.hostname
        let cmsBaseUrl = `https://cms.movember.com`;
        if (hostname.indexOf('dev.movember.com') > -1 || hostname.indexOf('localhost') > -1) {
            //cmsBaseUrl = `https://cms.dev.movember.com`;
            cmsBaseUrl = `http://localhost:3333`;
        } else if (hostname.indexOf('uat.movember.com') > -1) {
            cmsBaseUrl = `https://uat.cms.movember.com`;
        }
        const loadCmsIframe = () => {
            let cmsContainer = document.getElementById('cms-login-container');
            if (cmsContainer.childElementCount > 0) { return; }
            const iframeUrl = `${cmsBaseUrl}/desk?locale=${getLocale(window.location.host, window.location.pathname)}&watchDraft=true`;
            ReactDOM.render(React.createElement(() =>
                <iframe src={iframeUrl} title="cms-preview" />
            ), cmsContainer);

            setTimeout(() => {
                document.dispatchEvent(new CustomEvent('cms-preview-loaded'));
                let cmsEditIcon = document.getElementById('cms-edit-icon');
                if (cmsEditIcon && cmsEditIcon.style.display === 'none') {
                    cmsEditIcon.style.display = 'block';
                }
            }, 1000)
        };
        if (window.navigator.userAgent !== 'mo-site-pre-render') {
            if (document.cookie.indexOf('cms-login-cookie=true') > -1) {
                loadCmsIframe();
            }
            document.addEventListener('cms-preview-activated', () => {
                document.cookie = "cms-login-cookie=true; Path=/;";
                loadCmsIframe();
            });
        }
        // eslint-disable-next-line
    }, []);

    function getLocale(hostname, path) {
        hostname = hostname.toLowerCase();
        path = path.toLowerCase();
        if (hostname.indexOf('localhost') === 0) {
            return 'en_AU';
        }
        for (let i in locales) {
            if (hostname.indexOf(i + '.') === 0) {
                for (let j = 0; j < locales[i].length; j++) {
                    if (path.indexOf('/' + locales[i][j] + '/') === 0) {
                        return `${locales[i][j]}_${i.toUpperCase()}`;
                    }
                };
                return `${locales[i][0]}_${i.toUpperCase()}`;
            }
        }
        return null;
    }

    return ( 
        <React.Fragment>
            <SanityCmsContainer id="cms-login-container"/>
            <CmsEditIcon id="cms-edit-icon" className="fas fa-edit" style={{display: 'none'}}/>
        </React.Fragment>
     );
}
 
export default CmsPreview;
