import React, {useState} from "react";
import {
  VideoContainer,
  HeroVideo,
  VideoControl,
  HeroCopy,
  Typewriter,
  YoutubeVid,
} from "./HeroVideo.style";
import { breakpoints } from "themes/breakpoints.theme";
import VideoView from "./VideoView";
import Heading from "components/common/Heading/Heading";
import Text from "components/common/Text/Text";
import TextRotate from "components/content/templates/TextRotate";
import MoDialog from "components/common/Dialog/Dialog";
import { useWindowWidth, renderImage } from "components/utils";
import CampaignCountDown from "./CampaignCountDown"



const MoVideo = (props) => {
  const [openShare, setOpenShare] = useState(false);
  const desktopVideo = props.video_url_desktop
  const mobileVideo = props.video_url_mobile
  const desktopPoster = renderImage(props.video_poster_desktop);
  const mobilePoster = renderImage(props.video_poster_mobile);
  const isDesktop = useWindowWidth() > Number.parseInt(breakpoints.sm);
  const floatingText = props.heading_typewriter;
  
  return (
    <VideoContainer>
      <HeroVideo>
        <VideoControl
          onClick={(e) => {
            e.preventDefault();
            setOpenShare(true);
          }}
        >
          <img src="/resources/images/icons/play-blue.svg" alt="play-button"/> {props.video_duration}
        </VideoControl>
        <VideoView
          source={isDesktop ? desktopVideo : mobileVideo}
          poster={isDesktop ? desktopPoster : mobilePoster}
        />
        <HeroCopy>
          <div>
            <Heading
              color="rgba(255, 255, 255)"
              level="h1"
              className="HP-2020_Hero_Header"
            >
             <Typewriter>
                <TextRotate textArray={floatingText} period={200}></TextRotate>
              </Typewriter><br />
              {props.video_header }
              
            </Heading>

            <Text color="#FFFFFF">
              {props.video_copy}
            </Text>
          </div>
        </HeroCopy>
        <MoDialog open={openShare} onClose={() => setOpenShare(false)}>
          <YoutubeVid>
            <iframe
              width="560"
              height="515"
              src= {"https://www.youtube.com/embed/" + props.youtube_id + "?rel=0&autoplay=1"}
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              title="youtube-video"
            ></iframe>
          </YoutubeVid>
        </MoDialog>
      </HeroVideo>
      <CampaignCountDown /> 
    </VideoContainer>
  );
};

export default MoVideo;

