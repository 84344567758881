import React from 'react';
import { IsOnMobile } from 'components/utils';
import { Container, Pear, Heading, Text } from './TcamHeader.style';

interface Props {
    heading: string;
    text: string;
}

const TcamHeader: React.FC<Props> = ({ heading, text }) => {

    // Currently we only support 2 variants of heading: TESTICLES & TESTICULES
    const renderHeading = () =>
        ((heading.toUpperCase() === 'TESTICLES' || heading.toUpperCase() === 'TESTICULES')) ? ['TESTI-', heading.substring(5)] : [heading];
    
    return (
       <Container>
            <Pear/>
            <Heading>
                {
                    IsOnMobile() && renderHeading()[0] === 'TESTI-' ?
                        <svg
                            viewBox={heading.toUpperCase() === 'TESTICULES' ? "0 0 365 300" : "0 0 345 300"}
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <text
                                x="-1" y="144"
                            >{renderHeading()[0]}
                            </text>
                            <text
                                x="-1" y="300"
                            >{renderHeading()[1]}
                            </text>
                        </svg>
                        :
                        <svg
                            viewBox={heading.toUpperCase() === 'TESTICULES' ? "0 0 680 144" : "0 0 600 144"}
                            xmlns="http://www.w3.org/2000/svg">
                            <text x="0" y="144">{heading}</text>
                        </svg>
                }
            </Heading>
            <Text>{text}</Text>
       </Container>
    );
};

export default TcamHeader;