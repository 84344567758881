import { locales } from 'components/utils';

class MoConfiguration {
    constructor(configs) {
      this.config = configs ? configs : {
        country: 'au', language: 'en', "locale": locales,
      };
    }

    /**
     * Given a config key (or array of consecutive subkeys), attempt to load the requested config item.
     * Keys can be nested using "." (Example. "one.two" corresponds to {"one": { "two": "me"}}, returning "me")
     *
     * Examples:
     *   .getValue("country"), will return initVars.country
     *   .getValue(["locale", country, "language"]), if the variable 'country' = 'au', will return locale.au.language from initVars
     *
     * If the key does
     * @param key
     * @param defaultValue
     * @returns {*}
     */
    getValue(key, defaultValue) {

        if(this.config[key]) return this.config[key];

        if (typeof key === "object") {
            key = key.join(".");
        }
        let keys = key.split(".");

        let value = this.search(this.config, keys);

        if (typeof value === "undefined" && typeof defaultValue !== "undefined") {
            value = defaultValue;
        }

        // console.log("search getValue: ", key, defaultValue, value);
        return value;
    }

    /**
     * This expects an array of subkeys, and will recursively attempt to find the next level of config, containing the next key
     *
     * @param config
     * @param keys
     * @returns {*}
     */
    search (config, keys) {

        if (!config) return config;

        let thisKey = keys[0];

        // Return the value, or undefined
        if (keys.length === 1) {
            if(config)
            return config[thisKey];
        }

        // Take the current search key off the list so we can go down another level
        keys.shift();

        return this.search(config[thisKey], keys)
    }

    /**
     * @todo This doesn't handle nested subkeys yet
     *
     * @param key
     * @param value
     * @returns {*}
     */
    setValue(key, value) {
        return this.config[key] = value;
    }

    /**
     *
     * Commonly used shortcut functions
     *
     **/

    getCountry() {
        return this.config.country;
    }
    setCountry(value) {
        this.config.country = value;
    }

    getLanguage() {
        return this.config.language;
    }
    setLanguage(value) {
        this.config.language = value;
    }
}

export default MoConfiguration;