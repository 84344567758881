import React, { useState, useCallback, useEffect } from 'react';
import MagnifyingGlass from './MagnifyingGlass.jsx';
import { Container, Input } from './SearchInput.style.js';

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const SearchInput = ({
  setSearchResult,
  setLoading,
  value,
  setValue,
  setError,
  setNoResult,
  textInput,
  placeholderText,
  setNoInput,
  noInput,
  api = ""
}) => {
  const [cachedResult, setCachedResult] = useState({})

  const url = (query) => api.replace('query', `${query}`);
 
  const trimmedValue = value.trim()

  useEffect(() => {
    if (value.length > 1) {
      setLoading(true);
      setNoResult(false);
      // Only fetch if we don't have the cachedResult
      if(Object.keys(cachedResult).filter(keys => keys === trimmedValue).length === 0) {
        debounceSearch(trimmedValue);
      } else {
        setSearchResult(cachedResult[trimmedValue]);
        if(cachedResult[trimmedValue].length === 0) {
          setNoResult(true);
        }
        setLoading(false)
      }
    }
  }, [value]);

  useEffect(() => {
    if(Object.keys(cachedResult).length > 0 && trimmedValue.length > 1) {
      setSearchResult(cachedResult[trimmedValue]);
    }
  }, [cachedResult])

  const debounceSearch = useCallback(
    debounce(async (value) => {
      try {
        const response = await fetchData(value);
        const responseArray = Array.isArray(response) ? response : response.data;
        setCachedResult(prevState => ({...prevState, [value]: responseArray}))
        if (responseArray.length === 0) {
          setNoResult(true);
        } else {
          setNoResult(false)
        }
      } catch (e) {
        console.warn(e);
      }
      setLoading(false);
    }, 300),
    [],
  );
  const handleSearch = async (e) => {
    const inputVal = e.target.value;
    setValue(inputVal);
    resetStates();
  };

  const resetStates = () => {
    setSearchResult([]);
    setError(false);
    setNoResult(false);
    setNoInput(false);
    setLoading(false);
  };

  const fetchData = async (value) => {
    try {
      const response = await fetch(url(value));
      const result = await response.json();
      if (!response.ok) {
        setError(true);
        return [];
      }
      return result;
    } catch (e) {
      setError(true);
    }
  };

  return (
    <Container>
      <Input
        type='search'
        placeholder={placeholderText}
        value={value}
        onChange={(e) => handleSearch(e)}
        ref={textInput}
        noInput={noInput}
      />
      <MagnifyingGlass
        style={{ position: 'absolute', top: '50%', right: '12px', transform: 'translate(0, -50%)' }}
      />
    </Container>
  );
};

export default SearchInput;
