const shareModal = (socialNetwork, url, text, title) => {
    switch (socialNetwork) {
        case 'linkedin':
            // According to https://developer.linkedin.com/docs/share-on-linkedin the size must be 520 x 570px
            popupCenter('http://www.linkedin.com/shareArticle?mini=true&title='+title+'&summary='+text+'&url='+url, 'linkedin', 520, 570);
            break;
        case 'twitter':
            // https://dev.twitter.com/web/tweet-button/parameters
            popupCenter('http://twitter.com/share?url='+url+'&text='+text, 'twitter', 550, 420);
            break;
        case 'facebook':
            // https://developers.facebook.com/docs/sharing/reference/share-dialog
            window.FB.ui({
                method: 'share',
                display: 'popup',
                href: url,
                quote: text
            }, function(response){});
            break;
        case 'email':
            window.open('mailto:?subject=' + title + '&body=' + text + encodeURIComponent('\r\n' + url), '_blank');
            break;
        case 'whatsapp':
            popupCenter('https://api.whatsapp.com/send?text=' + text + ' ' + url, 'whatsapp', 550, 420);
            break;
        default:
            return;
    }
}

const  popupCenter = (url, title, w, h) => {
    // Fixes dual-screen position
    let dualScreenLeft = window.screenLeft,
    dualScreenTop = window.screenTop,
    width = window.innerWidth,
    height = window.innerHeight;

    let left = ((width / 2) - (w / 2)) + dualScreenLeft;
    let top = ((height / 2) - (h / 2)) + dualScreenTop;
    let newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

    // Puts focus on the newWindow
    if (window.focus) {
        newWindow.focus();
    }
}

export { shareModal };