import styled from "styled-components";
import { breakpoints } from "themes/breakpoints.theme";

const CountdownList = styled.ul`
    list-style: none;
    color: #fff;
    display: flex;
    padding: 0;
    margin: 0;
`

const CountdownMeasure = styled.li`
    font-family: Overpass;
    font-size: 8px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(255, 250, 250, 0.4);
    display: flex;
    flex-wrap: wrap;
    width: min-content;
    text-align: center;
    text-transform: uppercase;
` 
const CountdownValue = styled.span`
    font-family: Anton;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.95;
    letter-spacing: -0.5px;
    text-align: left;
    flex:100%;
    color: rgba(255, 255, 255, 0.6);
    
    line-height: 29px;
    margin-bottom: 5px;
    
`

const CountdownColon = styled.li`
    font-family: Anton;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    
    
    line-height: 29px;
    letter-spacing: -0.5px;
    text-align: left;
    width: auto;
    margin: 0 8px;
    color: rgba(255, 255, 255, 0.6);
`

const CountdownContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    z-index: 5;

    > h1{
        font-family: Anton;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 28px;
    text-align: left;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
    line-height: 29px;
    margin-bottom: 2px;

    }
    @media (max-width: ${breakpoints.sm}) {
        margin: 24px 16px;
    }
    @media (min-width: ${breakpoints.sm}) {
        margin: 24px 16px;
    }
    @media (min-width: ${breakpoints.md}) {
        margin: 24px 32px;
    }
    @media (min-width: ${breakpoints.lg}) {
        margin: 32px 56px;
    }
`
export {CountdownContainer, CountdownValue, CountdownMeasure, CountdownList, CountdownColon}