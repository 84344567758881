import AccordionItem from './AccordionItem';
import { Container } from 'themes/MoGrid';
import { Wrapper, Heading, Body } from './Accordion.style';
import { pushAccordionEvent } from '../../../tracking/events';

interface Props {
    title: string;
    items: any[]
}

const Accordion: React.FC<Props> = ({ title, items }) => {

    //Data Tracking
    const onToggleAccordion = (isOpen: boolean, subHeading: string) => {
        pushAccordionEvent({ id: title, name: subHeading, action: isOpen ? "open" : "close" });
    }

    return (
        <Container>
            <Wrapper>
                <Heading>{title}</Heading>
                <Body>
                    {items.map((item, i) => 
                    <AccordionItem key={i} index={i} subHeading={item.subheading} content={item.content} onToggleAccordion={(isOpen) => onToggleAccordion(isOpen, item.subheading)}/>
                    )}
                </Body>
            </Wrapper>
        </Container>
    );
};

export default Accordion;