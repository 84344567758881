import React from 'react';
import { Container, Row, Col } from 'themes/MoGrid';
import { getImage } from 'components/utils';
// eslint-disable-next-line
import { StyledLink, Heading, PageRow, SocialRow, ContactRow, TitleUnderline, LightText, SocialLink } from './nav.style';
import { pushAnyClickEvent } from '../../tracking/events';

const Nav = ({nav, socials}) => {
    return ( 
        <Container>
            <PageRow>
                <Row>
                    {Object.keys(nav).map((col, index) => 
                        <React.Fragment key={index}>
                            { nav[col].items.length > 0 &&
                                <Col sm={3} xs={6} >
                                    <Heading>{nav[col].heading}</Heading>
                                    {nav[col].items.map((item, i) => 
                                        <StyledLink key={i} href={item.url} onClickCapture={() => pushAnyClickEvent({linkUrl: item.url, linkText: item.title})}>{item.title}</StyledLink>
                                    )}
                                </Col>
                            }
                        </React.Fragment>
                    )}
                </Row>
            </PageRow>
           
            <SocialRow>
                {socials.map((social, index) =>
                     <SocialLink href={social.url} key={index} onClickCapture={() => pushAnyClickEvent({linkUrl: social.url, linkText: social.name})}>
                        <img src={getImage(social.icon, 'small')} width="18" height="18" alt={social.icon.alt}/>
                        <span>{social.name}</span>
                    </SocialLink>
                )}
            </SocialRow>

            {/* <ContactRow>
                <TitleUnderline>{contact.title}</TitleUnderline>
                <Row>
                    {contact.cols.map((col, index) =>
                        <Col sm={3} xs={6} key={index}>
                            <div>{col.heading}</div>
                            <LightText>
                                {col.items.map((item, i) =>
                                    <div key={i}>
                                        {item.link
                                            ? <AnimatedLink href={item.link}>{item.text}</AnimatedLink>
                                            :  <div>{item.text}</div>
                                        }
                                    </div>
                                )}
                            </LightText>
                        </Col>
                    )}
                </Row>
            </ContactRow> */}
        </Container>
     );
}
 
export default Nav;
