import React from 'react';
import { MessageContainer, MessageMinor, MessageMajor } from './MoMessage.style'

const MoMessage = () => {
    return ( 
        <MessageContainer>
            <MessageMinor>What we stand for</MessageMinor>
            <MessageMajor>TACKLING<br/>PROSTATE CANCER,<br/>TESTICULAR CANCER,<br/>MENTAL HEALTH<br/>AND SUICIDE<br/>PREVENTION</MessageMajor>
        </MessageContainer>
     );
}
 
export default MoMessage;