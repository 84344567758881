import styled from "styled-components";

const Container = styled.div`
`;

const Content = styled.div<{isInMultiColumn?:boolean}>`
    display: flex;
    flex-direction: column;
    padding: 32px;
    ${props => props.isInMultiColumn && `
        margin-left: -32px;
        margin-right: -32px;
    `}

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        flex-direction: row;
        padding: 0;
        margin-left: 0;
        margin-right: 0;
    }
`;

const ImageDesktop = styled.div`
    display: none;
    flex: 1;
    padding: 48px;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
        display: block;
    }
`;

const ImageCon = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 100%;

    img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
`;

const MobileImageCon = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 75%;

    img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
`;

const ImageMobile = styled.div`
    flex: 1;
    width: 100%;
    margin-bottom: 24px;
    position: relative;

    img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
        display: none;
    }
`;

const Description = styled.div`
    flex: 1;
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 44px;

    & > h2 {
        margin-top: 0;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        flex: 1;
        padding: 48px;
        margin-bottom: 0;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
        border-left: solid 1px;
    }
`;

const Form = styled.div<{hasError:boolean, hasLeftContent:boolean, isInMultiColumn?:boolean}>`
    flex: 1;
    margin-bottom: 16px;

    ${props => props.hasError && `
        background-image: url('resources/images/global/error.svg');
    `}
   
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        flex: 1;
        padding: 48px;
        margin-bottom: 0;
        ${props => props.hasLeftContent && `
            border-left: solid 1px grey;
        `}
        ${props => props.isInMultiColumn && `
            margin-left: -48px;
            margin-right: -48px;
        `}
    }
`;

const InputGroup = styled.div`
    margin-bottom: 24px;
    input {
        border: solid 1px grey;
    }    
`;

const Label = styled.div`
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 12px;
`;

const ConsentGroup = styled.div`
    display: flex;
`;

const CheckboxWrapper = styled.div`
    display: inline-block;
    margin-top: 2px;
    margin-right: 6px;
    vertical-align: middle;
`;

const Consent = styled.div`
    display: inline-block;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
`;

const Error = styled.div<{isShown:boolean}>`
    display: ${props => props.isShown ? 'block' : 'none'};
    color: #D93A3F;
    font-size: 14px;
`;

const ButtonWrapper = styled.div`
    margin-top: 24px;
    & > a {
        width: 100%;
    }
`;

export { Container, Content, ImageMobile, MobileImageCon,ImageDesktop, ImageCon, Description, Form, InputGroup, Label, CheckboxWrapper, ConsentGroup, Consent, Error, ButtonWrapper }