import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    margin-top: 24px;
    justify-content: space-between;
    &:first-child {
        margin-top: 32px;
    }
    &:last-child {
        margin-bottom: 32px;
    }
`

export const ImageContainer = styled.img`
    height: 64px;
    width: 64px;
    min-width: 64px;
    margin-right: 16px;
    border-radius: 4px;
`

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 16px;
`

export const Button = styled.button`
    color: #333;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Overpass;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-radius: 80px;
    border: 1px solid #8F909B;
    padding: 10px 16px;
    background: none;
    text-transform: capitalize;
    max-height: 30px;
    min-height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
`

export const NoResultsContainer = styled.div`
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: center;
`