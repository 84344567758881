import React, {useState, useEffect, useRef } from 'react';
import { MarqueeItem, MarqueeContainer, MarqueeArea } from './Marquee.style'; 


const getFillList = (marquees, copyTimes = 3) => {
  let newlist = [];
  for (let i = 0; i < copyTimes; i++) {
    newlist.push(...marquees);
  }
  return newlist;
};



const Marquee = ({ marquees, ...props }) => {
  // Set universal time 
  
  const time = 40
  const marqueeContainer = useRef(null);
  const marqueeArea = useRef(null);
  const [moveLeft, setMoveLeft] = useState(0);
  const [showList, setShowList] = useState(marquees);
  const [realTime, setRealTime] = useState(time);

  useEffect(() => {
    const containerWidth = Math.floor(marqueeContainer.current.offsetWidth);
    const areaWidth = Math.floor(marqueeArea.current.scrollWidth);
   
    const copyTimes = Math.max(2, Math.ceil((containerWidth * 2) / areaWidth));

    const newMoveLeft = areaWidth * Math.floor(copyTimes / 2);

    const newRealTime =
      time * parseFloat((newMoveLeft / containerWidth).toFixed(2));
    setShowList(getFillList(marquees, copyTimes));
    setMoveLeft(newMoveLeft);
    setRealTime(newRealTime);
  }, [marquees]);
  return (
    <MarqueeContainer ref={marqueeContainer} {...props}>
      <MarqueeArea ref={marqueeArea} move={moveLeft} time={realTime}>
        {
          showList.map((item, index) => {
          return <MarqueeItem key={index}>{item}</MarqueeItem>;
        })}
      </MarqueeArea>
    </MarqueeContainer>
  );
};


export default Marquee;