import styled from 'styled-components';

const Button = styled.div`
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    min-width: 146px;
    padding: 16px 32px;
    margin: 12px 6px;
    text-decoration: none;
    letter-spacing: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #FFF;
    border-radius: 24px;
    background: ${props => props.theme.palette.primary.normal};
    cursor: pointer;

    i {
        margin-left: 8px;
        font-size: 10px;
    }
`;

const Dropdown = styled.div`
    position: absolute;
    top: 60px;
    right: 0;
    min-width: 286px;
    transition: all 0.2s ease 0s;
    
    visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
    opacity: ${props => props.isVisible ? '1' : '0'};

    &::before {
        content: "";
        position: absolute;
        display: block;
        border: 12px transparent double;
        border-bottom: 12px  ${props => props.theme.palette.mo.charcoal} solid;
        top: -22px;
        right: 55px;
    }
`;

const Option = styled.a`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 24px;
    font-size: 16px;
    color: #333333;
    background-color: ${props => props.theme.palette.secondary.normal};
    border: solid 1px ${props => props.theme.palette.secondary.border};
    border-radius: 4px;
    border-bottom: none;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 1px;
    font-weight: 500;


    i {
        display: inline-block;
        margin-left: 10px;
        width: 16px;
        height: 14px;
        background-image: url("/resources/images/icons/arrow.png");
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        transition: all 0.2s ease 0s;
        filter: invert(1);
    }

    &:first-child {
        color: #fff;
        background-color:  ${props => props.theme.palette.mo.charcoal};
        i {
            filter: invert(0);
        }
        &:hover {
            background-color: #444444;
        }
       
    }

    &:nth-child(3) {
        border-radius: 4px 4px 0 0;
    }

    &:last-child {
        border-radius: 0 0 4px 4px;
        border-bottom: solid 1px;
    }

    &:hover {
        background: ${props => props.theme.palette.secondary.hover};
        i {
            transform: translateX(20%);
        }
    }
`;

const Separator = styled.div`
    height: 8px;
`;

export { Button, Dropdown, Option, Separator };