
import Text from 'components/common/Text/Text.jsx';
import { Button, Container, DetailsContainer, ImageContainer } from './TeamsSearch.style.js';
import { useTranslation, getApiUrl } from 'components/utils';

const SearchResultsTeams = ({ searchResult, memberId, onSelectTeam }) => {
  const translate = useTranslation;

    const handleClick = async (selection) => {
        // mov.helper.overlay.showGlobalLoading();
        if (memberId){
          const api = getApiUrl('team-member');
          const data = {
              "teamId": selection.id,
              "memberId": memberId
          }
          const response = await fetch(api, {
              method: "POST",
              body: JSON.stringify(data)
          })
          const result = await response.json();
          // mov.helper.overlay.hideGlobalLoading();
  
          if(result) {
            onSelectTeam(selection, result)
          }
        } else {
          window.location.href = `/register/join-team?id=${selection.id}&mocamp=mtar`;
        }
    }

  return (
    <div>
      {searchResult.map((result, idx) => {
        const {
          ProfilePicture: { defaultURL },
          totalNumMembers,
          name,
          Captain,
          isPrivate,
        } = result;
        const captainName = Captain && Captain.fullName;

        return (
          <Container key={idx}>
            <ImageContainer src={defaultURL} alt=""/>
            <DetailsContainer>
              <Text variant='overpassSm' margin="0 0 4px">{name}</Text>
              <Text variant='overpassExtSm'>{captainName} {translate('text-team-captain')}</Text>
              <Text variant="overpassExtSm">{`${totalNumMembers} ${translate(`text-team-${totalNumMembers <= 1 ? 'member' : 'members'}`)}`}</Text>
            </DetailsContainer>
            <Button type="button" onClick={() => handleClick(result)}>{translate(`text-team-${isPrivate ? 'request' : 'join'}`)}</Button>
          </Container>
        );
      })}
    </div>
  );
};

export default SearchResultsTeams;
