import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
    display: block;
    padding-left: 16px;
    padding-right: 16px;

    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }


    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.xs}){
       padding-left: 16px;
       padding-right: 16px;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.sm}){
        padding-left: 32px;
        padding-right: 32px;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.md}){
        padding-left: 32px;
        padding-right: 32px;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.lg}){
        padding-left: 56px;
        padding-right: 56px;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.xl}){
        padding-left: 80px;
        padding-right: 80px;
    }


`;

const Container = (props) => {
    const {children} = props;
    return ( 
        <StyledContainer {...props}>
            {children}
        </StyledContainer>
     );
}
 
export default Container;