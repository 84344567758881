import React, { useState, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { CMSContext } from 'context/AppContext';

const MoDialog = ({children, open, onClose, ...other}) => {
    let CmsContext = useContext(CMSContext);
    const [cms] = useState(CmsContext);

    return (
        <Dialog open={open} onClose={onClose} {...other}>
            {React.cloneElement(children, {...{modalsCms: cms.modals}})}
        </Dialog>
    );
}
 
export default MoDialog;