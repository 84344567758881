import React, { Component } from 'react';
import MoVideo from './MoVideo/MoVideo.jsx';
import MoMessage from  './MoMessage/MoMessage';
import MoCard from './MoCard/MoCard';
import MoConclusion from './MoConclusion/MoConclusion';

const body1 = "Our fathers, brothers, sons and friends are dying by suicide, every minute of every day. We won’t accept this. To make change happen, Movember views mental health through a male lens. We focus on early intervention, engaging men and working to their strengths. By 2030, we aim to reduce the rate of male suicide by 25%.",
body2 = "Men with prostate cancer and testicular cancer face an uphill battle before, during and after treatment. But with useful tools and straight-shooting advice, we aim to lighten their load so these men can live life to the fullest.",
body3 = "Globally, men die on average 6 years earlier than women, and for largely preventable reasons. So we’re shining a light on the health risks men need to know about, increasing awareness to stop men dying too young.",
body4 = "A crisis this size calls for big ideas from the brightest minds. Movember brings together the best researchers and experts from across the globe, reducing research overlap to maximise brainpower and accelerate progress.",
body5 = "In the month formerly known as November, Mo Bros and Mo Sistas across the world Grow a Mo, Move for Movember or Host a Mo-ment to raise much-needed funds for men’s health.";


class Home extends Component {
    render() { 
        return (
            <div>
                <MoVideo />
                <MoMessage />
                <MoCard 
                    imageUrl='/resources/images/homepage/preventing-suicide-in-men.webp'
                    title="Preventing suicide in men"
                    body={body1}
                    reverse={false}
                />
                <MoCard 
                    imageUrl='/resources/images/homepage/improving-quality-of-life.webp'
                    title="Improving quality of life"
                    body={body2}
                    reverse={true}
                />
                <MoCard 
                    imageUrl='/resources/images/homepage/preventing-suicide-in-men.webp'
                    title="Helping men take action"
                    body={body3}
                    reverse={false}
                />
                <MoCard 
                    imageUrl='/resources/images/homepage/teaming-up-with-great-minds.webp'
                    title="Teaming up with great minds"
                    body={body4}
                    reverse={true}
                />
                <MoCard 
                    imageUrl='/resources/images/homepage/raising-funds-during-movember.webp'
                    title="Rasing funds during movember"
                    body={body5}
                    reverse={false}
                />
                <MoConclusion></MoConclusion>
          
            </div>
        );
    }
}
 
export default Home;