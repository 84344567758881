import styled from "styled-components";

export const Input = styled.input`
    border-radius: 4px;
    width: 100%;
    height: 48px;
    padding: ${({ hasIcon }) => hasIcon ? '0 5rem 0 1rem' : '0 1rem'};
    &&& {
        border: 1px solid ${({ noInput }) => noInput ? "#D93A3F" : "#999999"};
        box-sizing: border-box;
        &:focus {
            border: 1px solid ${({ noInput }) => noInput ? "#D93A3F" : "#999999"};
        }
    }
    ::placeholder {
        color: #B9BAC5;
    }

    ::-webkit-search-decoration,
    ::-webkit-search-cancel-button,
    ::-webkit-search-results-button,
    ::-webkit-search-results-decoration { 
        display: none;
    }
`

export const Container = styled.div`
    position: relative;
`