import {useState, useEffect, useContext} from 'react';
import { locales, getUrlLocale, getCookie, setCookie } from 'components/utils';
import { Container, Bar, Language, Close } from './LanguageBar.style';
import ConfigContext from 'context/ConfigContext';
import { pushAnyClickEvent } from '../../tracking/events';

const LanguageBar = () => {
    let config = useContext(ConfigContext);
    const [isActive, setIsActive] = useState(false);
    let {country, language} = getUrlLocale();
    
    useEffect(() => {
        let languageBarDisabled = getCookie('language-bar-is-disabled');
        let hasMultipleLanguages = config.getValue(["locale", country, "language"]) && config.getValue(["locale", country, "language"]).length > 1;
        if (!languageBarDisabled && hasMultipleLanguages){
            setIsActive(true);
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const setLanguageBarState = () => {
        setIsActive(false);
        setCookie('language-bar-is-disabled', 'true', 30);
    }
    const onSelectLanguage = (code, isDefault, isSelected, name) => {
        if(isSelected) {
            setLanguageBarState()
            return;
        }
        const { origin, pathname } = window.location;

        // Array of supportedLanguages = ['en', 'fr', 'de', 'nl', 'es', 'nb', 'sv', 'cs']
        // I wanted to derive it from locales as the source of truth
        const supportedLanguages = Object.values(locales).map(subdomain => subdomain.language.flatMap(flat => flat)).flatMap(flat => flat).map(lang => lang.code).filter((val, idx , arr) => idx === arr.indexOf(val))
        
        // example: ch.movember.com/fr/ => ch.movember.com/en/ 
        const alreadyOnSecondaryLocale = supportedLanguages.includes(pathname.split('/')?.[1]); 

        const redirectedPathname = isDefault ? `/${pathname.split("/").slice(2).join("/")}` : alreadyOnSecondaryLocale ? `/${code}/${pathname.split("/").slice(2).join("/")}` : `/${code}${pathname}`;

        //Data tracking
        const redirectLink = `${origin}${redirectedPathname}`
        pushAnyClickEvent({linkText: name, linkUrl: redirectLink});
        
        window.location = redirectLink;
        
        setLanguageBarState();
    }

    const isClientDefaultLanguage = language.length === 0;

    const localeDetails = config.getValue(["locale",country, "language"])

    return ( 
        <Container isVisible={isActive}>
            <Bar>
                {localeDetails && localeDetails.map((lang, index) => {
                    const isDefaultSelected = isClientDefaultLanguage === lang.default; 
                    const isSelected = language ? language === lang.code : isDefaultSelected;
                    return (
                        <Language key={index} 
                            isSelected={isSelected}
                            onClick={() => onSelectLanguage(lang.code, lang.default, isSelected, lang.name)}>
                                {lang.name}
                        </Language>
                    )
                })}
                <Close onClick={setLanguageBarState}/>
            </Bar>
        </Container>
    );
}
 
export default LanguageBar;