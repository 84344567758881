import React from 'react';
import StyledText from './Text.style';

const Text = (props) => {
    const { children, tag, display } = props;
    return ( 
        <StyledText {...props} display={display || (tag === 'p' ? 'block' : 'inline')} as={tag}>
            {children}
        </StyledText>
    );
}

/**
 * Definition of default values for component props
 */
Text.defaultProps = {
    children: undefined,
    tag: 'span',
    level: 'normal',
    truncate: false,
    color: undefined,
    display: undefined,
    align: 'inherit',
    fontFamily: "Overpass",
    fontWeight: undefined,
    marginTop: '0',
    marginBottom: undefined
  };
 
export default Text;