import { useState, useEffect, useRef } from "react";
import {
  GetInvolvedContainer,
  GetInvolvedBlock,
  GetInvolvedCopy,
  GetInvolvedImage,
  GetInvolvedCta,
  GetInvolvedHeading,
} from "./GetInvolved.style";
import ProductColors from "components/common/Colors/ProductColors";
import { renderImage } from "components/utils";
import { Button } from 'components/common';
import { pushAnyClickEvent } from '../../../tracking/events'

const ProductBlockWithContext = props => {
  const ref = useRef()
  return(<GetInvolvedBlock ref={ref} {...props} />)
};

const GetInvolved = (props) => {
  // eslint-disable-next-line
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  
  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
      
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  // eslint-disable-next-line
  const getProductColor = (product) =>{
    const ProductString = String(product)
    switch(true){
      case (ProductString.indexOf('moustache') > -1):
        return ProductColors.Grow
      case (ProductString.indexOf('move') > -1):
        return ProductColors.Move
      case (ProductString.indexOf('host') > -1):
        return ProductColors.Host
      case (ProductString.indexOf('mo-your-own-way') > -1):
        return ProductColors.MYOW
      default:
        return "#fff"
    }
  }
  
  return (
    <GetInvolvedContainer>
      {props.get_involved_cards.map((card, index)=>{
        return(
          <ProductBlockWithContext onClick={()=> pushAnyClickEvent({linkText: card.title, linkUrl: card.link})} top={scrollTop} href={card.link} key={card.link} backgroundImage={`/resources/images/global/get-involved/background-getInvolved-column-${index+1}.jpg`}>
            <GetInvolvedCopy>
              <GetInvolvedHeading>
                {card.title}
              </GetInvolvedHeading>
              <p>
                {card.description}
              </p>
            </GetInvolvedCopy>
            <GetInvolvedImage>
              {renderImage(card.image)}
            </GetInvolvedImage>
            <GetInvolvedCta>
              <Button onClick={(e)=> {e.stopPropagation(); pushAnyClickEvent({linkText: card.cta, linkUrl: card.link})}} onClickCapture={()=>{}} btnText={card.cta} btnLink={card.link} btnType="secondaryArrow"/>
            </GetInvolvedCta>
          </ProductBlockWithContext>
        )
      })}
    </GetInvolvedContainer>
  );
};

export default GetInvolved;
