import styled from 'styled-components';

const Copy = styled.div`
    display: flex;
    justify-content: center;
    
`;

const CopyContent = styled.div`
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        display: flex;
        max-width: 1366px;
        justify-content: space-between;
    }
`;

const CopyItem = styled.div`
    flex: 1;
    margin: 70px 12px;
`;

const ItemLink = styled.a`
    font-size: 24px;
    cursor: pointer;
    text-decoration: none;
    color: #000;

    & > span {
        border-bottom: solid 2px #001eff;
        margin-right: 10px;
    }
    & > img {
        filter: brightness(0);
    }

    &:hover {
        color: #001eff;

        & > img {
            filter: none;
            animation-name: bounce;
            animation-duration: 0.5s;
            animation-fill-mode: both;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
        }
        @keyframes bounce {
            0%   { transform: translateX(0); }
            50%  { transform: translateX(10px); }
            100% { transform: translateX(0); }
        }
       
    }
`;

export { Copy, CopyContent, CopyItem, ItemLink };