import styled, {css} from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 16px 0;

    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.sm}){
      flex-direction: row;
      margin: 32px 0;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.lg}){
      margin: 56px 0;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.xl}){
      margin: 80px 0;
    }
`;

const Heading = styled.h2`
    flex: 1;
    padding: 0 20px;
    font-family: Overpass;
    font-size: 22px;
    font-weight: 500;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
      font-size: 36px;
    }
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
      font-size: 28px;
    }
`;

const Body = styled.div`
    flex: 1.6;
`;

const Section = styled.div`
  padding: 20px;
  border-top: ${(props: {firstItem: boolean}) =>
    props.firstItem ? "solid 1px #000" : "none"};
  border-bottom: solid 1px #000;
  transition: height 0.5s ease-in-out;
`;

const SubHeading = styled.h3`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  font-family: Overpass;
  font-size: 22px;
  font-weight: 500;

  @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
    font-size: 36px;
  }
  @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
    font-size: 28px;
  }
`;

const Icon = styled.span`
    & > svg {
        font-size: 16px;
        transition: all 0.5s ease-in-out;
        ${(props: {isOpen: boolean}) => props.isOpen && css`
            transform: rotate(-180deg); 
        `}
`;

const Content = styled.div`
  max-height: ${(props: {isOpen: boolean, height: number}) => (props.isOpen ? `${props.height}px` : '0')};
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
`;

export { Wrapper, Heading, Body, Section, SubHeading, Icon, Content };