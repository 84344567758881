import styled from 'styled-components';
import { breakpoints } from 'themes/breakpoints.theme';

const Container = styled.div`
    max-height: 700px;
    padding: 60px 0px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 20px;

    @media screen and (min-width: ${breakpoints.md}) {
        align-items: center;
        flex-direction: row;
        padding: 0 30px;
    }
    @media screen and (min-width: ${breakpoints.lg}){
        padding: 0 60px;
    }
`;

const Body = styled.div`
    margin-top: 16px;
    padding-left: 10px;

    @media screen and (min-width: ${breakpoints.md}) {
        flex-direction: row;
        padding-left: 20px;
    }
    @media screen and (min-width: ${breakpoints.lg}){
        padding-left: 46px;
    }
`;

const Heading = styled.h2`
    font-size: 24px;
    font-weight: normal;
    line-height: 1.4;

    @media screen and (min-width: ${breakpoints.lg}){
        font-size: 30px;
    }
`;

const Navigator = styled.div`
    display: flex;
    justify-content: space-between;
`;

const SeeMore = styled.a`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    height: 40px;
    padding: 8px 16px;
    margin-right: 16px;
    font-family: Overpass;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    background-color: ${props => props.theme.palette.mo.charcoal};
    letter-spacing: normal;
    line-height: normal;
    border: none;
    border-radius: 24px;
    cursor: pointer;

    &:hover, &:active, &:focus {
        font-weight: bold;
        color: #fff;
        background-color: ${props => props.theme.palette.mo.charcoal};
        border: none;
        outline: none;
        span {
            background-image: url('/resources/images/icons/arrow.png');
        }
    }

    span { 
        display: inline-block;
        margin-left: 10px;
        width: 14px;
        height: 12px;
        border: none;
        background-image: url('/resources/images/icons/arrow.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    @media screen and (min-width: ${breakpoints.md}){
        color: ${props => props.theme.palette.mo.charcoal};
        background-color: rgba(0, 0, 0, 0.1);
        span {
            background-image: url('/resources/images/icons/arrow-black.png');
        }
    }   
`;

const ArrowsContainer = styled.div`
    display: inline-flex;
`;

const StepIndicator = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 40px;
    padding: 0 4px;
    font-size: 12px;
    font-weight: bold;

    @media screen and (min-width: ${breakpoints.sm}) {
        display: none;
    }
`;

const Arrow = styled.a`
    width: 40px;
    height: 40px;
    color: #000;
    background-color: rgba(0, 0, 0, 0.1);
    background-image: url('/resources/images/icons/arrow-black.png');
    background-position: center;
    background-size: 14px;
    background-repeat: no-repeat;
    cursor: pointer;
    outline: none;
    border-radius: 4px;

    ${props => props.direction === 'left' && `
        transform: rotate(180deg);
    `}

    &:hover {
        background-color: #000;
        border: none;
        outline: none;
        background-image: url('/resources/images/icons/arrow.png');
    }

    &:focus {
        outline: none;
    }

    @media screen and (min-width: ${breakpoints.sm}){
        color: #000;
        background-color: rgba(0, 0, 0, 0.1);
        background-image: url('/resources/images/icons/arrow-black.png');
        background-position: center;
        background-size: 14px;
        background-repeat: no-repeat;
        ${props => props.direction === 'left' && `
            margin-right: 20px;
        `}
    }
`;

const Slide = styled.div`
    display: block;
    padding: 0 10px; 
    width: 275px;
    text-decoration: none;
    font-weight: normal;
    cursor: pointer;

    @media screen and (min-width: ${breakpoints.md}) {
        width: 310px;
    }

    @media screen and (min-width: ${breakpoints.lg}) {
        width: 400px;
        padding: 0 16px; 
    }

    &:hover {
        font-weight: normal;
        img {
            transform: scale(1.05, 1.05);
            transition-duration: 500ms;
        }
        a {
            color: #fff;
            background-color: ${props => props.theme.palette.mo.charcoal};
        }
    }
`;

const Image = styled.div`
    position: relative;
    overflow: hidden;
    padding-bottom: 100%;
    background-color: #c4c4c4;
    img {
        position: absolute;
        width: 100%;
        height: 100%;
    }
`;

const CTA = styled.a`
    position: absolute;
    left: 18px;
    bottom: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    padding: 0 16px;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    background-color: ${props => props.theme.palette.mo.charcoal};
    cursor: pointer;
    font-weight: 400;
    border-radius: 24px;

    span { 
        display: inline-block;
        margin-left: 10px;
        width: 14px;
        height: 12px;
        border: none;
        background-image: url('/resources/images/icons/arrow.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    @media screen and (min-width: ${breakpoints.md}){
        color: #fff;
        background-color: rgba(0, 0, 0, 0.6);
    }   
`;

const Title = styled.h3`
    margin-top: 12px;
    margin-bottom: 6px;
    font-size: 18px;
    font-weight: 600;
    line-height: 120%;
`;

const Description = styled.div`
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
`;

export { Container, Header, Body, Heading, Navigator, SeeMore, StepIndicator, ArrowsContainer, Arrow,
    Slide, Image, CTA, Title, Description };