import styled from 'styled-components';

const Container = styled.div`
    margin-top: 68px;
`;

const Header = styled.div`
    a {
        font-weight: normal;
        color: ${props => props.theme.palette.link.normal};
        text-decoration: none;
    
        &:hover {
            font-weight: normal;
            color: ${props => props.theme.palette.link.hover};
        }
    }
`;

const Icon = styled.i`
    font-size: 18px;
    margin-left: 8px;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
        font-size: 24px;
    }
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.xl}){
        font-size: 32px;
    }
`;

const ItemList = styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.xs}){
        flex-direction: row;
    }
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        flex-direction: column;
    }
`;

const Item = styled.div`
    a {
        flex: 1;
        display: flex;
        flex-direction: row;
        max-width: 100%;
        padding: 0;
        margin-bottom: 16px;
        text-decoration: none;
        color: #000;
        cursor: pointer;
    
        @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.xs}){
            flex-direction: column;
            max-width: 34%;
            padding: 0 4px;
        }
        @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
            flex-direction: row;
            max-width: 100%;
            padding: 0;
        }
    }
    
`;

const ImageContainer = styled.div`
    flex: 1;
    background-color: #000;
    position: relative;
    padding-bottom: 28%;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.xs}){
        padding-bottom: 56%;
    }
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        padding-bottom: 28%;
    }
`;

const Picture = styled.div`
    picture {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    
        source, img {
            width: 100%;
            max-height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            object-fit: cover;
    object-position: center;
        }
    }
`;

const Image = styled.img`
    background-image: ${props => props.backgroundImage};
    height: 100%;
    width: 100%;
    background-size: cover;
`;

const InfoContainer = styled.div`
    flex: 1;
    position: relative;
    padding-bottom: 28%;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.xs}){
        padding-bottom: 56%;
    }    
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        padding-bottom: 28%;
    }
`;

const Info = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 8px;
    color: #fff;
    background-color: #000;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
        padding: 16px;
    }

`;

const Date = styled.div`
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: -.5px;
    opacity: .7;
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
        font-size: 12px;
    }
`;

const Title = styled.div`
    font-size: 12px;    
    font-weight: 600;
    // Limit text in 2 lines
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
        font-size: 14px;
    }
`;

const Author = styled.div`
    font-size: 10px;
    opacity: .7;
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
        font-size: 12px;
    }
`;

export { Container, Header, Icon, ItemList, Item, ImageContainer, Picture, Image, InfoContainer, Info, Date, Title, Author };