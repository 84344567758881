import styled from 'styled-components';

// Wrapper
const WrapperContainer = styled.div `
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${props => props.theme.palette.mo.charcoal};
`;

const MarqueeContainer = styled.div `
  position: relative;
  width: 100%;
  margin-top: 20px;
  padding: 10px 0;
  background-color: ${props => props.theme.palette.mo.charcoal};
  overflow: hidden;
 
  *{
      color: #fff;
      font-size: 15px;
    font-family: 'Overpass', 'open sans', 'Helvetica', sans-serif;
    font-weight: 100;
  }
`;

const MarqueeArea = styled.div `
  
  display: inline-block;
  white-space: nowrap;
  transform: translateX(-${(props) => props.move}px);
  animation-delay: 0.5s;
  animation-direction: normal;
  
animation-duration: ${props => props.item ? props.time + 's': '40s'};
  animation-name: marqueeMove;
  animation-iteration-count: infinite;
 animation-timing-function: linear;
  @keyframes marqueeMove {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(${props => props.move ? '-' + props.move + 'px' : '-2000px'});
      }
  }

  
`;


const MarqueeItem = styled.div `
  position: relative;
  display: inline-block;
  margin-right: 65px;
`;

const Context = styled.div `
    position: relative;
    display: block;
    background: black;
`;


export {
  Context,
  MarqueeItem,
  WrapperContainer,
  MarqueeContainer,
  MarqueeArea
};