const palette = {
    primary: {
        text: "#FFF",
        normal: "#0C0C0C",
        hover: "#333333",
        border: "#0C0C0C",
        borderHover: "#333333"
    },
    secondary: {
        text: "#000",
        normal: "#FFFFFF",
        hover: "#F3F3F3",
        border: "#999999",
        borderHover: "#999999"
    },
    link: {
        normal: "#0C0C0C",
        hover: "#666666"
    },
    error: {},
    warning: {},
    info: {},
    success: {},
    mo: {
        charcoal: "#151515",
        charcoal2: "#222222",
        greyTone: "#161719",
    },
    hover: {
        color: '#2E2E34'
    }
};

export default palette;