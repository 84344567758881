import styled from 'styled-components';

const Title = styled.div`
    font-family: 'Anton';
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0px;
    margin: 16px 0;
`;

const UrlContainer = styled.div`
    min-height: 56px;
    width: 100%;
    background-color: #efefef;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
`;

const UrlDisplay = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-right: 50px;
`;

const UrlEdit = styled.div`
    max-width: 100%;
`;

const UrlContent = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const EditIcon = styled.a`
    position: absolute;
    top: 0;
    right: 2rem;
    cursor: pointer;
`;

const EditInput = styled.input`
    flex: 1;
    height: 42px;
    margin-left: 4px;

    ${props => props.hasError && `
        border-color: #FF0000;
    `}
`;

const EditContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Actions = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Error = styled.div`
    font-size: 80%; 
    font-weight: normal;
    color: #FF0000;
    margin-bottom: 1rem;
`;

const CharacterLeft = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
`;

const EditButtons = styled.div`
    padding: 1rem 3rem;
    width: 100%;
    max-width: 300px;
`;

const EditButton = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 80px;
    min-height: 32px;
    padding: 8px 20px;
    color: #000;
    margin-right: 8px;
    font-family: Overpass;
    letter-spacing: normal;
    line-height: 1;
    text-transform: none;
    background-color: #fff;
    border: solid 2px #000;

    ${(props) => (props.isPrimary) && `
        color: #fff;
        background-color: #001EFF;
        font-weight: normal;
        border: none;
    `}

    &:hover {
        ${(props) => (!props.isPrimary) && `
            color: #fff;
            background-color: #000;
            border: none;
        `}
    }
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: solid 1px #efefef;
`;

const SocialListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const Button = styled.button`
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    display: flex;
    align-items: center;
    height: 56px;
    width: 100%;
    max-width: 300px;
    color: #000;
    background-color: transparent;
    border: none;
    text-transform: none;
    font-family: Overpass;
    padding: 0 3rem;

    &:hover {
        color: #fff;
        background-color: ${props => props.theme.palette.mo.charcoal};
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    }
`;

const SocialIcon = styled.i`
    width: 32px;
    font-size: 24px;
    margin-right: 2rem;
`;

const FooterButton = styled.button`
    color: #1443ef;
    background-color: transparent;
    border: none;
    padding: 0;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    font-family: Overpass;

    &:hover, &:focus {
        background-color: transparent;
        color: #1443ef;
        font-weight: bold;
    }
`;

export { Title, UrlContainer, UrlContent, UrlDisplay, UrlEdit, EditIcon, EditInput, EditContent, CharacterLeft, Actions, Error, EditButtons, EditButton, ButtonContainer, SocialListContainer, Button, SocialIcon, FooterButton } ;