import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, StaticRouter, Route, Switch } from "react-router-dom";
import Header from "components/header/header";
import Footer from "components/footer/footer";
import Home from "components/home/Home";
import DynamicPage from "components/content/DynamicPage";
import ImpactHub from "components/content/ImpactHub/ImpactHub";
import PartnersPage from "components/content/Partners/Partners"
import DollarMatching from "components/content/Partners/DollarMatching"
import * as Common from "components/common";
import { locales, cloudFrontUrls, isJson, getEnvironment, getAssetUrl, getImage, getConfigUrl, getUrlLocale } from "components/utils";
import { CMSContext, ConfigContext } from "./context/AppContext";
import MoConfiguration from 'MoConfiguration';
import defaultConfig from 'resources/json/defaultConfig.json';
import { CmsLogin, CmsLogout, CmsPreview } from "components/cms";
import { Helmet } from "react-helmet";
import { LiveChatWidget } from '@livechat/widget-react'
import { OptimizelyProvider, createInstance}from '@optimizely/react-sdk';
import { PreviousUrlProvider } from './context/PreviousUrlContext';

const MoRouter = () => {
  let previewCms = null;
  const [cms, setCms] = useState(false);
  const [hasJSON, setHasJSON] = useState(false);
  const [liveChatEnabled, setLiveChatEnabled] = useState(false);
  const [config, setConfig] = useState(useContext(ConfigContext));
  const [randomKey, setRandomKey] = useState(Math.random());
  const [optimizely, setOptimizely] = useState();
  const [optimizelyEnabled, setOptimizelyEnabled] = useState(false);
  let routes = [];
  

  useEffect(() => {
    // Listen out for any callbacks to enable LiveChat
    window.addEventListener("enableLiveChat", (e) => {
      setLiveChatEnabled(true);
    });

    (async () => {
      setAppLanguage();
      
      // Fetch data
      fetchToConfigJson();
  
      // Fetch cms json file
      await fetchToCmsJson();
      // Event listeners
      window.addEventListener("changeCms", (e) => {
        if (e.detail && e.detail.cms) {
          setCms(e.detail.cms);
        }
      });
  
       // for Cms Draft Preview
       window.addEventListener('message', (event) => {
        if (event.data && event.data !== 'undefined' && typeof(event.data) === 'string' && isJson(event.data)) {
            let eventData = JSON.parse(event.data);
            /* Don't render preview if
              - receiving empty message.
              - message doesn't have story data structure (story data object has only 1 key which is story id)
            */
            if((eventData && eventData.value === false) || Object.keys(eventData).length > 1 || !isStoryData(eventData)) return;
            renderPreview(eventData);
        }
      });
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

function isStoryData(eventData) {
  for (let id in eventData){
      if(!eventData[id] || typeof eventData[id] !== 'object' ||!eventData[id]?.hasOwn('title') || !eventData[id]?.hasOwn('url')){
          return false;
      }
  }

  return true;
}

  useEffect(() => {

    // If it's not prerender
    if(window.navigator.userAgent !== 'mo-site-pre-render'){

      if(config.getValue('app.tracking.livechat.enabled')){
        // set up LiveChat
        setupLiveChat();
      }
      if(config.getValue('app.tracking.cookies.onetrust.enabled')){
        // Install our OneTrust cookie solution, if enabled
        setUpOnetrustCookie();
        // Set up GTM
        // setUpGTMAfterOneTrust();
        setUpGTM();
      }

      if(config.getValue('app.tracking.optimizely.enabled')){
        // set up Optimizely
        setUpOptimizely();
      }
  
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  // LiveChat
  useEffect(() => {
    // Event listener to disable/enable LiveChat in uat/production
    //Default
    if (getEnvironment() === "uat") {
      let hideChatInterval = setInterval(() => {
        if (document.getElementById("chat-widget-container")) {
          hideLiveChat();
          clearInterval(hideChatInterval);
        }
      }, 500);
    }
    window.addEventListener("disableLiveChat", (e) => {
      if (e.detail && e.detail.env) {
        switch (e.detail.env) {
          default:
          case "uat":
            if (getEnvironment() === "uat") {
              hideLiveChat(e.detail.disable);
            }
            break;
          case "production":
            if (getEnvironment() === "prod") {
              hideLiveChat(e.detail.disable);
            }
            break;
          case "local":
            if (getEnvironment() === "local") {
              hideLiveChat(e.detail.disable);
            }
            break;
        }
      }
    });
  }, []);

  /* 
  MWD-6201: hide Livechat by JS console
  Example: 
    - To disable: window.dispatchEvent(new Event('disableLiveChat'));
    - To disable/enable: window.dispatchEvent(new CustomEvent('disableLiveChat', {detail: {env:'uat', disable: true}}));
  */
  function hideLiveChat(hide) {
    let liveChatContainer = document.getElementById("chat-widget-container");
    if (liveChatContainer) {
      liveChatContainer.style.display =
        hide === undefined || hide ? "none" : "block";
      if (liveChatContainer.style.display === "block") {
        liveChatContainer.style.width = "281px";
        liveChatContainer.style.height = "63px";
      }
    }
  }

  /**
   * Logic to determine from our configs if LiveChat is enabled for the current country
   * (Note: if using OneTrust to handle cookie compliance, we will still need to check OneTrust cookies on top of this setting)
   * @returns {boolean}
   */
  function liveChatConfigEnabled(){
    return config.getValue('app.tracking.livechat.enabled') === '1';
  }

  function optimizelyConfigEnabled(){
    return config.getValue('app.tracking.optimizely.enabled') === '1';
  }

  function setUpOptimizely() {
    if (optimizelyConfigEnabled()){
        setOptimizelyEnabled(true);
        const optimizelyScript = document.createElement('script');
        optimizelyScript.setAttribute('src',`https://cdn.optimizely.com/js/${ config.getValue('app.tracking.optimizely.code')}.js`);
        const head = document.querySelector('head');
        const metaCollection = document.getElementsByTagName("META");
        const optimizely = createInstance({
          sdkKey: config.getValue('app.tracking.optimizely.code'),
          //datafile: window.optimizelyDatafile,
          eventBatchSize: 10, // Cannot change in JavaScript (Browser) environment.
          eventFlushInterval: 2000, // Cannot change in JavaScript (Browser) environment.          
        });
        setOptimizely(optimizely);
        head.insertBefore(optimizelyScript, metaCollection[metaCollection.length-1].nextSibling);
    }
  }
  function setupLiveChat() {
    // Only set up LiveChat integration if our configs have it enabled in the first place
    if (liveChatConfigEnabled()) {
      // If we're not using OneTrust, we will purely use our config settings to decide whether to show LiveChat or not
      if (!oneTrustConfigEnabled()) {
        setLiveChatEnabled(true);
      } else {
        // When OneTrust is enabled, don't just initialise LiveChat. We need to let OneTrust tell our page that
        // the "Functional" cookie category has been enabled by the user, via the below code and run the callback.
        // The calback itself will enable livechat in react
        const liveChatScript = document.createElement('script');
        liveChatScript.type = 'text/plain';
        liveChatScript.setAttribute('class', 'optanon-category-C0003');
        liveChatScript.async = false;
        liveChatScript.innerHTML = "var enableLiveChat = function(){window.dispatchEvent(new CustomEvent('enableLiveChat'))};" +
          "OneTrust.InsertHtml('<div></div>', 'body', enableLiveChat, null, '3');";
        const head = document.querySelector('head');
        head.insertBefore(liveChatScript, head.firstChild);
      }
    }
  }

  /**
   * Logic to determine from our configs if OneTrust has been enabled for the current country
   * @returns {boolean}
   */
  function oneTrustConfigEnabled(){
    return config.getValue('app.tracking.cookies.onetrust.enabled') === '1' && config.getValue('app.tracking.cookies.onetrust.scriptid');
  }

  /**
   * Logic to set up GTM after OneTrust cookie. if OneTrust could be load successfully
   */
  // function setUpGTMAfterOneTrust (){
  //   // Maximum attemps and delay time for each attemp.
  //   const maxAttemps = 50, delay = 100;
  //   let count = 0;
  //   const checkingOneTrust = () => {
  //     if(count === maxAttemps) {
  //       console.warn(`Failed to load OneTrust after ${count} retries. Now set up GTM`);
  //       setUpGTM();
  //     }
  //     // Waiting for OneTrust to load...
  //     if (typeof window.OneTrust === 'undefined') {
  //       console.log(`Waiting ${delay} for OneTrust script to load...`);
  //       count++;
  //       // Wait before trying again
  //       setTimeout(checkingOneTrust, delay);
  //     } else {
  //       console.log('OneTrust loaded successfully. Now set up GTM');
  //       setUpGTM();
  //     }
  //   }
  //   // Execute
  //   checkingOneTrust();
  // }

  /**
   * Install the Google Tag Manager (GTM)
   *
   * If we have OneTrust Cookie Management enabled, we will delay the gtm.js script from init'ing until OneTrust
   * fires up and enables it.
   * Note: We are setting gtm.js to belong to the OneTrust "Strictly Necessary" category, which will always be on, but
   * this way it still won't enable the below GTM code until it's processed the enclosing <script type=text/plain> tag
   * and converted it to text/javascript
   */
  function setUpGTM(){
    let gtmExtraParams = '';
    let gtmContainer = "GTM-T4M6JQ";

    const gtmScript = document.createElement('script');
    gtmScript.async = true;

    if (oneTrustConfigEnabled()) {
      gtmScript.type = 'text/plain';
      gtmScript.setAttribute('class', 'optanon-category-C0001');
    } else {
      gtmScript.type = 'text/javascript';
    }

    // UAT/dev should have extra debugging/previewing params appended
    if (getEnvironment() !== "prod") {
      gtmExtraParams = "+'&gtm_auth=eZPOPF6nHyHIltTgoy974w&gtm_preview=env-31&gtm_cookies_win=x'";
    }

    gtmScript.innerHTML = "window.dataLayer = window.dataLayer || [];\n" +
      "        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
      "            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
      "          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
      "          'https://www.googletagmanager.com/gtm.js?id='+i+dl"+gtmExtraParams+";f.parentNode.insertBefore(j,f);\n" +
      "        })(window,document,'script','dataLayer','"+gtmContainer+"');";

    const head = document.querySelector('head');

    if (oneTrustConfigEnabled()) {
      // OneTrust Enabled, insert GTM *after* the main OneTrust installation
      const otStub = document.querySelector('#oneTrustInitStub');
      head.insertBefore(gtmScript, otStub.nextSibling);

    } else {
      // No OneTrust, insert GTM as high as possible
      head.insertBefore(gtmScript, head.firstChild);
    }

    //set up GTM for youtube iframe
    const ytIframeScript = document.createElement('script');
    ytIframeScript.async = true;
    ytIframeScript.type = 'text/javascript';
    ytIframeScript.src = 'https://www.youtube.com/iframe_api';
    head.insertBefore(ytIframeScript, head.firstChild);
  }
  /**
   *
   * @returns {boolean} Return true if OneTrust enabled and integration code was included
   */
  function setUpOnetrustCookie(){
    if(oneTrustConfigEnabled()){
      /**
       * OneTrust expects the following JS to be rendered at the top of the <head> section.
       * We add the two script tags one at a time below
       *
       *  <!-- OneTrust Cookies Consent Notice start for movember.com -->
          <script src="https://cdn-au.onetrust.com/scripttemplates/otSDKStub.js" data-document-language="true" type="text/javascript" charset="UTF-8" data-domain-script="ea5960ce-518b-4f0c-be23-59cff9958b91" ></script>
          <script type="text/javascript">
            function OptanonWrapper() { }
          </script>
          <!-- OneTrust Cookies Consent Notice end for movember.com -->
       *
       *
       * @type {HTMLScriptElement}
       */

      // The OptanonWrapper bit
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = false;
      s.innerHTML = "function OptanonWrapper() { }";
      let head = document.querySelector('head');
      head.insertBefore(s, head.firstChild);
      // Now the main OneTrust JS include
      const otScript = document.createElement('script');
      otScript.setAttribute('id', 'oneTrustInitStub');
      otScript.setAttribute('type', 'text/javascript');
      otScript.setAttribute('charset', 'UTF-8');
      otScript.setAttribute('data-document-language', 'true');
      otScript.setAttribute('src', 'https://cdn-au.onetrust.com/scripttemplates/otSDKStub.js');
      otScript.setAttribute('data-domain-script', `${config.getValue('app.tracking.cookies.onetrust.scriptid')}${config.getValue('app.tracking.cookies.onetrust.testing') === '1' ? '-test' : ''}`);
      head = document.querySelector('head');
      head.insertBefore(otScript, head.firstChild);

      return true;
    }
    return false;
  }


  function fetchToConfigJson() {
    let hostname = window.location.hostname,
    {country, language} = getUrlLocale();
    let url = getConfigUrl(country);
    let headers = new Headers();
    // Add no-cache to headers to make sure it will get new changes in config.json
    headers.append('pragma', 'no-cache');
    headers.append('cache-control', 'no-cache');

    // Need to add extra headers for pre-renderer to work
    // Check to see if we are on a cloudfront URL and add 
    // extra headers

    if (hostname.includes("cloudfront.net") || hostname.includes("localhost")) {
        headers.append('x-mov-locale', country);
        headers.append('accept', 'application/json');
        headers.append('content-type', 'application/json');
    }

    fetch(url, { headers: headers })
      .then((res) => {
        return res.json();
      })
      .then(
        (result) => {
          let configuration = new MoConfiguration();
          let configs = {...configuration.config, ...result};
          // set current URL language to config
          configs.language = language;
          configs.country = country; 
          setConfig(new MoConfiguration(configs));
        },
        (error) => {
          console.log("Request error " + error);
          //if(getEnvironment() === 'local'){
            let configuration = new MoConfiguration();
            let configs = {...configuration.config, ...defaultConfig};
            configs.language = language;
            configs.country = country;          
            setConfig(new MoConfiguration(configs));
          //}
        }
      );
  }

    const fetchToCmsJson = async () => {
      const { country, language } = getUrlLocale();
      // We don't currently support de.movember.com/de/ -> this would return an error from fetch request
      const isDefault = locales[country]?.language.filter(locale => locale.code === language)?.[0]?.default;
      let url = getAssetUrl(
        "cms",
        country,
        isDefault ? "" : language
        );

        let headers = new Headers();
        // Add no-cache to headers to make sure it will get new changes in sitemap.json
        headers.append('pragma', 'no-cache');
        headers.append('cache-control', 'no-cache');

        // Need to add extra headers for pre-renderer to work
        // Check to see if we are on a cloudfront URL and add 
        // extra headers
        const hostname = window.location.hostname;
        if (hostname.includes("cloudfront.net") || hostname.includes("localhost")) {
            headers.append('x-mov-locale', country);
            headers.append('accept', 'application/json');
            headers.append('content-type', 'application/json');
        }

        try {
          const res = await fetch(url, { "headers" : headers })
          const contentType = res.headers.get("content-type");
          let sitemapJson = null;
          if(contentType && contentType.indexOf("application/json") !== -1) {
            sitemapJson = await res.json()
          } else {
            const newURL = getAssetUrl("cms", country);
            const res = await fetch(newURL, { "headers" : headers });
            sitemapJson = await res.json();
          }
          combineCms(sitemapJson)
        } catch (error) {
          console.log("Request error " + error);
        }
    }

  function combineCms(cmsJson) {
    if (cmsJson) {
      setCms(cmsJson);
      previewCms = cmsJson;
      // Flag JSON has loaded
      setHasJSON(true);
    }
  }

  function setAppLanguage() {
    const { country, language } = getUrlLocale();
    // Country code
    if ( country && country !== config.getCountry() ) {
      config.setCountry(country);
    } else {
        const { hostname } = window.location;
        if (typeof cloudFrontUrls[hostname] === 'undefined') {
            const locale = new URLSearchParams(window.location.search).get('locale');
            if (locale) {
              const [countryPre, languagePre] = locale.split("/");
              if (countryPre.length === 2) {
                config.setCountry(countryPre);
                if(languagePre && languagePre.length === 2) {
                  const supportedLanguages = locales?.[countryPre]?.language;
                  if (supportedLanguages) {
                    const languageMatch = supportedLanguages.find((lang) => lang.code === languagePre);
                    if (languageMatch) {
                      config.setLanguage(languagePre);
                    }
                  }
                }
              }
            }  
        } else {
          config.setCountry(cloudFrontUrls[hostname]);
        }
    }

    // Language code
    const countryLanguages = locales?.[country]?.language ? locales[country].language : [];
    if (language && language !== config.getLanguage()) {
      const languageMatch = countryLanguages.find((lang) => lang.code === language);
      if (languageMatch) {
        config.setLanguage(language);
      }
    }

    // TODO: in diffrent ticket to cover all T2 locales
    // Direct user browser language to current supported language of current locale
    // else if (countryLanguages.length > 1 && navigator?.language && !getCookie('language-bar-is-disabled')) {
    //   const supportedLanguage = navigator.language.substring(0, 2);
    //   const supportedLanguageMatch = countryLanguages.find((lang) => !lang.default && lang.code === supportedLanguage);
    //   if (supportedLanguageMatch) {
    //     config.setLanguage(supportedLanguage);
    //     window.location = `/${supportedLanguage}/`;
    //   }
    // }
    
    setConfig(config);
  }



  function renderPreview(json) {
    let newCms = previewCms;
    for (let id in json) {
      if ( newCms && newCms.components && typeof newCms.components[id] === "undefined") {
        newCms["pages"]["stories"]["components"].push({
          id: id,
          title: json[id]["title"],
          url: json[id]["url"],
          components: [id],
        });
      }
      newCms.components[id] = json[id];
      setCms(newCms);
      // Need to re-render current page to see new changes.
      // Can NOT reload the page as it would retrieve the cms again
      // Generate new random key in Article route for re-rendering article page in react-router
      setRandomKey(Math.random());
    }
  }

  return <React.Fragment>{renderRouter()}</React.Fragment>;

  function renderRouter(params) {
    let location = params && params.location ? params.location : "",
      RouterType = params && params.ssr ? StaticRouter : BrowserRouter;

    const returnPage = () => {
      return (
        <>
          {
          
          <ConfigContext.Provider value={config}>
          <CMSContext.Provider value={cms}>
            {/* Metadata */}
            {cms && renderMetaTags()}

            {optimizelyEnabled && <OptimizelyProvider
            optimizely={optimizely}
            // user={{id: '<Your_User_Id>'}}
            ></OptimizelyProvider>}
            <div id="clipboard-container"></div>
            {/* React Router */}
            <RouterType location={location}>
              <Header cms={cms} />
              <CmsPreview />
              <div id="content-container">
                <PreviousUrlProvider>
                  <Switch>{getRoutes()}</Switch>
                </PreviousUrlProvider>
              </div>
            </RouterType>
            {/* Live chat */}
            {liveChatEnabled && <LiveChatWidget
              license={config.getValue('app.tracking.livechat.code')}
              group={config.getValue('app.tracking.livechat.lc.group')}
              visibility="minimized"
            />}
            <Footer cms={cms} />
            <div id="json-loaded"></div>
          </CMSContext.Provider>
          </ConfigContext.Provider>
          
          }
        </>
      );
    };

    const returnPreRenderedHTML = () => {
      let innerHTML = document.getElementById("root").innerHTML;
      return (
        <div id="noJSON">
          {innerHTML > 0
            ? (React.dangerouslySetHTML =
                document.getElementById("root").innerHTML)
            : ""}
        </div>
      );
    };

    return (
      <React.Fragment>
        {!hasJSON ? returnPreRenderedHTML() : returnPage()}
        </React.Fragment>
    );
  }

  function renderMetaTags() {
    const { country, language } = getUrlLocale();
    const firstLanguage = locales?.[country]?.language[0];
    let languageCode = language || (firstLanguage?.default ? firstLanguage.code : config.getLanguage());
    let countryCode = country || config.getCountry();

    return (
      <React.Fragment>
         {/* React Helmet to set attributes for html tag and onetrust cookie script */}
         <Helmet
          htmlAttributes={{
            xmlns: "http://www.w3.org/1999/xhtml",
            "xmlns:fb": "http://www.facebook.com/2008/fbml",
            "xmlns:og": "http://ogp.me/ns#",
            "lang": `${languageCode}-${(countryCode === 'uk' ? 'gb' : countryCode).toUpperCase()}`,
            "xml:lang": `${languageCode}-${(countryCode === 'uk' ? 'gb' : countryCode).toUpperCase()}`,
          }}
        />
        {/* React Helmet to manage meta tags in document head */}
        { cms.siteSettings && cms.siteSettings.metadata && (
          <Helmet>
            <meta
              name="application-name"
              content={cms.siteSettings.metadata.title}
            />
            <meta
              name="description"
              content={cms.siteSettings.metadata.description}
            />
            <link
              rel="icon"
              href={getImage(
                cms.siteSettings.metadata["fav-icon"],
                "small"
              )}
            />
            <link
              rel="apple-touch-icon"
              href={getImage(
                cms.siteSettings.metadata["fav-icon"],
                "small"
              )}
            />
            {/* Open Graph meta tags */}
            <meta property="og:url" content={window.location.href} />
            <meta
              property="og:locale"
              content={`${config.getLanguage()}_${config.getCountry() === 'uk' ? 'gb' : config.getCountry()}`}
            />
            <meta
              property="og:title"
              content={cms.siteSettings.metadata.title}
            />
            <meta property="og:type" content="website" />
            <meta
              property="og:image"
              content="https://cdn.movember.com/uploads/images/Logos/Movember_Iconic%20Mo_Black.jpg"
            />
            <meta
              property="og:description"
              content={cms.siteSettings.metadata.description}
            />
          </Helmet>
        )}
      </React.Fragment>
    );
  }

  function getRoutes() {
    // Render Routes from static pages and dynamic pages from cms context
    // Static pages
    let staticPages = {   
      impact: { url: '/impact' }, 
      partners: { url: '/friends'},
      dollarMatching: { url: '/friends/dollar-matching'},
      'cms-login': { url: '/cms-login'}
    };

    // Small fix for allowing different routes for static pages
    // If the language is part of the path we need to 
    // update the static route to reflect this.
    // TODO soon we will move static pages to pages
    const { pathname } = window.location;
    if (pathname.includes(`/${config.getLanguage()}/`)) {
      Object.keys(staticPages).forEach((staticPage) => {
        staticPages[staticPage] = {"url": `/${config.getLanguage()}${staticPages[staticPage]["url"]}`};
      });
    }

    cms.pages = {...cms.pages, ...staticPages};

    for (const pageName in cms.pages) {
      let route = cms.pages[pageName];
      route.components && route.components.length > 0
        ? addDynamicPages(route, pageName)
        : addStaticPages(route, pageName);
    }
    // Add No-Match route (404)
    routes.push(<Route path="*" component={Common.NoRoute} key="no-route" />);
    return routes;
  }

    function addStaticPages(route, page) {
        let pageComponent = Home;
        let pageChild = null
        switch (page) {
            default:
            case "home":
                pageComponent = Home;
                break;
            case "cms-login":
                pageComponent = CmsLogin;
                break;
            case "cms-logout":
                pageComponent = CmsLogout;
                break;
            case "impact":
                pageComponent = ImpactHub;
                break;
            case "partners":
                pageComponent = PartnersPage;
                pageChild = <PartnersPage cms={cms}/>
                break;
            case "dollarMatching":
                pageComponent = DollarMatching;
                pageChild = <DollarMatching cms={cms}/>
                break;
              
        }
        routes.push(
            <Route
                exact
                path={route.url}
                component={pageComponent}
                key={page}
            >
             {pageChild !== null ? pageChild : null} 
            </Route>
        );
    }

    function getIndexRoutePath(url) {
        const currentLanguage = config.getLanguage();
        let indexRoutePath = "/";
        // Need to check if the page is a language page
        if (url.indexOf(`/${currentLanguage}/`) === 0 ) {
            indexRoutePath = `/${currentLanguage}/`;
        }
        return indexRoutePath;
    }

    function addDynamicPages(route, page) {
        const indexRoutePath = getIndexRoutePath(route.url);
        
        if (route.url && route.url !== indexRoutePath && route.components && route.url ) {
         
          routes.push(
                <Route
                exact
                path={route.url}
                key={page}
                >
                <DynamicPage cms={cms} page={page} componentList={route.components} />
                </Route>
            );
        }
        switch (page) {
            default:
            case "stories":
            case "content":
                addRoutes(route.components, page === "stories" ? "story" : page);
                break;
        }
    }

    function addRoutes(pages, pageName) {
      const currentLanguage = config.getLanguage();
      // The ^ and $ characters at the beginning and end of the regular expression pattern
      // respectively ensure that the entire page.url must match the pattern exactly
      const regex = new RegExp(`^(/${currentLanguage}/index|/index)$`, "g");
      pages.map((page) => {
        if(page && page.url){
          const pageHomeFound = regex.test(page.url);
          // Temporary homepage
          if (pageHomeFound) {
            const indexRoutePath = getIndexRoutePath(page.url);
            routes.push(
              // For Story, set randomKey to re-render this component to see changes on Preview
              <Route exact path={indexRoutePath} key={"homepage"}>
                <DynamicPage
                  cms={cms}
                  content={page}
                  page={pageName}
                  componentList={page.components}
                />
              </Route>
            );
          }
          routes.push(
            <Route
              exact
              path={page.url}
              key={pageName === "story" ? randomKey : page.id}
            >
              <DynamicPage
                cms={cms}
                page={pageName}
                content={page}
                componentList={page.components}
              />
            </Route>
          );
          return true;
        }
        return false;
      });
    }
};

export default MoRouter;
