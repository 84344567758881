import styled from 'styled-components';

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 32px;
    padding-top: 78px;
    color: #111111;
    background-image: url('/resources/images/content/tcam/background-mobile.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        align-items: center;
        background-image: url('/resources/images/content/tcam/background.png');
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
        padding: 48px;
        padding-top: 78px;
    }
`;

const Pear = styled.div`
    position: absolute;
    top: 0;
    width: 90px;
    height: 100px;
    left: 12%;
    background-image: url('/resources/images/content/tcam/pear.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        height: 125px;
        width: 105px;
        left: 7%;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
        height: 140px;
        width: 120px;
        left: 11%;
    }
`;

const Heading = styled.div`
    resize: both;
    height: auto;
    width: 100%;
    font-family: Anton
    text-transform: uppercase
    font-size: 166px

    svg {
        overflow: visible;
        display: block;
        margin-bottom: 32px;
    }
`;

const Text = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
`;

export { Container, Pear, Heading, Text };