import { Container, Wrapper, ContentContainer, HeadingContainer, BodyContainer,Heading, Body, ButtonContainer, PearContainer } from './TcamStatic.style';
import { renderParagraph } from 'components/utils/render';
import { Button } from 'components/common';
import TcamPear from './TcamPear';
interface Props {
    heading: string;
    text: any;
    btnText?: string;
    btnLink?: string;
}

const TcamStatic = ({heading, text, btnText, btnLink}: Props) => {

    const open3DModelEventTrigger = (e: React.MouseEvent) => {
        // If TCAM Wiggle with 3D model iframe available, then stop going to btnLink and open 3D model
        let tcamWiggleModule = document.getElementById('tcamWiggleModule');
        if(tcamWiggleModule) {
            e.preventDefault();
            window.dispatchEvent(new Event('open3DModel'));
        }
    }

  return (
    <Container>
        <Wrapper>
            <ContentContainer>
                <HeadingContainer>
                    <Heading>{heading}</Heading>
                </HeadingContainer>
                <BodyContainer>
                    <Body>{renderParagraph(text)}</Body>
                    {btnText && <ButtonContainer><Button btnType='tcamUp' btnText={btnText} btnLink={btnLink} onClick={open3DModelEventTrigger}/></ButtonContainer>}
                </BodyContainer>
            </ContentContainer>
            <PearContainer>
                <TcamPear hasAnimation={false} link={btnText && btnLink && btnLink !== ''? btnLink : undefined} onClick={open3DModelEventTrigger}/>
            </PearContainer>
        </Wrapper>
    </Container>
  )
};

export default TcamStatic;

