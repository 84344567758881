import React from 'react';
import Heading from '../../common/Heading/Heading';
import styled from 'styled-components';

const QuoteContent = styled.div`
    color: #7a99ac;
    font-size: 30px;
    margin: 25px 0;
    padding-left: 20px;
    border-left: solid 2px #7a99ac;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
        padding-left: 40px;
        margin: 40px 0;
    }
`;


const Quote = (props) => {
    const {content} = props;
    return ( 
        <div>
            <QuoteContent><Heading level="h6">{`" ${content} "`}</Heading></QuoteContent>
        </div>
     );
}
 
export default Quote;