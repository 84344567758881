import React from 'react';
import Heading from "components/common/Heading/Heading";
import Text from 'components/common/Text/Text';
import Quote from "components/stories/Body/Quote";
import { breakpoints } from "themes/breakpoints.theme";
import typography from "themes/typography.theme";
import { getImage, scrollWithOffset, getUrlLocale, IsOnMobile } from './helper';
import { pushAnyClickEvent } from '../tracking/events';

const renderParagraph = (content, quote, quotePosition, page) => {

  const createText = (data) => {
    let hasFontStyle = false;
    let dominatedFontStyle = null;
    let elements = [];

    for (let i = 0; i < data.length; i++) {
      const { text, id, anchorLink, href, nofollow, marks, fontStyle } = data[i];

      let element = text;

      if (id) {
        element = <span key={i} id={id}>{element}</span>;
      } else if (anchorLink) {
        const onAnchorLinkClick = (e, anchorId) => {
          e.preventDefault();
          const anchorElement = document.getElementById(anchorId);
          if(anchorElement) scrollWithOffset(anchorElement);
        }
        
        element = <a key={i} href={anchorLink} onClick={(e) => onAnchorLinkClick(e, anchorLink.replace('#', ''))}>{element}</a>;
      }

      if (href) {
        element = <a key={i} 
        href={href} 
        target="_blank" 
        rel={nofollow ? "nofollow" : undefined} 
        onClickCapture={() => pushAnyClickEvent({ linkUrl: href, linkText: element })}>
          {element}
        </a>;
      }

      if (marks && marks.length > 0 && !fontStyle) {
        for (const mark of marks) {
          let styleTag = 'span';
          switch (mark) {
            case 'strong':
            case 'em':
              styleTag = mark;
              break;
            case 'underline':
              styleTag = 'u';
              break;
            case 'strike-through':
              styleTag = 's';
              break;
            default:
              break;
          }
          element = React.createElement(styleTag, { key: i }, element);
        }
      }

      if (fontStyle && !dominatedFontStyle) {
        hasFontStyle = true;
        dominatedFontStyle = typography?.fontStyles?.[fontStyle];
        // Change font style for mobile
        if (IsOnMobile()) {
          // Anton large -> Anton medium
          dominatedFontStyle = fontStyle === 'anton-large' ? typography?.fontStyles?.['anton-medium'] : dominatedFontStyle;
        }
      }

      elements = [...elements, element];
    }

    return {
      elements,
      hasFontStyle,
      dominatedFontStyle,
    };
  };

  let result = [];
  /* This contentCount is needed to show quote in the right position
   ** ol/ul items are combined in a paragrahp sometimes. */
  let contentCount = 0;
  // eslint-disable-next-line
  content.map((paragraph, i) => {
    // Content
    let tag;
    switch (paragraph.style) {
      case "h1":
        tag = (page === 'story') ? { name: 'Heading', level: 'h4' } : { name: "Heading", level: paragraph.style };
        break;
      case "h2":
      case "h3":
      case "h4":
      case "h5":
      case "h6":
        tag = { name: "Heading", level: paragraph.style };
        break;
      case "ul":
      case "ol":
      case "blockquote":
        tag = { name: paragraph.style };
        break;
      default:
        tag = { name: "p" };
        break;
    }

    // If the data element isn't an array, make it one
    if (!Array.isArray(paragraph.data)) {
      paragraph.data = [paragraph.data];
    }

    // In case of rendering ordered list (ol) or unordered list (ul)
    // Have to create ol/ul element with li childs so it could display correct order numbers
    // Have to check next comming elements are still in ol/ul tag
    if (tag.name === "ol" || tag.name === "ul") {
      const currentListType = tag.name;
      // Do not render if this list item is already rendered in previous element.. i.e..previous element is also list item
      if (content[i - 1] && content[i - 1].style === currentListType) {
        // eslint-disable-next-line
        return;
      }
      // Check next element
      let li = [React.createElement("li", null, createText(content[i].data)?.elements)];
      let index = i + 1;
      while (content[index] && content[index].style === currentListType) {
        li.push(
          React.createElement("li", null, createText(content[index].data).elements)
        );
        index++;
        contentCount++;
      }
      result.push(React.createElement(currentListType, { key: i }, li));
    } else {
      let elementType = 'div',
        elementProps = { key: i };
      switch (tag.name) {
        case 'Heading':
          elementType = Heading;
          elementProps.level = tag.level;
          break;
        case 'p':
          elementType = Text;
          elementProps.tag = 'p';
          break;
        case 'blockquote':
          elementType = 'div';
          elementProps['data-attribute'] = 'quote';
          break;
        default:
          elementType = 'div';
      }
      const textData = createText(paragraph.data);
      elementProps.style = textData?.dominatedFontStyle;
      result.push(React.createElement(elementType, elementProps, textData?.elements));
    }

    // Quote
    if (quote) {
      if (quotePosition && quotePosition === contentCount + 1) {
        result.push(<Quote content={quote} key={i} />);
      }
      if (!quotePosition && contentCount === content.length - 1) {
        result.push(<Quote content={quote} key={i} />);
      }
    }

    contentCount++;
  });

  return result;
}

const renderPictureTag = (image, size) =>
  <picture>
    <source
      media={`(min-width:${breakpoints.md})`}
      srcSet={getImage(image, size === "small" ? "medium" : "large")}
      type="image/webp"
    />
    <source
      media={`(min-width:${breakpoints.md})`}
      srcSet={getImage(image, size === "small" ? "medium" : "large", true)}
      type={getImageFormat(image)}
    />
    <source
      media={`(min-width:${breakpoints.sm})`}
      srcSet={getImage(image, "medium")}
      type="image/webp"
    />
    <source
      media={`(min-width:${breakpoints.sm})`}
      srcSet={getImage(image, "medium", true)}
      type={getImageFormat(image)}
    />
    <source
      media={`(min-width:${breakpoints.xs})`}
      srcSet={getImage(image, "small")}
      type="image/webp"
    />
    <source
      media={`(min-width:${breakpoints.xs})`}
      srcSet={getImage(image, "small", true)}
      type={getImageFormat(image)}
    />
    <img
      src={getImage(image, "org", true)}
      alt={image && image.alt ? image.alt : "no-alt"}
    />
  </picture>;

function renderImage(image, size) {
  return image ? image.link ? <a href={image.link} target="_blank" rel="noreferrer" onClickCapture={() => pushAnyClickEvent({ linkUrl: image.link, linkText: `image/${image.alt}` })}>{renderPictureTag(image, size)}</a>
  :renderPictureTag(image, size)
  : null;
}

function getImageFormat(item) {
  let value = "";
  if (item && item.urls && item.urls.original && item.urls.original.original) {
    let dotIndex = item.urls.original.original.lastIndexOf(".");
    value = "image/" + item.urls.original.original.substring(dotIndex + 1);
  }
  return value;
}

function renderCurrency(options, locale) {
  let thisLocale = (locale && typeof locale === 'string') ? locale : `${getUrlLocale().language}-${getUrlLocale().country}`;
  let config = {
    value: options.amount ? options.amount : 0,
    currency: options.currency ? options.currency : 'AUD',
    symbol: options.symbol ? options.symbol : '$',
    format: locale,
    precision: 0,
    position: true, // Symbol position: true - left, false - right
    space: false // Space between symbol and amount
  };

  let getFormat = (value) => {
    const formatter = new Intl.NumberFormat();
    if (typeof value === "number") {
      return formatter.format(value);
    } else if (typeof value === "string") {
      const targetValue = parseInt(value);
      if (!Number.isNaN(targetValue)) {
        return formatter.format(targetValue);
      }
    }
    return value;
  };

  // Format currency string based on locale
  switch(thisLocale.toLowerCase()){
    case 'fr-ca':
      config.position = false;
      config.space = true;
      break;
    default:
      break;
  }

 return `${config.position ? config.symbol : ''}${config.space ? ' ' : ''}${getFormat(config.value)}${config.space ? ' ' : ''}${!config.position ? config.symbol : ''}`;
}
 
export { renderParagraph, renderImage, renderCurrency };
