import { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { renderImage } from 'components/utils';
import { breakpointsInt as breakpoints} from 'themes/breakpoints.theme';
import { Container, Header, Body, Heading, Navigator, SeeMore, StepIndicator, ArrowsContainer, Arrow,
    Slide, Image, CTA, Title, Description } from './ProgramCarousel.style';
import { pushAnyClickEvent } from '../../../../tracking/events';

/**
 * 
 * @param slides : array of slides
 * @param heading : Heading text of carousel
 * @param ctaText : CTA button text
 * @param ctaLink : CTA button link
 * @param containerWidth : width of carousel container
 * @param location : location of carousel, for google analytic params
 * 
 */
const ProgramCarousel = ({slides, heading, ctaText, ctaLink, containerWidth, location}) => {
    const [thisSlider,  setThisSlider] = useState();
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isNavigating, setIsNavigating] = useState(false);
    const [showArrows, setShowArrows] = useState(true);

    const settings = {
        infinite: false,
        variableWidth: true,
        arrows: false,
        speed: 200,
        afterChange: state => {
            setCurrentSlide(state);
        }
    };
    

    useEffect(() => {
        const onChangeWindowWidth = () => {
            let isShowArrows = true,
            conWidth = (containerWidth && !isNaN(containerWidth)) ? containerWidth : window.innerWidth,
            slideWidth = 400,
            padding = 60;
            isShowArrows = conWidth < ((slideWidth * slides.length) + padding);
            if(conWidth <= breakpoints.md && conWidth > breakpoints.sm){
                slideWidth = 310;
                isShowArrows = conWidth < ((slideWidth * slides.length) + padding);
            } else if (conWidth <= breakpoints.sm) {
                isShowArrows = true
            }
            setShowArrows(isShowArrows);
        };
        onChangeWindowWidth();
        window.addEventListener('resize', onChangeWindowWidth);
        return () => {
            window.removeEventListener('resize', onChangeWindowWidth);
        };
    });

    function handleArrowClick(isPrev){
        if(thisSlider && !isNavigating){
            setIsNavigating(true);
            if(isPrev){
                (currentSlide > 0) ? thisSlider.slickPrev() : thisSlider.slickGoTo(slides.length - 1);
            } else {
                (currentSlide < slides.length - 1) ?  thisSlider.slickNext() :  thisSlider.slickGoTo(0);
            }
            setTimeout(() => setIsNavigating(false), 300);
        }
    }

    return ( 
        <Container>
            <Header>
                <Heading>{heading}</Heading>
                <Navigator>
                    {(ctaText && ctaText.trim()) &&
                        <SeeMore href={ctaLink ? ctaLink : null} onClickCapture={()=> pushAnyClickEvent({linkUrl: ctaLink, linkText: ctaText})}>
                            {ctaText} <span/>
                        </SeeMore>
                    }
                     <ArrowsContainer style={{display: showArrows ? '' : 'none'}}>
                        <Arrow  direction='left' onClick={() => handleArrowClick(true)}/>
                        <StepIndicator>{`${(currentSlide + 1)} OF ${slides.length}`}</StepIndicator>
                        <Arrow onClick={() => handleArrowClick()}/>
                    </ArrowsContainer>
                </Navigator>
            </Header>
            <Body>
                <Slider ref={slider => (setThisSlider(slider))} {...settings}>
                    {slides.map((slide, index) =>
                        <div key={index}>
                             <Slide onClick={() => (slide.link) ? window.location = slide.link : null}
                             {...location ? {'data-dl-category': 'content', 'data-dl-location': location, 'data-dl-label': slide.title} : ''}>
                                <Image>
                                    {renderImage(slide.image)}
                                    {(slide.cta && slide.cta.trim()) && 
                                        <CTA href={slide.link ? slide.link : null} onClickCapture={()=> pushAnyClickEvent({linkUrl: slide.link, linkText: slide.cta})}>{slide.cta} <span/></CTA>
                                    }
                                </Image>
                                <Title>{slide.title}</Title>
                                <Description>{slide.description}</Description>
                            </Slide> 
                        </div>
                   )}
                </Slider>
            </Body>
        </Container>
     );
}
 
export default ProgramCarousel;