import React from 'react';
import { renderParagraph } from 'components/utils';
import { Container } from 'themes/MoGrid';
import { Wrapper } from './RichTextBlock.style';

const RichTextBlock = ({richTextBlock}) => {
    return (
        <Container>
            <Wrapper>{richTextBlock && renderParagraph(richTextBlock)}</Wrapper>
        </Container>
    );
}
 
export default RichTextBlock;