import { breakpoints, breakpointsInt } from './breakpoints.theme';
import typography from './typography.theme';
import palette from './palette.theme';


const MoTheme = {
    name: "MoTheme",
    breakpoints: {
        ...breakpoints
    },
    breakpointsInt: {
        ...breakpointsInt
    },
    typography: {
        ...typography
    },
    palette: {
        ...palette
    }
};


export default MoTheme;