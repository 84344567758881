import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    padding: 48px 32px;
    background-image: url('/resources/images/content/tcam/background-mobile.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        background-image: url('/resources/images/content/tcam/background.png');
        align-items: center;
        justify-content: center;
    }
`;

const Wrapper = styled.div`
    display: flex;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        width: 600px;
        padding: 0px 48px;
    }
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    gap: 24px;
    min-width: 256px;
    flex: 1;
    z-index: 1;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        min-width: 379px;
    }    
`;

const HeadingContainer = styled.div`
    align-self: stretch;
`;

const BodyContainer = styled.div`
    padding-bottom: 45px;
    align-self: stretch;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        height: auto;
        padding-bottom: 28px;
    }
`;

const Heading = styled.h2`
    color: ${props => props.theme.palette.mo.greyTone};
    font-family: Anton;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    text-transform: uppercase;
    margin: 0;
`;

const Body = styled.div`
    p {
        color: ${props => props.theme.palette.mo.greyTone};
        font-family: Overpass;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 28px;
        margin-bottom: 26px;
    }
`;

const ButtonContainer = styled.div`
    position: relative;
`;

const PearContainer = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    justify-content: center;
    transform: rotate(6.215deg);
    top: 50px;
    left: 15px;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        top: auto;
        left: 30px;
        transform: rotate(6.215deg) scale(1.107);
    }
`;

export { Container, Wrapper, ContentContainer, HeadingContainer, BodyContainer, Heading, Body, ButtonContainer, PearContainer };