const MagnifyingGlass = ({ color = "black", ...style}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
      {...style}
    >
      <g>
        <path
          fill={color}
          fillRule="evenodd"
          d="M11.883 2.373c4.981 0 9.024 4.037 9.024 9.011 0 4.974-4.043 9.011-9.024 9.011-4.981 0-9.024-4.037-9.024-9.01 0-4.975 4.043-9.012 9.024-9.012zm0 16.02c3.877 0 7.019-3.137 7.019-7.009 0-3.873-3.142-7.008-7.02-7.008a7.011 7.011 0 00-7.018 7.008 7.012 7.012 0 007.019 7.009zm11.344 2.902l-2.837-2.831-1.417 1.416 2.835 2.832 1.42-1.417z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export default MagnifyingGlass;
