import styled, {css} from "styled-components";

export const ImageContainer = styled.div<{hasHeading:boolean}>`
    position: relative;
    width: 100%;
    padding-bottom: 60%

    ${props => props.hasHeading && css`
        padding-bottom: 0 !important;
    `}
`;

export const StandAloneImageContainer = styled.div`
    img {
        width: 100%;
    }
`;

export const Picture = styled.div<{hasHeading:boolean}>`
    picture {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    
        source, img {
            width: 100%;
            height: 100%;
            max-height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        ${props => props.hasHeading && css`
            position: initial;
            source, img {
                position: initial;
            }
        `}
    }
`;

export const ImageCaption = styled.figcaption`
    font-family: Overpass;
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    color: #5D5E64;
    margin-top: -8px;
    display: none;
`;

export const Video = styled.div`
    position: relative;
    padding-bottom: 56%;
    height: 100%;
    img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
`;

export const Play = styled.a`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    svg {
        font-size: 19px;
        color: ${props => props.theme.palette.primary.normal};
    }
`;

export const VideoContainer = styled.iframe`
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export const ParagraphContainer = styled.div`

    div[data-attribute='quote'] {
        font-family: Lora;
        font-size: 20px;
        line-height: 28px;
        margin: 24px 0;
        padding-left: 24px;
        border-left: 2px solid #B9BAC5;
    }
`;

export const ButtonContainer = styled.div<{isIconButton:boolean}>`
    ${props => props.isIconButton && css`
        margin-bottom: 16px !important;
    `}
`;

export const TallyContainer = styled.div`
`;