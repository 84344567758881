import styled from 'styled-components';

const Container = styled.div`
    position: relative;
    min-height: 560px;
    color:  ${props => props.theme.palette.primary.text};
    background-color: ${props => props.theme.palette.primary.normal};

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        min-height: 0;
    }
`;

const Image = styled.div`
    img {
        position: absolute;
        bottom: 0;
        right: 0;
        width: auto;
        height: 40%;
        max-height: 100%;

        @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
            max-width: 42%;
            height: auto;
        }
    }
`;

const Wrapper = styled.div`
    padding: 20px;
    max-width: 100%;
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        max-width: 60%;
    }
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
        padding: 56px 32px;
    }
`;

const Number = styled.div`
    display: inline-block;
    padding: 8px;
    margin-bottom: 24px;
    font-family: ${props => props.theme.typography.font.secondary};
    font-size: 60px;
    line-height: 0.9;
    color: #333333;
    background-color: #E6E6E6;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
        font-size: 86px;
    }
`;

const Heading = styled.div`
    margin-bottom: 24px;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 1;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
        font-size: 32px;
    }
`;

const Text = styled.div`
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 1.4;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
        font-size: 24px;
    }
`;

const ButtonsGroup = styled.div`
    display: flex;
`;

const SecondaryButton = styled.span`
    margin-right: 8px;
    a {
        color: #E6E6E6;
        background-color: ${props => props.theme.palette.primary.normal}
        border: solid 1px ${props => props.theme.palette.secondary.border}

        &:hover {
            color: #E6E6E6;
            background-color: ${props => props.theme.palette.primary.hover}
        }
    }
`;



export {Container, Wrapper, Number, Heading, Text, ButtonsGroup, SecondaryButton, Image};