import styled from 'styled-components';


const SearchInput = styled.input`
    display: inline-block;
    height: 30px;
    width: 360px;
    max-width: calc(100VW - 12px);
    padding: 0 38px 0 4px;
    margin: 0 6px;
    font-size: 15px;
    line-height: 30px;
    border-color: #FFF;
    border-radius: 3px;

`;

const SearchFormStyled = styled.form`
    display: block;
    position: relative;
    margin: auto;
    width: 380px;
    right: 0;
    @media screen and (min-width: ${props => props.theme.breakpoints.xs}) {
        margin-right: 0;
    }
    @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
        position: absolute;
        bottom: 5px;

    }
`;

const SearchIcon = styled.button`
    background: url(/resources/images/icons/search.svg) center no-repeat;
    position: absolute;
    border: none;
    overflow: visible;
    top: 0;
    width: 30px;
    height: 30px;
    right: 15px;
    color: transparent;
    cursor: pointer;
`


export {SearchInput, SearchFormStyled, SearchIcon}