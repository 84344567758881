import styled from 'styled-components';

const Container = styled.div`
    margin: 40px 0;
`;

const Date = styled.div`
    text-transform: uppercase;
    letter-spacing: 1px;
`;

const Title = styled.div`
    margin-top: 10px;

    & > h1 {
        font-size: 23px;
        line-height: 1.2;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        & > h1 {
           font-size: 37px;
        }
    }
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
        & > h1 {
           font-size: 49px;
        }
    }
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
        & > h1 {
           font-size: 65px;
        }
    }
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.xl}){
        & > h1 {
           font-size: 91px;
        }
    }
`;

const Author = styled.div`
    display: flex;
`;

const AuthorImgContainer = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

const AuthorImg = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 44px;
    height: 44px;
    border-radius: 50%;
    background-image: ${props => props.backgroundImage};
    background-position: center;
    background-size: cover;
    background-color: #000;
`;

const FirstLetter = styled.span`
    font-size: 28px;
    font-weight: bold;
    color: #fff;
`;

const AuthorInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-left: 1rem;
`;

const AuthorName = styled.div`
    display: inline;
    border-bottom: solid 2px #001eff;
`;

const AuthorTitle = styled.div`
    opacity: .7;
`;



export { Container, Date, Title, Author, AuthorImgContainer, AuthorImg, FirstLetter, AuthorInfo, AuthorName, AuthorTitle };