import React, { useState, useEffect, useContext } from 'react';
import { getApiUrl, getUrlLocale, renderCurrency } from 'components/utils';
import { Container, Amount, Raised, Target, ProgressBar, InnerBar } from './Tally.style';
import { CMSContext } from '../../../context/AppContext';

interface Props {
    entityType: string,
    id: string
}

const Tally: React.FC<Props> = ({entityType = 'member', id}) => {
    const [amount, setAmount] = useState<any>();
    const [target, setTarget] = useState<any>();
    const [currencySymbol, setCurrencySymbol] = useState();
    const [loading, setLoading] = useState(true);
    const cms = useContext<any>(CMSContext);

    useEffect(() => {
        let { country } = getUrlLocale() as {country: string};
        fetch(getApiUrl(`/${entityType}/${id}`, country))
            .then(res => {
                return res.json();
            })
            .then(result => {
                    if(result.AmountRaised){
                        setAmount(parseInt(result.AmountRaised.originalAmount));
                        setCurrencySymbol(result.AmountRaised.originalCurrencySymbol);
                    }
                    if(entityType === 'member'){
                        setTarget(result.targetAmount ? result.targetAmount : null);
                        setLoading(false);
                    } else if(entityType === 'team'){
                        fetch(getApiUrl(id, country, 'team-target'))
                        .then(res => {
                            return res.json();
                        }).then(result => {
                            setTarget(result.fundraising_target ? parseInt(result.fundraising_target) : null);
                            setLoading(false)
                        })
                    } else if(entityType === 'network'){
                        fetch(getApiUrl(id, country, 'network-target'))
                        .then(res => {
                            return res.json();
                        }).then(result => {
                            setTarget(result.fundraising && result.fundraising.value && result.fundraising.value !== '0' ? parseInt(result.fundraising.value) : null);
                            setLoading(false)
                        })
                    }
                },
                error => {
                    console.log("Request error " + error);
                }
            );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            {!loading &&
                <Container>
                    <Amount>
                        <Raised>{renderCurrency({symbol: currencySymbol, amount: amount})}</Raised>
                        {!!target &&
                            <Target>
                                {`${cms.snippets["donation-tally-target-text"]? cms.snippets["donation-tally-target-text"].text : ''} 
                                ${renderCurrency({symbol: currencySymbol, amount: target})}`}
                            </Target>
                        }
                    </Amount>
                    {!!target && 
                        <ProgressBar>
                            <InnerBar width={target ? (amount/target)*100 : 0}/>
                        </ProgressBar>
                    }
                </Container>
            }
        </React.Fragment>
       
    );
};

export default Tally;