import React from 'react';
import Heading from 'components/common/Heading/Heading';
import { renderImage } from 'components/utils';
import { Link } from 'react-router-dom';
import { Container, Header, Icon, ItemList, Item, ImageContainer, Picture, InfoContainer, Info, Date, Title, Author } from './RelatedStories.style';
import { pushAnyClickEvent } from '../../tracking/events';

const RelatedStories = ({cms, country, stories, currentTag}) => {
    
    const realStoriesLink = `/story${currentTag ? `?tag=${encodeURIComponent(currentTag.replace(' ', '-').toLowerCase())}`: ''}`;

    return ( 
        <Container>
            <Heading level="h6" fontWeight="inherit">
                <Header>
                    <Link to={realStoriesLink} onClickCapture={() => pushAnyClickEvent({linkUrl: realStoriesLink, linkText: cms.snippets['text-common-related-stories'].text})}>
                        {cms.snippets['text-common-related-stories'].text} <Icon className="fas fa-chevron-right"/>
                    </Link>
                </Header>
            </Heading>
            <ItemList>
                {stories.map((story, i) =>
                    <Item key={i}> 
                        <Link to={story.url} onClickCapture={() => pushAnyClickEvent({linkUrl: story.url, linkText: story.title})}>
                            <ImageContainer>
                                <Picture>{renderImage(story.gallery[0], 'small')}</Picture>
                            </ImageContainer>
                            <InfoContainer>
                                <Info>
                                    <Date>
                                        {story.publishedDate ? new window.Date(story.publishedDate).toLocaleDateString(['us', 'ca'].indexOf(country) >= 0 ? 'en-US' : 'en-AU', { year: 'numeric', month: 'long', day: 'numeric' }) : ''}
                                    </Date>
                                    <Title>{story.title}</Title>
                                    <Author>{story.author.name}</Author>
                                </Info>
                            </InfoContainer>
                        </Link>
                    </Item>
                )}
            </ItemList>
        </Container>
     );
}
 
export default RelatedStories;