import React, { useState, useEffect } from 'react';
import { Row, Col } from '../../../themes/MoGrid';
import Text from '../../common/Text/Text';
import { Hidden } from '@material-ui/core';
import { getUrlParam, renderImage, formatVideoUrl } from 'components/utils';
import { Container, Image, ImageContainer, VideoContainer, Picture, VideoIcon, ImageInfo } from './Gallery.style';

const Gallery = ({ cms, gallery }) => {
    const [selecting, setSelecting] = useState({type:'image', credit:'', caption:'', alt:''});
    const [isVideoPlaying, setIsVideoPlaying] = useState(getUrlParam('autoplay') === '1' ? true : false);
    
    useEffect(() => {
        if(gallery.length > 0){
            setSelecting(gallery[0]);
        }
    }, [gallery])

    return ( 
        <Container>
            <Row>
                <Col xs={12} sm={8}>
                    <Image>
                        <Hidden smUp>
                            <ImageInfo>
                                <Text level="small">{cms.snippets['text-common-image-by'].text} {selecting.credit}</Text>
                            </ImageInfo>
                        </Hidden>
                        <ImageContainer>
                            {isVideoPlaying ?
                                <VideoContainer src={formatVideoUrl(selecting.videoURL)} allow='autoplay'/>
                                :<React.Fragment>
                                    <Picture>{renderImage(selecting)}</Picture>
                                    { selecting.type === 'video' &&
                                        <VideoIcon className="fas fa-play" isOnSelecting={true} onClick={() => setIsVideoPlaying(true)}/>
                                    }
                                </React.Fragment>
                            }
                        </ImageContainer>
                      
                        <ImageInfo>
                            <Text level="small">{selecting.caption}</Text>
                            <Hidden xsDown>
                                <Text level="small">{cms.snippets['text-common-image-by'].text} {selecting.credit}</Text>
                            </Hidden>
                        </ImageInfo>
                    </Image>
                </Col>
               
                <Hidden xsDown>
                    <Col sm={4}>
                        <Row>
                            {gallery.map((item, i) => 
                                <Col sm={6} key={i}>
                                    <ImageContainer isSideGallery={true} isNotSelecting={item !== selecting} isVideo={item.type === 'video'} 
                                        onClick={() => {setIsVideoPlaying(false); setSelecting(item);}}>
                                        <Picture>{renderImage(item, 'small')} </Picture>
                                        { item.type === 'video' && 
                                            <VideoIcon className="fas fa-play" />
                                        }
                                    </ImageContainer>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Hidden>
            </Row>
        </Container>
     );
}
 
export default Gallery;