import {breakpointsInt as breakpoints}  from './breakpoints.theme';

const getSpacing = () => {
   if(window.innerWidth < breakpoints.sm){
       return 1;
   } else if (window.innerWidth < breakpoints.lg){
       return 2;
   } else {
       return 4;
   }
}
 
export default getSpacing;