import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { renderParagraph, IsOnMobile, getUrlLocale, getEnvironment } from 'components/utils';
import { Button } from 'components/common';
import TcamPear from './TcamPear';
import Tcam3DModel from './Tcam3DModel';
import { Wrapper, Container, Top, Bottom, Content, Heading, ButtonContainer, ButtonAddText, Paragraph, PearContainer, PearMobile, FloatingDiv, FloatingContainer } from './TcamWiggle.style';

interface Props {
    heading: string;
    paragraph: any;
    btnText: string;
    btnAddText: string;
    btnLink: string;
}

const TcamWiggle: React.FC<Props> = ({ heading, paragraph, btnText, btnAddText, btnLink }) => {
    const divRef = useRef(null);
    const [isElementVisible, setIsElementVisible] = useState(false); // Is the TCAM wiggle element visible on current view screen
    const [delayFloatingHidden, setDelayFloatingHidden] = useState(false); // Delay the floating div hidden for the animation
    const isOnMobile = IsOnMobile();
    const [open3DModel, setOpen3DModel] = useState(false);
    const [iframeUrl, setIframeUrl] = useState('https://knowthynuts.uat.movember.com/');
    let browserLocation = useLocation();

    // Set 3D Model Iframe URL with language and UTM params
    useEffect(() => {
      const { language } = getUrlLocale() as { language: string };
      const currentEnv = getEnvironment();
      const languagePrefix = language === 'fr' ? '/fr' : '';
      const envPrefix = currentEnv === 'prod' ? '' : 'uat.';
      const utmParams = browserLocation.search;
      setIframeUrl(`https://knowthynuts.${envPrefix}movember.com${languagePrefix}${utmParams}`);

      const handleOpen3DFromOthers = () => {setOpen3DModel(true)};

      // Set event listener to open 3D Model Iframe
      window.addEventListener('open3DModel', handleOpen3DFromOthers);
      return () => {
        window.removeEventListener('open3DModel', handleOpen3DFromOthers);
      };
    }, []);

    // Delay the floating div hidden for the animation
    useEffect(() => {
      if (!isElementVisible) {
        const floatingContainer = document.querySelector('.floating-container') as HTMLElement;
        if (floatingContainer) {
          floatingContainer.classList.add('slide-up');
          setTimeout(() => {
            setDelayFloatingHidden(false);
          }, 500); // Adjust the duration as needed
        }
      }
    }, [isElementVisible]);
  
    // Set state value if the TCAM wiggle element is visible
    useEffect(() => {
        const options = {
          root: null,
          rootMargin: '0px',
          threshold: 0.3, // percentage of the element must be visible
        };
    
        const observer = new IntersectionObserver(([entry]) => {
          setIsElementVisible(!entry.isIntersecting);
          if(!entry.isIntersecting){
            setDelayFloatingHidden(true);
          }
        }, options);
    
        if (divRef.current) {
          observer.observe(divRef.current);
        }
    
        return () => {
          if (divRef.current) {
            observer.unobserve(divRef.current);
          }
        };
      }, [divRef]);

    const open3DModelHandler = (e: React.MouseEvent) => {
      e.preventDefault();
      setOpen3DModel(true);
    }
    
    return (
      <Wrapper id='tcamWiggleModule'>
        <Container ref={divRef}>
            <Top>
                <Content>
                    <Heading>{heading}</Heading>
                    {!isOnMobile &&
                      <ButtonContainer>
                        <ButtonAddText>{btnAddText}</ButtonAddText>
                        { btnText?.trim() && <Button onClick={open3DModelHandler} {...{ btnType: 'tcamUp', btnText, ...(btnLink.trim() && {btnLink}) }} />}
                      </ButtonContainer>
                    }
                </Content>
                {(!isOnMobile) && 
                  <PearContainer>
                      <TcamPear onClick={open3DModelHandler} hasAnimation={true} {...btnLink.trim() ? {link: btnLink} : {}}/>
                  </PearContainer>
                }
            </Top>
            <Bottom>
                <Paragraph>{renderParagraph(paragraph)}</Paragraph>
                {isOnMobile &&
                  <PearMobile>
                    <ButtonContainer>
                      <ButtonAddText>{btnAddText}</ButtonAddText>
                      { btnText?.trim() && <Button onClick={open3DModelHandler} {...{ btnType: 'tcamDown', btnText, ...(btnLink.trim() && {btnLink}) }} />}
                    </ButtonContainer>
                  </PearMobile>
                }
            </Bottom>
            { (isElementVisible || delayFloatingHidden) &&
            <FloatingDiv>
                <FloatingContainer className="floating-container">
                    <TcamPear onClick={open3DModelHandler} hasScrollAnimation={true} {...btnLink.trim() ? {link: btnLink} : {}}/>
                    {btnText?.trim() && <Button onClick={open3DModelHandler} {...{btnType: 'tcam', btnText, ...(btnLink.trim() && { btnLink })}} /> }
                </FloatingContainer>
            </FloatingDiv>
            }
            {open3DModel &&
              <Tcam3DModel sourceUrl={iframeUrl} status3DModel={open3DModel} updadeStatus3DModel={() => setOpen3DModel(false)} />
            }
        </Container>
        {isOnMobile &&
          <PearContainer>
            <TcamPear onClick={open3DModelHandler} hasAnimation={true} {...btnLink.trim() ? {link: btnLink} : {}} />
          </PearContainer>
        }
      </Wrapper>
    );
};

export default TcamWiggle;