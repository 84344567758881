import React, { useState, useEffect} from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 30px;
`;

const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    padding: 10px;
    background-color: #001eff;
    color: #fff;
    border: none;
    border-radius: 10px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
`;

const Text = styled.div`
    font-size: 24px;
    margin-bottom: 12px;
`;

const CmsLogout = () => {
    const [ second, setSecond ] = useState(10);

    useEffect(() => {
        document.cookie = "cms-login-cookie=false; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        let countdown = second;
        const interval = setInterval(() => {
            if(countdown > 0){
                setSecond(second => second - 1);
                countdown--;
            } else {
                window.close();
            }
        }, 1000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return ( 
        <Container>
            <Text>This window will close in {second}</Text>
            <Button onClick={()=> window.close()}>Close now</Button>
        </Container>
     );
}
 
export default CmsLogout;