export const locales = {
    "au": {
        "language": [{ "code": "en", "name": "English", "default": true }]
    },
    "nz": {
        "language": [{ "code": "en", "name": "English", "default": true }]
    },
    "ca": {
        "language": [
            { "code": "en", "name": "English", "default": true },
            { "code": "fr", "name": "Français" }
        ]
    },
    "us": {
        "language": [{ "code": "en", "name": "English", "default": true }]
    },
    "uk": {
        "language": [{ "code": "en", "name": "English", "default": true }]
    },
    "ie": {
        "language": [{ "code": "en", "name": "English", "default": true }]
    },
    "fr": {
        "language": [
            { "code": "fr", "name": "Français", "default": true },
            { "code": "en", "name": "English" }
        ]
    },
    "ch": {
        "language": [
            { "code": "de", "name": "Deutsch", "default": true },
            { "code": "fr", "name": "Français" },
            { "code": "en", "name": "English" }
        ]
    },
    "de": {
        "language": [
            { "code": "de", "name": "Deutsch", "default": true },
            { "code": "en", "name": "English" }
        ]
    },
    "nl": {
        "language": [
            { "code": "nl", "name": "Nederlands", "default": true },
            { "code": "en", "name": "English" }
        ]
    },
    "es": {
        "language": [
            { "code": "es", "name": "Español", "default": true },
            { "code": "en", "name": "English" }
        ]
    },
    "no": {
        "language": [
            { "code": "nb", "name": "Norsk bokmål", "default": true },
            { "code": "en", "name": "English" }
        ]
    },
    "se": {
        "language": [
            { "code": "sv", "name": "Svenska", "default": true },
            { "code": "en", "name": "English" }
        ]
    },
    "dk": {
        "language": [{ "code": "en", "name": "English", "default": true }]
    },
    "eu": {
        "language": [
            { "code": "en", "name": "English", "default": true },
            { "code": "de", "name": "Deutsch" },
            { "code": "es", "name": "Español" },
            { "code": "fr", "name": "Français" }
        ]
    },
    "cz": {
        "language": [
            { "code": "cs", "name": "Čeština", "default": true },
            { "code": "en", "name": "English" }
        ]
    },
    "be": {
        "language": [
            { "code": "en", "name": "English", "default": true },
            { "code": "nl", "name": "Nederlands" },
            { "code": "fr", "name": "Français" }
        ]
    },
    "at": {
        "language": [
            { "code": "de", "name": "Deutsch", "default": true },
            { "code": "en", "name": "English" }
        ]
    },
    "sg": {
        "language": [{ "code": "en", "name": "English", "default": true }]
    },
    "ex": {
        "language": [
            { "code": "en", "name": "English", "default": true },
            { "code": "es", "name": "Español" },
            { "code": "fr", "name": "Français" }
        ]
    }
}


export const hreflangStandardCodes = {
    "en-uk": "en-gb",
    "en-eu": "en",
    "de-eu": "de",
    "fr-eu": "fr",
    "es-eu": "es",
    "en-ex": "en",
    "es-ex": "es",
    "fr-ex": "fr"
}

export const cloudFrontUrls = {
    "di6zu7qhqdhsr.cloudfront.net": "au",
    "d1ddvyclchmo4y.cloudfront.net": "nz",
    "d23ezety1qok32.cloudfront.net": "ie",
    "d8loua3wooenr.cloudfront.net": "us",
    "dfi88kws6r01o.cloudfront.net": "uk",
    "d2dvmzy18p3frk.cloudfront.net": "ca",
    "d15uphwd4tvxn9.cloudfront.net": "dk",
    "dt92ks2xx59t6.cloudfront.net": "sg",
    "dcr9vgachx84x.cloudfront.net": "ex",
    "dny75t48fpg9c.cloudfront.net": "eu",
    "d3534nhqavbxvq.cloudfront.net": "be",
    "d3e5ej5dc7quvb.cloudfront.net": "ch",
    "d1w4cartu1pskr.cloudfront.net": "cz",
    "d1riglqe7ncnln.cloudfront.net": "at",
    "d1ho26g5bntiot.cloudfront.net": "es",
    "dqzkpf9fop1bt.cloudfront.net": "de",
    "d2kl7c77d1t2r1.cloudfront.net": "no",
    "d372vr9zy16exu.cloudfront.net": "se",
    "d2wkdevigndgxl.cloudfront.net": "nl",
    "d1f6eas3pbq8z2.cloudfront.net": "fr",

    "d2dv6zlv7zzndq.cloudfront.net": "dr",
    "d49t9m54hx2iw.cloudfront.net": "au",
    "d1noq7r95tgipk.cloudfront.net": "nz",
    "dseebj0wlmhxk.cloudfront.net": "uk",
    "d1oez4kbvsai9h.cloudfront.net": "us",
    "d3nocjlgc7f5mm.cloudfront.net": "ie",
    "d1ybcrxfz61yix.cloudfront.net": "ca",
    "d1tax7cya0lpdg.cloudfront.net": "eu",
    "d2r0kshtpuewtr.cloudfront.net": "sg",
    "d181jpt9f8gl3r.cloudfront.net": "dk",
    "d3o6gynk8xorfx.cloudfront.net": "be",
    "d28dtiz8592ie7.cloudfront.net": "ex",
    "d3dod215y5ru33.cloudfront.net": "fr",
    "dic08mqwjvd5z.cloudfront.net": "ch",
    "dxcfdjxms8ula.cloudfront.net": "no",
    "d2zx1igb5ic47y.cloudfront.net": "cz",
    "d1eadoz4xcovif.cloudfront.net": "nl",
    "d2jzmydavwoae4.cloudfront.net": "se",
    "d2mfhwi95cxiid.cloudfront.net": "es",
    "d4esfncie0eke.cloudfront.net": "de",
    "d2lc0iuosdojaw.cloudfront.net": "at"
};