import React from 'react';
import * as Common from  'components/common';
import { getImage } from 'components/utils';
import { Container, Date, Title, Author, AuthorImgContainer, AuthorImg, FirstLetter, AuthorInfo, AuthorTitle } from './Info.style';
import { pushAnyClickEvent } from '../../tracking/events';

const Info = ({ country, publishedDate, title, author }) => {
    return ( 
        <Container>
            <Date>
                {publishedDate ? new window.Date(publishedDate).toLocaleDateString(['us', 'ca'].indexOf(country) >= 0 ? 'en-US' : 'en-AU', { year: 'numeric', month: 'long', day: 'numeric' }) : ''}
            </Date>
            <Title>
                <Common.Heading level="h1">{title}</Common.Heading>
            </Title>
            <Author>
                <AuthorImgContainer>
                    <AuthorImg {...(author.photo && author.photo.url) ? 
                        { backgroundImage: `url(${getImage(author.photo,'small', true)})` }
                        : { children: <FirstLetter>{author.name.substring(0,1)}</FirstLetter>}
                    } />
                   
                </AuthorImgContainer>
                <AuthorInfo>
                    <AuthorTitle><Common.Text level="small">{author.title}</Common.Text></AuthorTitle>
                    {author.url ? 
                        <Common.AnimatedLink href={author.url} onClickCapture={() => pushAnyClickEvent({linkUrl: author.url, linkText: author.name})}  {...(author.url.indexOf('movember.com') >= 0) ? '' : {target: '_blank'}}>
                            {author.name}
                        </Common.AnimatedLink>
                        : <div>{author.name}</div>
                    }
                </AuthorInfo>
            </Author>
        </Container>
     );
}
 
export default Info;