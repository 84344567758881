import React from 'react';
import styled from 'styled-components';

const StyledAnimatedLink = styled.a`
    display: inline-block;
    color: #000;
    text-decoration: none;

    &::after {
        content: '';
        display: block;
        position: relative;
        top: -10px;
        height: 8px;

        width: 100%;
        border-bottom: solid 2px ${props => props.theme.palette.link.normal};
       
    }

    &:hover {
        cursor: pointer;
        color: ${props => props.theme.palette.link.hover};
        &::after {
            border-color: ${props => props.theme.palette.link.hover};
            animation-duration: 1s;
            animation-name: changewidth;
            animation-iteration-count: infinite;
            animation-direction: alternate;
        }
    }

    @keyframes changewidth {
        from {
          width: 0%;
        }
        to {
          width: 100%;
        }
    }
`;

const AnimatedLink = (props) => {
    return ( 
        <StyledAnimatedLink {...props}></StyledAnimatedLink>
    );
}
 
export default AnimatedLink;