import { Container } from 'themes/MoGrid';
import { Wrapper, PartnerContainer } from './Partners.styles';
import { PartnerRenderConfig, PartnersDetails } from './Partners.models';
import PartnersRow from './PartnersRow';
import React, { Fragment } from 'react';

function Partners({ partners }: PartnersDetails) {
    // change config as need so that what types of partners need to be renderd and how many logos need to be shown at a instance in mobile or in desktop
    const renderingPartnersConfig: PartnerRenderConfig[] = [
        { type: 'major', displayLogoCount: 4},
        { type: 'official', displayLogoCount: 4},
        { type: 'promo', displayLogoCount: 2 },
        { type: 'fund', displayLogoCount: 2 },
    ]

    return (
        <Wrapper>
            <PartnerContainer>
                <Container>
                    {partners.map((partner, index) => {
                        // render the partners only avilable in "renderingPartnersConfig"
                        const partnerRenderConfig = renderingPartnersConfig.find((config) => config.type === partner.type);
                        if (partnerRenderConfig && partnerRenderConfig.displayLogoCount > 0 && partner.items && partner.items.length > 0) {
                            return (
                                <PartnersRow key={partner.type} {...partner} partnerRenderConfig={partnerRenderConfig} />
                            )
                        }
                        return <Fragment key={index}></Fragment>
                    })}
                </Container>
            </PartnerContainer>
        </Wrapper>
    )
}

export default Partners