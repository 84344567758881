import styled from 'styled-components';

const Container = styled.div`
    height: ${props => props.height ? props.height : 'auto'};
`;

const StyledHeader = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 75px;
    margin: auto;
    padding: 0;
    background: #ffffff;
    transition: top .6s ease;
    z-index: 1999;

    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Overpass", sans-serif !important;
    font-weight: 400 !important;
    font-size: 16px;
    letter-spacing: normal;
    transition: top .6s ease;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        height: 75px;
        padding: 0 16px;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
        padding: 0 32px;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
        padding-left: calc(50% - 700px);
        padding-right: calc(50% - 700px);
    }    
`;

const HeaderLogo = styled.div`
    position: absolute;
    left: 50%;
    width: 75px;
    height: 55px;
    min-width: 75px;
    background-image: url(${props => props.logoMobile});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transform: translate(-50%);
    z-index: 1;

    & > a {
        display: flex;
        height: 100%;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        position: static;
        width: 125px;
        height: 75px;
        background-image: url(${props => props.logo});
        transform: none;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
        min-width: 120px;
    }

    ${(props) =>props.preview && `
    background-color: yellow;
  `}
`;

const Preview = styled.span`
    display: flex;
    justify-content: center;
`;

const Menu = styled.div`
    width: 100%;
    @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
        width: auto;
     }
`;

const Nav = styled.div`
    display: none;
    overflow: hidden;
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
        display: block;
    }    
`;

const NavMenu = styled.nav`
    display: flex;
`;

const NavItem = styled.div`
    padding: 0 1rem;
    font-weight: normal;
    text-transform: uppercase;
    text-decoration: none;
    color: black;

    & > a {
        display: table-cell;
        vertical-align: middle;
        height: 75px;
        line-height: 75px;
        text-decoration: none;
        color: black;
        white-space: nowrap;

        &:hover {
            color: ${props => props.theme.palette.link.hover};
            cursor: pointer;
            :after {
                width: 70%;
            }
        }

        &:after {
            content: ' ';
            display: block;
            margin: auto;
            margin-top: -5px;
            height: 5px;
            background: ${props => props.theme.palette.link.hover};
            width: 0%;
            transition: width 0.3s;
        }
    }
`;

const CTAs = styled.div`
    float: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 200px;
    font-weight: 600;

    @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
       float: right;
       flex-direction: row-reverse;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
        flex-direction: row;
    }
`;

const IconsGroup = styled.div`
    display: flex;

    @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
        flex-direction: row-reverse;
    }
`;

const Icon = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 54px;
    border: none;
    background: #fff;
    cursor: pointer;

    & > a {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        transition: top .5s ease, opacity .5s ease, visibility .5s ease;
    }

    &:after {
        content: '';
        display: none;
        width: 2px;
        position: absolute;
        right: -1px;
        top: 15%;
        height: 70%;
        background-color: #D7D8DE;

        @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
            display: block;
        }
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
        height: 75px;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
        display: ${props => props.isSideMenu ? 'none' : 'block'};
    }
`;

const IconStart = styled.a`
    background-image: url(${props => props.isSideMenu ? '/resources/images/icons/menu.svg' : '/resources/images/icons/search.svg' });
    background-position: center;
    background-repeat: no-repeat;
    opacity: ${props => props.isVisible ? '1' : '0'};
    visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
    top: ${props => props.isVisible ? '0%' : '20%'};
`;

const IconClose = styled.a`
    background-image: url("/resources/images/icons/close.svg");
    background-position: center;
    background-repeat: no-repeat;
    opacity: ${props => props.isVisible ? '1' : '0'};
    visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
    top: ${props => props.isVisible ? '0%' : '20%'};
`;

const SearchBar = styled.section`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 1400px;
    margin: auto;
    background: #000;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.35);
    height: ${props => props.headerHeight === '75px' ? '120px' : '190px'};
    padding-top: ${props => props.headerHeight === '75px' ? '77px' : '142px'};
    z-index: 1997;
    transition: opacity .4s ease, visibility .4s ease, margin-top .4s ease, top .6s ease, height .4s ease;
    opacity: ${props => props.isVisible ? '1' : '0'};
    visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
    margin-top: ${props => props.isVisible ? '0px' : '-40px'};

    @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
        height: 115px;
        padding-top: 75px;
     }
`;

const SearchForm = styled.div`
    margin-top: 5px;
`;

const SearchInput = styled.input`
    display: block;
    height: 30px;
    width: 360px;
    padding: 0 38px 0 4px;
    margin: 0 2rem 0 auto;
    font-size: 15px;
    line-height: 30px;
    border-color: #FFF;
    border-radius: 3px;
`;

const SideNavContainer = styled.div``;

const ButtonsGroup = styled.div`
    display: flex;
    align-items: center;
`;

const LoggedIn = styled.div`
`;

const User = styled.div`
    position: relative;
    display: flex;
    margin: 0 20px;
    cursor: pointer;
`;

const FirstName = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 45px;
    padding: 0 16px;
    color: #1443EF;
    text-transform: capitalize;
    &:before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: "\f078";
        display: block;
        position: absolute;
        color: #1443EF;
        left: 0;
        text-decoration: none;
        font-size: 11px;
        vertical-align: middle;
    }
`;

const UserImage = styled.div`
    width: 45px;
    height: 45px;
    img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
    }
`;

const UserMenu = styled.div`
    position: fixed;
    right: 0;
    left: 0;
    top: 65px;
    min-width: 400px;
    opacity: ${props => props.isVisible ? '1' : '0'};
    visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
    transition: opacity .3s ease, visibility .3s ease, margin-top .3s ease;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}) {
       position: absolute;
       left: 0;

       &:after {
            left: 24px;
            right: unset !important;
       }
    }

    &:after { 
        content: "";
        position: absolute;
        display: block;
        border: 14px transparent double;
        border-bottom: 14px #151515 solid;
        top: -24px;
        right: 90px;
    }
    
    ul {
        position: relative;
        list-style: none;
        padding: 0;
        margin: 0;
        background: #151515;
        box-shadow: 0px 4px 6px rgb(0 0 0 / 30%);

        li {
            a, button {
                position: relative;
                transition: color .1s linear, background-color .3s linear;
                color: #fff;
                padding: 12px 4rem 12px 2rem;
                min-width: 200px;
                display: block;
                font-weight: bold;
                text-decoration: none;

                &:hover {
                    background: #5E5D5D;
                }

                &:after {
                    font-family: 'Font Awesome 5 Free';
                    font-weight: 900;
                    content: "\f054";
                    display: inline;
                    font-size: 11px;
                    vertical-align: middle;
                    position: absolute;
                    right: 2rem;
                    top: 50%;
                    bottom: 50%;
                    line-height: 0;
                    padding-left: 5px;
                }
            }
        }
    }
`;

const LogOut = styled.li`
    a:after {
        content: "\f2f5" !important;
        font-size: 14px !important;
    }
`;

const Login = styled.a`
    height: 51px;
    padding: 15px;
    margin: 0 1rem;
    color: #000;
    text-transform: uppercase;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none;
    background: #fff;
    cursor: pointer;

    &:active, &:visited {
        color: #000;
    }
`;

const CampaingButtons = styled.div`
    display: flex;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: #FFF;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 1px rgba(0, 0, 0, 0.12), 0 4px 2px -2px rgba(0, 0, 0, 0.2);

    @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
        position: static;
        box-shadow: none;
     }
`;

const CampaingBtn = styled.a`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    min-width: 146px;
    padding: 16px 32px;
    margin: 12px 6px;
    text-decoration: none;
    letter-spacing: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #000;
    border: 1px solid ${props => props.isPrimary ? props.theme.palette.primary.border : props.theme.palette.secondary.border};
    border-radius: 24px;
    background: ${props => props.isPrimary ? props.theme.palette.primary.normal : props.theme.palette.secondary.normal};
    cursor: pointer;

    &:hover {
        background: ${props => props.isPrimary ? props.theme.palette.primary.hover : props.theme.palette.secondary.hover};
    }
`;

export { Container, StyledHeader, HeaderLogo, Preview, Menu, Nav, NavMenu, NavItem, CTAs, IconsGroup, Icon, IconStart, IconClose, SearchBar, SearchForm, SearchInput,
    SideNavContainer, ButtonsGroup, LoggedIn, User, UserImage, UserMenu, FirstName, LogOut, Login, CampaingButtons, CampaingBtn };