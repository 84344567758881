import styled from 'styled-components';
import { Button, Heading } from 'components/common';
import { renderImage, renderParagraph } from 'components/utils';

const Container = styled.div`
    width: 308px;
`;

const Image = styled.div`
    margin-bottom: 32px;

    img {
        width: 100%;
        height: auto;
    }
`;

const HeadingWrapper = styled.div`

    & > h2 {
        margin-top: 0;
        margin-bottom: 8px;
        color: #FFF;
    }
`;

const Description = styled.div`
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 32px;
    color: #FFF;
`;

const ButtonWrapper = styled.div`
    a {
        width: 100%;
    }
`;

interface Props {
    image?: string;
    heading: string;
    description: string;
    btnText: string;
    onClick: React.MouseEventHandler<HTMLElement>;
}

const Success: React.FC<Props> = ({image, heading, description, btnText, onClick}) => {
    return (
        <Container>
            <Image>{renderImage(image)}</Image>
            <HeadingWrapper><Heading level="h2" isHeading={true}>{heading}</Heading></HeadingWrapper>
            <Description>{renderParagraph(description)}</Description>
            <ButtonWrapper><Button btnType='secondary' dataAttributes={{'data-overlay': true}} btnText={btnText} onClick={onClick}/></ButtonWrapper>
        </Container>
    );
}

export default Success;
