import {useEffect} from 'react';
import { useHistory } from 'react-router-dom';

const CmsLogin = () => {
    let history = useHistory();
    let page = new URLSearchParams(window.location.search).get('redirect');

    useEffect(() => {
        // Redirect to page after cms preview loaded
        document.addEventListener('cms-preview-loaded', () => {
            if(page.indexOf('#preview') < 0){
                // eslint-disable-next-line react-hooks/exhaustive-deps
                page += '#preview';
            }
            history.push(page);
        });

        // Trigger event to load cms iframe
        document.dispatchEvent(new CustomEvent('cms-preview-activated'));
    }, [history, page]);

    return ( 
        <div>
            &nbsp;&nbsp; Loading preview for <strong>{page}</strong>
            &nbsp;&nbsp;<span className="fas fa-sync-alt fa-spin"></span>
        </div>
     );
}
 
export default CmsLogin;