import styled from 'styled-components';

const Container = styled.div`
    display: block;
    width: 100%;
    min-width: 200px;
    max-width: 1024px;
    position: absolute;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    padding: 32px;
    background-color: #FFF;
    box-shadow: 1px 3px 6px rgba(0,0,0,0.3);

    visibility: hidden;
    opacity: 0;
    top: 112%;
    transition: opacity .2s ease .25s, visibility .2s ease .25s, top .2s ease .25s;

    ${props => props.isVisible && `
        visibility: visible;
        opacity: 1;
        top: 100%;
    `}
`;

const Content = styled.div`
    display: flex;
`;

const Column = styled.div`
    flex: 1;
    padding: 0 16px;
    border-left: solid 1px #D7D8DE;
    transition: color .3s ease,background-color .3s ease;

    &:hover {
        border-color: ${props => props.theme.palette.link.hover};
    }
`;

const Heading = styled.div`
    min-height: 20px;
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 600;
`;

const Item = styled.a`
    display: block;
    padding-bottom: 20px;
    text-decoration: none;
    cursor: pointer;
    
    &:hover {
        & > span:first-child {
            color: ${props => props.theme.palette.link.hover};
        }
        i {
            padding-left: 6px;
            color: ${props => props.theme.palette.link.hover};
        }
    }
`;

const Title = styled.span`
    position: relative;
    display: block;
    font-weight: 600;
    color: #000;
    transition: color .3s;
    i {
        font-size: 13px;
        color: transparent;
        transition: color .3s, padding-left .3s;
    }
`;

const Description = styled.span`
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #1F2440;
`;

const StoryMenu = styled.div`
    display: flex;
    & > div {
        flex: 1;
    }
`;


const StoryItem = styled.div`
    a {
        display: block;
        padding-bottom: ${props => props.isPrimary ? '4px' : '8px'};
        font-size: ${props => props.isPrimary ? '32px' : '16px'};
        font-weight: ${props => props.isPrimary ? 'bold' : 'normal'};
        text-decoration: none;
        color: #1F2440;
        transition: color .3s ease,background-color .3s ease;
    
        &:hover {
            span {
                color: ${props => props.theme.palette.link.hover};
                border-bottom: solid 3px ${props => props.theme.palette.link.hover};
            }
        }
    }
`;


export { Container, Content, Column, Heading, Item, Title, Description, StoryMenu, StoryItem };