import styled from 'styled-components';

const ContainerWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;

const Container = styled.div<{headerHeightStyle: string}>`
    position: relative;
    width: 100%;
    height: ${props => props.headerHeightStyle === 'slim' ? '232.5px' : '100vh'}; 
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${props => props.headerHeightStyle === 'slim' ? '80px 45px' : '32px'};

    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.xs}) {
        ${props => props.headerHeightStyle === 'slim' ? 'height: 283.25px;' : ''}
    }
`;

const BackgroundImageWrapperMobile = styled.div`
    z-index: 1;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.xs}) {
        display: none;
    }
`;

const BackgroundImageWrapperTablet = styled(BackgroundImageWrapperMobile)`
    display: none;

    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.xs}) {
        display: block;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.md}) {
        display: none;
    }
`;

const BackgroundImageWrapperDesktop = styled(BackgroundImageWrapperMobile)`
    display: none;

    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.md}) {
        display: block;
    }
`;

const SvgImageWrapper = styled.div`
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
`;

const HeaderTextContainer = styled.div`
    padding: 12px 32px;
    border-bottom: 1px solid #EEEEEEDD;
`;

const Header = styled.h1`
    font-family: Overpass;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.19px;
    text-align: left;
    color: #999999;
    margin: 0;

    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.xs}) {
        text-align: center;
    }
`;

export { ContainerWrapper, Container, BackgroundImageWrapperMobile, BackgroundImageWrapperTablet, BackgroundImageWrapperDesktop, SvgImageWrapper, HeaderTextContainer, Header };
