import styled from 'styled-components'

const ErrorBox = styled.div`
        height: 100VH;
    width: auto;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
    font-family: 'Overpass';
    margin: auto 40px;
      

      img{
          max-width: 600px;
      }
      h1{
          margin: 100px 0 0 0;
      }
       h2{
        font-weight: 300;
      }
      h3{
          margin-top: 0;
          font-weight: 300;
      }`;

export default ErrorBox;