import React from "react";
import { Video } from "./HeroVideo.style";

const VideoView = (props) => {
  return (
    <Video
      autoPlay
      data-vscid="w08173nl4"
      loop
      muted
      playsInline
      src={props.source}
      poster={props.poster}
    />
  );
};

export default VideoView;
