import styled from 'styled-components';

const BlockAuto = styled.div`
    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.md}){
        padding: 32px;
        display: flex;
        flex-direction: row;
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.lg}){
        padding: 56px;
    }
`;

const AutoImage = styled.div`
    flex: 1;
`;

const AutoText = styled.div`
    flex: 1;
    padding: 24px 18px;
`;

const BlockCarousel = styled.div`
    padding: 32px;
    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.lg}){
        padding: 56px;
    }
`;

const Slides = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.md}){
        flex-direction: row;
        margin-left: -12px;
    }
`;

const Slide = styled.div`
    width: 300px;
    height: 300px;
    padding: 12px;
`;

export { BlockAuto, AutoImage, AutoText, BlockCarousel, Slides, Slide};