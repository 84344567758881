import styled from "styled-components";

export const ComponentContainer = styled.div`
  & > div {
    margin-bottom: 32px;
  }
  & > div:last-child {
    margin-bottom: 0;
  }
`;

export const HeadingContainer = styled.div`
  & > h2 {
    color:${props => props.theme.palette.mo.charcoal}
  }
`;

export const SubHeadingContainer = styled.div`
  & > h3 {
    color:${props => props.theme.palette.mo.charcoal2}
  }
`;