import styled, {css} from "styled-components";

const StyledInput = styled.input<{type: string, hasError?: boolean}>`
    ${(props: {type: string}) => props.type !== 'checkbox' && css`
        width: 100%;
        height: 56px;
        padding: 12px;
        border: none;
        border-radius: 12px;
    `}
    ${(props: {type: string}) => props.type === 'checkbox' && css`
        width: 21px;
        height: 21px;
        margin: 0;
        transition: background-color ease .4s, background-position ease .4s, border-color ease .4s;
        background-position: 50% 150%;
        ${(props: {hasError?: boolean}) => props.hasError && css`
            outline: solid 1px #D93A3F;
        `}
    `}

    ${(props: {hasError?: boolean}) => props.hasError && css`
        border: solid 2px #D93A3F;
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #D93A3F;
            opacity: 1; /* Firefox */
        }
        &::-ms-input-placeholder { /* Microsoft Edge */
            color: #D93A3F;
        }
    `}
`


export { StyledInput };