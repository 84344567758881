import { Container } from 'themes/MoGrid';
import SearchComponent from './SearchComponent/SearchComponent';
import { useTranslation, getApiUrl, getCurrentCountryCode } from 'components/utils';
import styled from 'styled-components';


const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const ComponentContainer = styled.div`
    width: 100%;
    max-width: 505px;
`;

const Title = styled.div`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
`;

const Description = styled.div`
    margin-bottom: 20px;
    line-height: 28px;    
`;

interface Props {
    searchType: 'team' | 'member';
    networkId?: string;
    title?: string;
    description?: string;
}

const Search: React.FC<Props> = ({ searchType = 'team', networkId, title, description }) => {
    const translate = useTranslation;

    const searchProps = { 
        api: getApiUrl(`/${searchType}?&q=query&countryCode=${getCurrentCountryCode()}&page=1&start=1&limit=10${networkId ? `&networkId=${networkId}` : ''}`),
        placeholderText: translate('text-search-placeholder')
    }

    return (
        <Container data-test-id="search-cotainer">
            <Wrapper>
                <ComponentContainer>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                    <SearchComponent
                        {...searchProps}
                    />
                </ComponentContainer>
            </Wrapper>
        </Container>
    );
};

export default Search;