import React, { useState, useRef } from 'react';
import LoadingIndicator from './LoadingIndicator.jsx';
import SearchInput from '../SearchInput/SearchInput.jsx'
import Text from 'components/common/Text/Text.jsx';
import SearchResultsTeams from './SearchResultsTeams.jsx';
import { NoResultsContainer } from './TeamsSearch.style.js';
import {useTranslation} from 'components/utils';

import styled from 'styled-components';

const SearchComponent = ({
  api,
  placeholderText,
  onSelectItem = () => {},
  ...props
}) => {
  const [value, setValue] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [noResult, setNoResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [noInput, setNoInput ] = useState(false)

  const textInput = useRef(null);

  const showSearchResults = !loading && searchResult && searchResult.length > 0 && !noResult;

  const translate = useTranslation

  return (
    <SearchComponentContainer {...props}>
      <SearchInput
        value={value}
        setValue={setValue}
        setSearchResult={setSearchResult}
        setLoading={setLoading}
        setError={setError}
        setNoResult={setNoResult}
        textInput={textInput}
        api={api}
        placeholderText={placeholderText}
        setNoInput={setNoInput}
        noInput={noInput}
      />

      {noInput && <Text variant="overpassExtSm" color="#D93A3F" margin="12px auto">{translate('text-search-no-input')}</Text>}

      {loading && <LoadingIndicator />}

      {showSearchResults && <SearchResultsTeams searchResult={searchResult} onSelectTeam={onSelectItem}/>}

      {noResult && <React.Fragment>
        <NoResultsContainer>
          <Text variant='overpassSm'>
            {translate('text-search-no-result')}
          </Text>
          <Text variant='overpassSm' fontWeight="bold" marginLeft="4px">{value}</Text>
        </NoResultsContainer>
        <Text variant='overpassSm' textAlign="center">
          {translate('text-search-again')}
        </Text>
      </React.Fragment>}

      {error && <div style={{color: "#D93A3F"}}>{translate('text-search-error')}</div>}
    </SearchComponentContainer>
  );
};

export default SearchComponent;

const SearchComponentContainer = styled.div`
  ${({ margin }) => margin && `margin: ${margin};`}
`