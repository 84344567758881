import styled, { css } from "styled-components";

export type Type = 'primary' | 'secondary' | 'external' | 'internal' | 'download' | 'link' | 'arrow' | 'secondaryArrow' | 'tcam' |  'tcamUp' | 'tcamDown';

interface ButtonProps {
    type: Type;
    isDisabled: boolean;
    isDynamicBtn?: boolean;
}

export const StyledButton = styled.a<ButtonProps>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 134px;
    height: 42px;
    padding: 10px 22px;
    font-size: 16px;
    color: ${({ theme }) => theme.palette.secondary.text};
    background-color: ${({ theme })  => theme.palette.secondary.normal};
    text-decoration: none;
    border: solid 1px #000;
    border-radius: 21px;
    cursor: pointer;

    &:hover {
        color: #000;
        background-color: ${({ theme })  => theme.palette.secondary.hover};
    }

    ${({ type, theme}) => type === 'primary' && css`
        border: none;
        color: ${theme.palette.primary.text};
        background-color: ${theme.palette.primary.normal};
        &:hover {
            color: #FFF;
            background-color: ${theme.palette.primary.hover};
        }
    `}

    ${({ type, theme }) => (type === 'internal' || type === 'external') && css`
        text-decoration: underline;
        border: none;
        color: ${theme.palette.primary.normal};
        background-color: transparent;
        &:hover {
            color: ${theme.palette.primary.normal};
            background-color: transparent;
        }
        &:visited {
            color: ${theme.palette.primary.hover};
        }
    `}

    ${({ type, theme }) => (type === 'arrow' || type === 'download' || type === 'link') && css`
        text-decoration: underline;
        padding: 0;
        border: none;
        color: ${theme.palette.primary.normal};
        background-color: transparent;
        justify-content: start;
        &:hover {
            color: ${theme.palette.hover.color};
            background-color: transparent;
            transition: linear 0.2s;
        }
        &&& {
            @media screen and (min-width: ${theme.breakpoints.sm}){
                min-width: 0;
            } 
        }
    `}
   
    ${({ isDisabled }) => isDisabled && css`
        opacity: .5;
        cursor: not-allowed;
        pointer-events: none;
    `}

    ${({isDynamicBtn, type, theme })=> isDynamicBtn && css`
        ${(type === 'internal' || type === 'external') && `
        justify-content: left;
        padding-left: unset;
        width: fit-content;
        block-size: fit-content;
        &:hover {
          background-color: transparent;
        }`}
        
        @media screen and (max-width: ${theme.breakpoints.sm}){
            width: 100%;
        } 
    `}

    ${({ type, theme}) => type === 'secondaryArrow' && css`
        border: none;
        color: ${theme.palette.secondary.text};
        background-color: ${theme.palette.secondary.normal};

        svg {
            width: 20px;
            height: 20px;
        }
        &:hover {
            background-color: ${theme.palette.secondary.normal};
        }
    `}

    ${({ type, theme}) => (type === 'tcam' || type==='tcamUp' || type === 'tcamDown') && css`
        border: none;
        height: auto;
        color: ${theme.palette.primary.text};
        background-color: ${theme.palette.mo.greyTone};
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 32px;
        &:hover {
            color: #FFF;
            background-color: ${theme.palette.primary.hover};
        }

        span {
            color: #FFF;
            font-family: Overpass;
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: 28px;
        }
    `}

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}){
        min-width: 170px;
        height: 56px;
        ${({ type }) => (type !== 'primary' && type !== 'secondary') && css`
            height: auto;
            min-height: 28px;
        `}
        font-size: 18px;
        border-radius: 28px;
    }
`; 


const ArrowCurlBase = styled.div`
    width: 38px;
    height: 16px;
    flex-shrink: 0;
    background-position: center;
    background-repeat: no-repeat;
`;

export const ArrowCurl = styled(ArrowCurlBase)`
    background-image: url('/resources/images/icons/arrow-curl.svg');
`;

export const ArrowCurlUp = styled(ArrowCurlBase)`
    background-image: url('/resources/images/icons/arrow-curl-up.svg');
`;

export const ArrowCurlDown = styled(ArrowCurlBase)`
    background-image: url('/resources/images/icons/arrow-curl-down.svg');
`;
