import styled, {css} from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 32px;
    color: #FCFCFE;
    background-color: #161719;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        flex-direction: row;
        padding: 48px;
    }
`;

const titleCss = css`
    margin-bottom: 32px;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    text-transform: uppercase;
`;

const Title = styled.div`
   ${titleCss}
   flex: 1;
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
       display: none;
    }
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
        display: flex;
        min-width: 200px;
        max-width: 320px;
        padding-right: 48px;
        margin-bottom: 0;
    }
`;

const TitleTabletView = styled.div`
    ${titleCss}
    display: none;
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        display: flex;
        margin-bottom: 48px;
    }
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
        display: none;
    }
`;

const Image = styled.div`
    width: 210px;
    height: 210px;
    margin-bottom: 32px;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        flex: 1;
        margin-bottom: 0;
        width: auto;
        height: auto;
    }
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
        padding-left: 48px;
        padding-right: 48px;
    }

    img {
        width: 100%;
        border-radius: 50%;
        aspect-ratio: 1 / 1;
    }
`;

const Content = styled.div`
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        flex: 3;
        padding-left: 48px;
    }
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
        flex: 2;
    }
`;

const Quote = styled.div`
    display: flex;
    margin-bottom: 32px;
`;

const SideBar = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 15px;
`;

const QuoteIcon = styled.span`
    width: 15px;
    height: 12px;
    background-image: url('/resources/images/icons/quote.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;

const Bar = styled.span`
    position: absolute;
    bottom: 0;
    left: 6px;
    top: 22px;
    width: 2px;
    background-color: #B9BAC5;
`;


const Message = styled.div`
    font-family: Lora;
    padding-left: 14px;
    font-size: 20px;
    line-height: 28px;

    p:first-child {
        margin-top: 0;
    }
    p:last-child {
        margin-bottom: 0;
    }
`;


const Link = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 48px;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        margin-bottom: 0;
    }
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
        margin-bottom: 40px;
    }
`;

const LinkIcon = styled.span`
    width: 22px;
    height: 16px;
    margin-right: 8px;
    background-image: url('/resources/images/icons/arrow.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;

const Text = styled.a`
    text-decoration: underline;
    color: #FCFCFE;
    cursor: pointer;
`;

export { Container, Title, TitleTabletView, Image, Content, Quote, SideBar, QuoteIcon, Bar, Message, Link, LinkIcon, Text };