import styled from 'styled-components';

const StyledConclusion = styled.div`
    font-family: Anton;
    font-weight: 400;
    font-size: 43px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 4rem 2rem;
`;

export { StyledConclusion } ;