import React from 'react';
import { renderParagraph, renderImage } from 'components/utils';
import { Container, LeftCol, RightCol, Header, Cta, Caption } from './Block2Column.style';
import { pushAnyClickEvent } from '../../../tracking/events'

const Block2Column = (data) => {
    return (
        <Container>
            <LeftCol>
                {data.left_column_header &&
                    <Header>{data.left_column_header}
                    </Header>
                }
                <div>{renderParagraph(data.left_column_paragraph)}</div>
                { data.left_column_cta &&
                    <Cta href={data.left_column_link} onClickCapture={()=> pushAnyClickEvent({linkText: data.left_column_cta, linkUrl: data.left_column_link})}>
                        {data.left_column_cta}
                        {/* <img src="/resources/images/icons/arrow.png" alt="arrow" /> */}
                    </Cta>
                }
            </LeftCol>
            <RightCol>
            
            {renderImage(data.right_column_image)}
            <Caption>
                <strong>{data.right_column_credit}</strong>
                {data.right_column_location}
            </Caption>
            </RightCol>
           
        </Container>
    );
}
 
export default Block2Column;
