import React from 'react';
import { Button } from 'components/common';
import { renderImage } from 'components/utils/render';
import { Container, Wrapper, Number, Heading, Text, ButtonsGroup, SecondaryButton, Image } from './MemberTally.style';

interface Props {
    number: number,
    heading: string,
    text: string,
    primaryButton: any,
    secondaryButton: any,
    image: any
}

const MemberTally: React.FC<Props> = ({number, heading, text, primaryButton, secondaryButton, image}) => {
    return (
        <React.Fragment>
            {(number || heading || text ||
                (secondaryButton && secondaryButton.btnText && secondaryButton.btnLink) ||
                (primaryButton && primaryButton.btnText && primaryButton.btnLink)) &&
                <Container>
                    <Wrapper>
                        {number && <Number>{number}</Number>}
                        <Heading>{heading}</Heading>
                        <Text>{text}</Text>
                        <ButtonsGroup>
                            {secondaryButton && secondaryButton.btnText && secondaryButton.btnLink && <SecondaryButton><Button {...secondaryButton} /></SecondaryButton>}
                            {primaryButton && primaryButton.btnText && primaryButton.btnLink && <Button {...primaryButton} />}
                        </ButtonsGroup>
                    </Wrapper>
                    {image && image.url && <Image>{renderImage(image)}</Image>}
                </Container>
            }
        </React.Fragment>  
    );
};

export default MemberTally;