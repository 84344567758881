import React, { Component } from 'react';

class TextRotate extends Component {
    constructor() {
        super();

        this.setTimeout = null;

        this.state = {
            text: '',
            paused: false
        }
    }

    render() { 
        return ( 

            <span >{this.state.text}&nbsp;</span>
         );
    }

    componentDidMount() {
        const textArray = this.props.textArray;
        if(textArray.length > 0){
            let period = this.props.period || 2000;
            this.animateText(textArray, period);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.setTimeout);
    }

    animateText(textArray, period, increment = 0, isDeleting = false, isPaused = false) {
        let index = increment%textArray.length;
        let fullText = textArray[index];
        let currentText = isDeleting ? fullText.substring(0, this.state.text.length - 1) : fullText.substring(0, this.state.text.length + 1);
        let _this = this;

        this.setState({text: currentText, paused: isPaused});


            if(this.state.paused){
                this.setTimeout = setTimeout(function(){

                    isPaused = false;
                    isDeleting = true;
                    _this.animateText(textArray, period, increment, isDeleting, isPaused); 
                }, 1000)
            }
            else{
                this.setTimeout = setTimeout(function(){
                    if(currentText.length === fullText.length){
                        isPaused = true;
                        isDeleting = false;
                        period/= 3;
    
                    }
                    if(currentText.length === 0) {
                        isDeleting = false;
                        increment++;
                        period *= 3;
                    }    
                    _this.animateText(textArray, period, increment, isDeleting, isPaused);  
                },period)
            }
    }
}
 
export default TextRotate;