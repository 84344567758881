import styled from 'styled-components';
import { breakpoints } from 'themes/breakpoints.theme';

const Container = styled.div`
`;

const CTAContainer = styled.div`
    display: flex;
    justify-content: ${props => props.isCta ? 'space-between' : 'flex-end'};

    @media screen and (min-width: ${breakpoints.md}) {
        justify-content: flex-end;
    }
`;

const CTA = styled.a`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    padding: 8px 16px;
    margin-right: 20px;
    font-family: Overpass;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    color: #FFF;
    background-color: rgba(255, 255, 255, 0.2);
    letter-spacing: normal;
    line-height: normal;
    border: solix 1px #999999;
    border-radius: 24px;
    cursor: pointer;
    z-index: 1;

    span { 
        display: inline-block;
        margin-left: 10px;
        width: 14px;
        height: 12px;
        border: none;
        background-image: url('/resources/images/icons/arrow.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    &:hover, &:active, &:focus {
        background-color: #333333;
        outline: none;
    }
`;

const ArrowsContainer = styled.div`
    display: inline-flex;
    z-index: 2;
`;

const StepIndicator = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    min-width: 70px;
    padding: 0 16px;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    @media screen and (min-width: ${breakpoints.sm}) {
        padding: 0 27px;
    }
`;

const Arrow = styled.a`
    width: 40px;
    height: 40px;
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
    background-image: url('/resources/images/icons/arrow.png');
    background-position: center;
    background-size: 14px;
    background-repeat: no-repeat;
    cursor: pointer;
    outline: none;
    border-radius: 4px;

    ${props => props.direction === 'left' && `
        transform: rotate(180deg);
    `}

    &:hover {
        background-color: #333333;
    }

    &:focus {
        outline: none;
    }
`;

const Slide = styled.div`
    background-color: ${props => props.theme.palette.primary.normal};
    display: flex;
    flex-direction: column;
    @media screen and (min-width: ${breakpoints.md}) {
        flex-direction: row-reverse;
    }
`;

const Body = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 376px;
    color: #fff;
    padding: 24px 18px;
    @media screen and (min-width: ${breakpoints.md}) {
        padding-right: 32px;
    }
    @media screen and (min-width: ${breakpoints.lg}) {
        padding-right: 56px;
    }
`;


const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width: ${breakpoints.sm}){
    }

    @media screen and (min-width: ${breakpoints.md}){
        padding: 40px 35px;
    }

    @media screen and (min-width: ${breakpoints.lg}){
        padding: 40px 50px;
    }
`;

const Description = styled.div`
    font-size: 24px;
    margin-bottom: 48px;
    font-weight: 200;
`;

const Author = styled.a`
    display: inline-flex;
    margin-top: 30px;
    margin-left: 2px;
    font-weight: normal;
    text-decoration: none;
    cursor: pointer;

    &:hover, &:focus, &:active {
        font-weight: normal;
        outline: none;
    }
`;

const Image = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    color: #0C0C0C;
    border-radius: 50px;
    background-color: lightgrey;
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 50px;
    }
    &:hover {
        color: #0C0C0C;
    }
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 0.8px;

    @media screen and (min-width: ${breakpoints.md}){
        font-size: 14px;
        line-height: 20px;
    }
`;

const Profile = styled.a`
    flex: 1;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    z-index: 2;
    @media screen and (min-width: ${breakpoints.sm}) {
        flex-direction: row-reverse;
    }
    @media screen and (min-width: ${breakpoints.md}) {
        flex: auto;
        flex-direction: column-reverse;
        max-width: 376px;
    }
    @media screen and (min-width: ${breakpoints.lg}) {
        max-width: 436px;
    }
`;

const ImageContainer = styled.div`
    position: relative;
    overflow: hidden; 
    background-color: #c4c4c4;
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media screen and (min-width: ${breakpoints.sm}) {
        min-height: 465px;
        flex:1;
    }
    @media screen and (min-width: ${breakpoints.lg}) {
        padding-bottom: 100%;
        min-height: auto;
    }
    
`;


const Title = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-left: 0;
    padding: 16px 18px;
    font-size: 14px;
    font-weight: normal; 
    text-transform: uppercase;
    letter-spacing: 0.8px;
    color: #fff;
    background-color: ${props => props.theme.palette.mo.charcoal};
    @media screen and (min-width: ${breakpoints.sm}) {
        justify-content: flex-end;
        align-items: start;
        padding: 21px;
    }
    @media screen and (min-width: ${breakpoints.md}) {
        padding: 16px;
    }
`;


export { Container, Slide, ArrowsContainer, StepIndicator, Arrow, Author, Image, ImageContainer, Info, Body, Content, Description, CTAContainer, CTA, Profile, Title };