import styled from 'styled-components';

const Container = styled.div`
`;

const Image = styled.div`
    margin: 0 -16px;
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        margin: 0;
    }
`;

const ImageContainer = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 56%
    background-color: lightgrey;
    ${props => props.isNotSelecting && `
        opacity: .5;
    `}
    
    &:hover {
        ${props => props.isSideGallery && `
            opacity: 1;
            cursor: pointer;
        `} 
    }
`;

const VideoContainer = styled.iframe`
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const Picture = styled.div`
    picture {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    
        source, img {
            width: 100%;
            height: 100%;
            max-height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
`;

const VideoIcon = styled.i`
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 32px;
    transform: translate(-50%, -50%);
    color: ${props => props.theme.palette.link.normal};
    text-shadow: 0px 0px 50px rgba(20,67,239,0.5);

    &:hover {
        ${props => props.isOnSelecting && `
            color: ${props.theme.palette.link.hover};
            cursor: pointer;
        `}
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
        ${props => props.isOnSelecting && `
            font-size: 64px;
        `}
    }
    
`;

const ImageInfo = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 4px 16px;
    text-transform: uppercase;
    background-color: black;
    color: rgba(255, 255, 255, 0.7);
`;

export { Container, Image, ImageContainer, VideoContainer, Picture, VideoIcon, ImageInfo };