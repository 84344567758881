import styled from 'styled-components';

const StoriesContainer = styled.div`
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        margin-top: 40px;
    }
`;

const Share = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    padding: 0 18px;
    margin-top: 30px;
    font-family: Overpass;
    font-size: 16px;
    text-transform: none;
    letter-spacing: normal;
    font-weight: normal;
    color: #fff;
    background-color: ${props => props.theme.palette.mo.charcoal};
    border: solid 1px #000;

    i {
        margin-right: 8px;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        max-width: 300px;
    }

    &:hover {
        filter: invert(1);
        background-color: #000;
        border: solid 1px #fff;
    }
`;

export  { StoriesContainer, Share };