import styled from 'styled-components';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 115px;
    margin: auto;
    padding-top: ${props => props.loggedIn ? '12px' : '75px'};
    z-index: 1998;
    transition: opacity .4s ease, visibility .4s ease, margin-top .4s ease, top .6s ease, height .4s ease;

    opacity: ${props => props.isVisible ? '1' : '0'};
    visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
    margin-top: ${props => props.isVisible ? '60px' : '20px'};

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        margin-top: ${props => props.isVisible ? '0px' : '-40px'};
        padding-top: 75px;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.lg}){
        padding-left: calc(50% - 700px);
        padding-right: calc(50% - 700px);
       
    }    
`;

const Form = styled.div`
    padding: 6px;
    color: #fff;
    background-color: ${props => props.theme.palette.primary.main};
    box-shadow: 0px 0px 8px rgba(0,0,0,0.35);

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
`;

const Text = styled.div`
    padding: 6px;
    font-size: 13px;
    font-weight: 700;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 35%);

    a {
        color: #fff;
    }

    p {
        margin: 0;
    }
`;

const Button = styled.button`
    width: 100%;
    font-weight: 700;
    padding: 6px 9px;
    margin-top: 12px;
    color: #000;
    background-color: #fff;
    border: 1px;
    border-radius: 3px;
    box-shadow: 0 0 2px rgb(0 0 0 / 25%);
    text-shadow: 0 -1px 0 rgb(0 0 0 / 35%);

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        width: auto;
        margin-top: 0;
    }
`;

export { Container, Form, Text, Button };