import { Column } from './Column/Column'
import { Columns } from './MultiColumn.models'
import { Container, HeadContainer, SubHeadContainer, Heading, Description, ButtonContainerTop, ButtonContainerBottom, ColContainer, ContainerWrapper, StyledColumn } from './MultiColumn.style'
import { renderParagraph } from 'components/utils/render';
import { Button } from 'components/common';

export const MultiColumn = ({ heading, description, ctaButton, columns, backgroundColor, columnAlignment, moduleWidth }: Columns) => {
    const isCentredColumnLayout = columnAlignment === 'centre' && columns.length === 1;

    return (
        <ContainerWrapper noBackgroundColor={backgroundColor === 'default'} backgroundColor={backgroundColor}>
            <Container colCount={columns.length} moduleWidth={moduleWidth}>
                {heading &&
                    <HeadContainer>
                        <SubHeadContainer colCount={columns.length}>
                            <Heading colCount={columns.length} background={backgroundColor}>{heading}</Heading>
                            <Description colCount={columns.length}>{renderParagraph(description)}</Description>
                        </SubHeadContainer>
                        <ButtonContainerTop background={backgroundColor} colCount={columns.length} buttonType={ctaButton?.btnType}>{ctaButton?.btnText && <Button  {...ctaButton} />}</ButtonContainerTop>
                    </HeadContainer>
                }
                <ColContainer colCount={columns.length} isCentredColumnLayout={isCentredColumnLayout}>
                    {columns && columns.length > 0 &&
                        columns.map((column, index) => {
                            return (
                                <StyledColumn key={index} colCount={columns.length} backgroundColor={backgroundColor} isCentredColumnLayout={isCentredColumnLayout} moduleWidth={moduleWidth}>
                                    <Column {...column} hasHeading={heading !== ''}/>
                                </StyledColumn>
                            );
                        })}
                </ColContainer>
                {heading && ctaButton && <ButtonContainerBottom background={backgroundColor} colCount={columns.length} buttonType={ctaButton?.btnType}>{ctaButton?.btnText && <Button  {...ctaButton} />}</ButtonContainerBottom>}
            </Container>
        </ContainerWrapper>
    );
}
