import styled, { keyframes } from "styled-components";
import { breakpoints } from "themes/breakpoints.theme";

const caret = keyframes`

50% {
    border-color: transparent;
}
`;

const VideoContainer = styled.div`
  background-color: #e3e5e4;

  background: black;
  position: relative;
`;

const HeroVideo = styled.div`
  display: block;
  @media (min-width: ${breakpoints.sm}){
    min-height: 500px;
  }
`;



const VideoControl = styled.a`
  position: absolute;
  color: #001EFF;
  opacity: 1;
  text-decoration: none;
  z-index: 6;
  padding: 8px 15px;
  background: rgba(255, 255, 255, 0.4);
  margin: 24px 16px;
  right: 0;
  font-size: 16px;
  line-height: 25px;
  display: flex;
  cursor: pointer;
  img {
    margin-right: 4px;
  }
  :hover {
    background: rgba(255, 255, 255, 1);
  }
  @media (min-width: ${breakpoints.lg}) {
    margin: 32px 56px;
  }
`;

const Video = styled.video`
  min-height: 450px;
  width: 100%;
  position: relative;
  min-width: 100%;
  min-height: 100%;
  max-height: 70VH;
  object-fit: cover;
  display: block;
  @media (min-width: ${breakpoints.sm}){
    min-height: 500px;
    object-fit: cover;
  }
`;

const HeroCopy = styled.div`
  width: auto;
  text-align: left;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
  left: 0;
  bottom: 0;
  margin: 24px 16px;

  @media (min-width: ${breakpoints.md}) {
    margin: 24px 32px;
  }
  @media (min-width: ${breakpoints.lg}) {
    margin: 32px 56px;
  }
  div {
    @media (min-width: ${breakpoints.sm}) {
      flex: 1;
      max-width: 50%;
    }
    @media (min-width: ${breakpoints.md}) {
      flex: 1;
      max-width: 50%;
    }
  }
  h1 {
    font-size: 36px;
    line-height: 95%;
    letter-spacing: 0px;
    text-transform: uppercase;
    @media (min-width: ${breakpoints.lg}) {
      font-size: 50px;
    }
    margin-bottom: 9px;
  }
  p {
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0px;
    @media (min-width: ${breakpoints.lg}) {
      font-weight: 300;
      font-size: 18px;
    }
  }
`;
const Typewriter = styled.span`
  color: #fff;
  border-right: 0.05em solid;
  animation-name: ${caret};
  animation-duration: 1s;
  animation-timing-function: steps(1);
  animation-iteration-count: infinite;

  .HP-2020_Hero_Typing {
    display: none;
  }
  .HP-2020_Hero_Header span.active {
    display: inline;
  }
`;

const YoutubeVid = styled.div`
  display: block;
  max-height: 100%;
  iframe {
    display: block;
    max-width: 80vw;
  }
`;

export {
  VideoContainer,
  HeroVideo,
  VideoControl,
  Video,
  HeroCopy,
  Typewriter,
  YoutubeVid,
};
