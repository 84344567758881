import React, { useState, useEffect } from 'react';
import MoDialog from 'components/common/Dialog/Dialog';
import styled from 'styled-components';

const IframeContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
   iframe {
    width: 100%;
    height: 100%;
    border: none;
   }
`;

interface Props {
    sourceUrl: string;
    status3DModel: boolean;
    updadeStatus3DModel: () => void;
}

const Tcam3DModel: React.FC<Props> = ({ sourceUrl, status3DModel, updadeStatus3DModel }) => {
    const [open3DModel, setOpen3DModel] = useState(status3DModel);

    useEffect(() => {
      setTimeout(() => {
        // Set z-index to maximum for fullscreen iframe modal to cover everything.
        document.querySelectorAll('.MuiDialog-root').forEach((modalRoot) => {
          (modalRoot as HTMLElement).style.zIndex = '2147483647' // max z-index number;
        });

        // To handle actions from 3D Model Iframe
        // - Close 3D Modal
        // - Redirect to new URL on URL click
        window.addEventListener('message', event => {
          const eventData = event?.data;
          const eventDataName = eventData?.name;
          const eventDataUrl = eventData?.url;
          const eventOrigin = event?.origin;
          if (eventData === 'close3DModel' && sourceUrl.includes(eventOrigin)) {
            on3DModelClose();
          } 
          if (eventDataName === 'redirect' && eventDataUrl &&  sourceUrl.includes(eventOrigin)) {
            window.location.href = eventDataUrl;
          }
        });
      }, 100)
    }, []);


    const on3DModelClose = () => {
      // Set z-index of modal back to default.
      document.querySelectorAll('.MuiDialog-root').forEach((modalRoot) => {
        (modalRoot as HTMLElement).style.zIndex = '1300' // default z-index number;
      });
      setOpen3DModel(false);
      updadeStatus3DModel();
    }
  
    
    return (
      <MoDialog fullScreen open={open3DModel} onClose={() => on3DModelClose()} transitionDuration={500}>
        <IframeContainer>
          <iframe id='3DModelIframe' allow="web-share" src={sourceUrl} />
        </IframeContainer>
      </MoDialog>
    );
};

export default Tcam3DModel;