import React from 'react';
import { CardContainer, NewsImage, NewsContent, ContentTitle, ContentBody, ContentButton } from './MoCard.style';

const MoCard = ({imageUrl, title, body, reverse}) => {
    return (
        <CardContainer reverse={reverse}>
            <NewsImage>
                <img src={imageUrl} alt="" width="100%" height="100%"/>
            </NewsImage>
            <NewsContent>
                <ContentTitle>{title}</ContentTitle>
                <ContentBody>{body}</ContentBody>
                <ContentButton>{"Learn more"}</ContentButton>
            </NewsContent>
        </CardContainer>
    );
}
 
export default MoCard;