import { useState, useEffect, useContext } from 'react';
import { Container, Row, Col } from '../../themes/MoGrid';
import Info from './Info/Info';
import Body from './Body/Body';
import RelatedStories from './RelatedStories/RelatedStories';
import Gallery from './Gallery/Gallery';
import { getImage } from 'components/utils';
import { StoriesContainer, Share } from './Article.style';
import ShareSocials from 'components/common/ShareSocials/ShareSocials';
import MoDialog from 'components/common/Dialog/Dialog';
import ConfigContext from 'context/ConfigContext';

const Article = ({cms, components}) => {
    let config = useContext(ConfigContext);
    let country = config.getCountry();
    const [openShare, setOpenShare] = useState(false);
    const {id, gallery, publishedDate, author, title, content, quote, quotePosition, wordCount, tags, description, metadata} = components;
    const currentTag = (new URLSearchParams(window.location.search).get('tag')) ?
        new URLSearchParams(window.location.search).get('tag') : ((tags && tags.length > 0) ? tags[0].name : '');
    const [meta, setMeta] = useState({
      title: title,
      description: description,
      ogDescription: description,
      ogTitle: title,
      ogImage: gallery.length > 0 ? getImage(gallery[0], null, true) : null
    });
    
    useEffect(() => {
      //Set fallbacks based on metadata
      if(metadata){
        let newMeta = {
          title: metadata.metaTitle ? metadata.metaTitle : title,
          description: metadata.metaDescription ? metadata.metaDescription : description,
          ogDescription: metadata.ogDescription ? metadata.ogDescription : meta.title,
          ogTitle: metadata.ogTitle ? metadata.ogTitle : meta.description,
          ogImage: metadata ? getImage(metadata.ogImage, null, true): gallery.length > 0 && (
            <meta property="og:image" content={getImage(gallery[0], null, true)} />
          )
        }
        setMeta(newMeta);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
  
    function getAllStories() {
        let rawStories = (cms && cms.pages && cms.pages.stories && cms.pages.stories.components) ? cms.pages.stories.components : [];
        // Filter only Article objects & remove current story
        let filteredStories = rawStories.filter(story => {
          if (cms.components && story.components?.[0] !== id) {
            let item = cms.components[story.components[0]];
            return (item && typeof(item) === 'object' && item.type === 'Article');
          }
          return false;
        });
        return filteredStories.map(story => {
          let item = cms.components[story.components[0]];
          item.id = id;
          item.url = story.url;
          item.title = story.title;
          return item;
        });
    }
    
    function getRelatedStories(){
        let stories = [];
        let allStories = getAllStories();
        allStories.sort(function(a,b){
            if (a.publishedDate && b.publishedDate) {
                return new window.Date(b.publishedDate) - new window.Date(a.publishedDate);
            }
            return 0;
          });
        if(currentTag){
            stories = allStories.filter(story => {
                let includesTag = false;
                for(let i=0; i < story.tags.length; i++){
                    if((story.id !== id) && (story.tags[i].id === currentTag.toLowerCase())){
                        includesTag = true;
                        break;
                    }
                }
                return includesTag;
            }).slice(0,3);
        } else {
            stories = allStories.slice(0,3);
        }
        return stories;
    }

    return (
      <Container>
        <StoriesContainer>
          <Gallery {...{ cms, gallery }} />
          <Row>
            <Col xs={12} sm={8}>
              <Info {...{ country, publishedDate, author, title }} />
              <Body {...{ cms, content, quote, quotePosition, wordCount, tags }} />
              <Share
                data-dl-category="content"
                data-dl-location="stories"
                data-dl-label="share page modal"
                onClick={() => setOpenShare(true)}
              >
                <i className="fas fa-share-alt" />
                {cms.snippets['text-common-share'].text}
              </Share>
              <MoDialog open={openShare} onClose={()=> setOpenShare(false)}>
                <ShareSocials
                  onSelect={()=> setOpenShare(false)}
                  location="story"
                  shareUrl={window.location.href} 
                  getSocialContext={() => {
                    return {
                      title: title,
                      desc: title,
                      url: window.location.href
                    }
                  }}
                  />
              </MoDialog>
            </Col>
            <Col xs={12} sm={4}>
              {getRelatedStories().length > 0 && (
                <RelatedStories
                  {...{cms, country, currentTag}}
                  stories={getRelatedStories()}
                />
              )}
            </Col>
          </Row>
        </StoriesContainer>
      </Container>
    );
}
 
export default Article;