import { getUrlLocale } from 'components/utils';
import { SearchInput, SearchFormStyled, SearchIcon} from "./searchInput.style"

const SearchForm = () => {

    const {language} = getUrlLocale();
    
    return ( 
        <SearchFormStyled action={(language && language !== 'en') ? `/${language}/search/` : `/search/`} method="get">
            <SearchInput type="text" name="q" />
            <SearchIcon type="submit"/>
        </SearchFormStyled>
    );
};

export default SearchForm;