import styled from 'styled-components';

const Copy = styled.div`
    min-height: 330px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        height: 50vh;
    }
`;

const CopyContent = styled.div`
    max-width: 1366px;
    width: 100%;
  
   
    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

const CopyHeading = styled.div`
    z-index: 1;
`;

const CopyText = styled.div`
    max-width: 450px;
    z-index: 1;
`;

const CopyAnimateText = styled.div`
    font-family: Anton;
    font-size: 15vw;
    text-transform: uppercase;
    color: #e3e5e4;
    letter-spacing: 2px;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
`;


export { Copy, CopyContent, CopyHeading, CopyText, CopyAnimateText };