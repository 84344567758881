import { useState, useEffect } from 'react';
import Nav from './Nav/Nav';
import More from './More/More';
import { checkNested } from 'components/utils';
import Partners from './Partner/Partners';

const Footer = ({cms}) => {
    const [navigation, setNavigation] = useState({});
    const [socials, setSocials] = useState([]);
    const [partners, setPartners] = useState([]);
    const [copyright, setCopyright] = useState();
    const [acknowledgement, setAcknowledgement] = useState();
    const [more, setMore] = useState();

    useEffect(() => {
        let setNav = () => {
            let nav = {};
            if (cms.nav && cms.nav.siteNav) {
                for (let menu in cms.nav.siteNav) {
                    nav[menu] = {
                        heading: cms.nav.siteNav[menu].title,
                        items: []
                    };
                    if (cms.nav.siteNav[menu] && cms.nav.siteNav[menu].categories) {
                        cms.nav.siteNav[menu].categories.forEach(category => {
                            category.navItems.forEach(item => {
                                if (item.footer) {
                                    nav[menu].items.push(item);
                                }
                            })
                        });
                    }
                }
            }
            setNavigation(nav);
        }
        setNav();
        if(checkNested(cms, 'siteSettings', 'social')){
            setSocials(cms.siteSettings.social);
        }
        if(checkNested(cms, 'siteSettings', 'copyright')){
            setCopyright(cms.siteSettings.copyright);
        }

        if(checkNested(cms, 'siteSettings', 'acknowledgement')){
            setAcknowledgement(cms.siteSettings.acknowledgement);
        }
        if(checkNested(cms, 'nav', 'more')){
            setMore(cms.nav.more);
        }
        if(cms.partners) {
            setPartners(cms.partners);
        }
    }, [cms]);


    return ( 
        <footer>
            {(navigation && socials) && <Nav nav={navigation} socials={socials} />}
            { partners && <Partners partners={partners} />}
            {(copyright && more) && <More copyright={copyright} more={more} acknowledgement={acknowledgement}/>}
        </footer>
    );
}
 
export default Footer;
