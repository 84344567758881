import styled from 'styled-components';

const Wrapper = styled.div`
  font-size: 14px;
  opacity: 0.6;
  padding-bottom: 40px;
  background-color: rgba(0, 0, 0, 0.1);
`;

const QuickLinks = styled.div`
  display: block;
`;

const Link = styled.a`
  display: inline-block;
  line-height: 48px;
  color: #000;
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.palette.link.hover};
  }
`;

const Separator = styled.span`
  height: 14px;
  margin: 0 8px;
  border-left: solid 1px;
`;

export { Wrapper, QuickLinks, Link, Separator };