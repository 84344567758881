import styled from 'styled-components';

const ListContainer = styled.div`
    max-width: 100VW; 
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    background-color: rgb(227, 229, 228);
`;

const List = styled.ol`
    display: flex;
    justify-content: space-around;
    padding: 0;
`;

const ListItem = styled.li`
    height: 200px;
    counter-increment: target-item;
    list-style: none;
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 64px;

    &::before {
        content: counter(target-item);
        font-family: Anton;
        font-size: 160px;
        color: #fff;
        position: absolute;
        top: 0;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        height: 360px;
        &::before {
            font-size: 240px;
        }
    }
`;

const ItemContent = styled.div`
    z-index: 1;
    margin-left: 36px;
`;

const ItemHeading = styled.div`
    & > * {
        font-family: Anton;
        text-transform: uppercase;
        line-height: 0.95;
        font-size: 34px;

        @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
            font-size: 54px;
        }
    }
`;

const ItemLink = styled.a`
    font-size: 24px;
    cursor: pointer;

    & > span {
        border-bottom: solid 2px #001eff;
    }
    & > img {
        filter: brightness(0);
    }

    &:hover {
        color: #001eff;

        & > img {
            filter: none;
            animation-name: bounce;
            animation-duration: 0.5s;
            animation-fill-mode: both;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
        }
        @keyframes bounce {
            0%   { transform: translateX(0); }
            50%  { transform: translateX(10px); }
            100% { transform: translateX(0); }
        }
       
    }
`;

export { ListContainer, List, ListItem, ItemContent, ItemHeading, ItemLink };