import React, { useEffect } from 'react';
import styled from 'styled-components';
import { pushAnyClickEvent } from '../../../tracking/events';

const PearWrapper = styled.div`
    height: 310px;
    transition: all .5s ease-in-out; 
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
`;

const Svg = styled.svg<{hasAnimation?:boolean, hasScrollAnimation?:boolean, animateOnScroll?:boolean, hasLinkOrOnClick?: boolean}>`
    height: 100%;
    width: auto;
    display: block;
    transform-origin: 0% center;
    filter: drop-shadow(0 40px 10px rgba(0, 0, 0, .2));
    filter: ${props => props.hasScrollAnimation ? 'drop-shadow(0 12px 4px rgba(0, 0, 0, .2))' : 'drop-shadow(0 40px 10px rgba(0, 0, 0, .2))'};
    overflow: visible;

    ${props => props.hasAnimation && `
        animation: swing ease-in-out infinite alternate;
        animation-duration: 1s;
    `}

    ${props => props.animateOnScroll && `
        animation: scroll-swing ease 2s;
    `}

    use {
        stroke: white;
        stroke-width: 0;
        transition: .2s;
    }

    ${props => props.hasLinkOrOnClick && `
        a:hover {
            use {
                stroke-width: 22px;
            }
        }
    `}

@keyframes swing {
    0% {
        transform: skew(-4deg, -1deg);
        translate: 0 0;
    }
    100% {
        transform: skew(2deg, 0);
        translate: 5% 0;
    }
}


@keyframes scroll-swing {
    0% {
        rotate: -6deg;
    }
    10% {
        rotate: 6deg;
    }
    20% {
        rotate: -4deg;
    }
    30% {
        rotate: 4deg;
    }
    40% {
        rotate: -3deg;
    }
    50% {
        rotate: 3deg;
    }
    60% {
        rotate: -2deg;
    }
    70% {
        rotate: 2deg;
    }
    80% {
        rotate: 1deg;
    }
    90% {
        rotate: 1deg;
    }
    100% {
        rotate: 0; 
    }
}
`;


interface Props {
    onClick?: (e: React.MouseEvent) => void;
    hasAnimation?: boolean;
    hasScrollAnimation?: boolean;
    link?: string;
}

const TcamPear: React.FC<Props> = ({onClick, hasAnimation, hasScrollAnimation, link }) => {
    const [animateOnScroll, setAnimateOnScroll] = React.useState(false);

    useEffect(() => {
        if (hasScrollAnimation) {
            const handleScroll = () => {
                if(!animateOnScroll){
                    setAnimateOnScroll(true);
                    setTimeout(() => setAnimateOnScroll(false), 2000);
                }
            };
              
              window.addEventListener('scroll', handleScroll);
          
              return () => {
                window.removeEventListener('scroll', handleScroll);
              };
        }
       
      }, [animateOnScroll]);
      
    return (
        <PearWrapper>
            <Svg onClick={onClick}
                onClickCapture={()=> pushAnyClickEvent({linkUrl: link, linkText: `Interactive pear ${hasScrollAnimation ? 'floating' : ''} ${hasAnimation ? 'animated' : ''}`})}
                viewBox="0 0 980 1800"
                hasAnimation={hasAnimation}
                hasScrollAnimation={hasScrollAnimation}
                animateOnScroll={animateOnScroll}
                hasLinkOrOnClick={!!link || !!onClick} >
                <a href={link}>
                    <defs>
                        <clipPath id="pearClipPath">
                            <path id="pearLine" d="M367,6c18.76,13.31,26.37,28.54,35,57,15.87,52.31,5.82,124.02,34,163,62.44,.52,80.09,38.03,104,76,21.61,34.32,85.8,175.55,99,210,65.06,147.75,109.57,307.9,178,450,27.22,56.52,120.79,289.71,134,335,18.04,62.42,29.79,174.53,10,242-21.04,71.74-52.49,138.11-108,175-77.32,51.38-236.21,101.22-358,66-68.8-19.9-111.77-54.93-152-103-32.18,3.77-106.48,16.31-150,1-117.83-36.33-221.98-185.01-172-343,33.6-106.2,100.23-187.64,151-279,51.31-92.33,52.29-126,70-207,13.6-62.21,19.1-131.6,20-199,.7-17.92,7-188.68,7-217,3.44-75.3,13.16-146.75,60-182,13.88-10.44,29.24-13.92,43-19,7.96-29.32,5.73-123.54-4-151-3.62-10.22-11.79-22.63-18-36-4.28-9.21-8.03-16.69-4-27,7.48-16,20.19-12,21-12Z" />
                        </clipPath>
                    </defs>
                    <use href="#pearLine" x="0" fill="none" />
                    <image
                        clipPath="url(#pearClipPath)"
                        href="/resources/images/content/tcam/pear.jpeg"
                        width="980" height="1800" />
                </a>
            </Svg>
        </PearWrapper>
    );
};

export default TcamPear;