import styled from 'styled-components';
import { breakpoints } from 'themes/breakpoints.theme';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (min-width: ${breakpoints.sm}){
        flex-direction: row;
    }
`;

const LeftCol = styled.div`
    flex: 1;
    padding: 40px 53px;
    order: 2;

    @media screen and (min-width: ${breakpoints.sm}){
        display: flex;
    flex-direction: column;
    justify-content: center;
        padding: 54px 58px;
        order: 1;
        flex: 50%;
    }
    @media screen and (min-width: ${breakpoints.md}){
        padding: 72px 97px;
    }
    @media screen and (min-width: ${breakpoints.lg}){
        padding: 56px 70px;
    }
    p{
        font-family: Overpass;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 22.4px;
    letter-spacing: normal;
    color: #000000;
    position: relative;
    text-align: left;
    margin-top: 0;
    max-width:500px;
    @media screen and (min-width: ${breakpoints.md}){
        font-size: 18px;
        line-height: 25.2px;
    }
    @media screen and (min-width: ${breakpoints.lg}){
        font-size: 22px;
line-height: 140%;
    }
    }
`;
const RightCol = styled.div`
    flex: 1;
    position: relative;
    overflow: hidden;
    background-color: #c4c4c4;
    order: 1;
    min-height: 100VW;
    @media screen and (min-width: ${breakpoints.sm}){
        min-height: 50VW;
        order: 2;
        flex: 50%;
    }
    @media screen and (min-width: ${breakpoints.lg}){
        min-height: 40VW;
    }
    img {
        display: block;
    position: relative;
    width: 100%;
    height: 100VW;
    object-fit: cover;
    object-position: center;
    @media screen and (min-width: ${breakpoints.sm}){
        width: 100%;
    height: 100%;
    }
        
    }

`;

const Header = styled.div`
    font-family: Anton;
    font-size: 37px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0;
`;

const Cta = styled.a`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: Overpass;
    font-style: normal;
    line-height: 18px;
    transition: all 0.2s ease;
    text-decoration: none;
    background-color: ${props => props.theme.palette.primary.normal};
    color:  ${props => props.theme.palette.primary.text};
    height: 48px;
    min-width: 146px;
    padding: 12px;
    margin-right: auto;
    font-size: 16px;
    border-radius: 24px;

    margin-top: 24px;
    font-size: 16px;


    img {
        width: 16px;
        margin-left: 8px;
    }

    &:hover {
        background: ${props => props.theme.palette.primary.hover};
    }
`;

const Caption = styled.div`
    display: block;
    position: absolute;
    bottom: 0;
    padding: 16px;
    color: #fff;
    background: rgba(21, 21, 21, 0.4);
    width: 100%;
    font-family: Overpass;
    text-transform: uppercase;
    letter-spacing: 0.86px;
    line-height: 16px;
    font-weight: 200;
    font-size: 14px;
    strong{
        font-weight: 500 !important;
        display: block;
    }
`

export { Container, LeftCol, RightCol, Header, Cta, Caption };