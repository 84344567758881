import styled from "styled-components";

  const GetInvolvedContainer = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.xs}){
      display: flex;
      flex-wrap: wrap;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
      //flex-wrap: nowrap;
    }
  `;

  const GetInvolvedBlock = styled.a`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-position: center;
    background-size: cover;
    position: relative;
    overflow: hidden;
    flex : 1;
    text-decoration: none;
    background-image: url(${props => props.backgroundImage});

    &:before {
      content: "";
      position: absolute;
      inset: 0;
      background-color: #000;
      opacity: .2;
      transition: .4s;
    }

    &:hover:before {
      opacity: .6;
    }

    &:hover {
      img {
        opacity: .7;
        transform: scale(1.05);
      }
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.xs}){
      //flex-grow: 0;
      //flex-shrink: 0;
      flex-basis: 50%;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
      //flex-basis: 25%;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
      flex-basis: 0;
    }

    @media (max-width: ${props => props.theme.breakpoints && props.theme.breakpoints.md}){
      ${ (props) => { 
        if(props.forwardedRef && props.forwardedRef.current){
          let cardTop = props.forwardedRef.current.offsetTop
          let cardBottom =  props.forwardedRef.current.offsetHeight + cardTop
          if(props.forwardedRef.current.offsetTop > 0){
            const offset = 100
            if( cardTop < props.top + offset && cardBottom - offset > props.top){
              
              return `
              &:before {
                opacity: .6;
              }

              img {
                opacity: .7;
                transform: scale(1.05);
              }
              `
            }
          }
        }
       
        }
        }; 
      }
  `;

  const GetInvolvedLink = styled.div`

  `;

  const GetInvolvedCopy = styled.div`
    color: white;
    padding: 32px 32px 16px 32px;
    position: relative;

    p {
      font-family: 'Overpass', sans-serif;
      font-weight: 300;
      line-height: 28px;
      font-size: 20px;
      color: #FCFCFE;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
      padding: 48px 48px 16px 48px;
    }
  `;

  const GetInvolvedHeading = styled.h2`
    font-family: 'DAMN', 'Impact', sans-serif;
    font-weight: normal;
    font-size: 40px;
    font-weight: 400;
    line-height: 46px;
    line-height: .9;
    margin-bottom: 16px;
    text-transform: uppercase;
    margin-block-start: 0;
    color: #FCFCFE;


    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.xs}){
      font-size: 56px;
    }
  `;

  const GetInvolvedCta = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 48px;
    display: flex;

    a {
      flex: 1;
      border-radius: 24px;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.xs}){
      display: block;  

      a {
        height: 48px;
      }
      
      span {
        font-size: 16px;
        line-height: 26px;
        font-weight: 600;
        color: #161719;
        border-radius: 24px;
        font-family: 'Overpass', sans-serif;
      }
    }
  `;

  const GetInvolvedImage = styled.div`
    img {
      display: block;
      opacity: .5;
      width: 100%;
      background-size: cover;
      transition: .2s;
    }
  `;

  export {
    GetInvolvedContainer,
    GetInvolvedBlock,
    GetInvolvedLink,
    GetInvolvedCopy,
    GetInvolvedImage,
    GetInvolvedCta,
    GetInvolvedHeading,
  };
