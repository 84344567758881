import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    position: fixed;
    width: 100%;
    top: 0px;
    left: -100%;
`;

const Skip = styled.a`
    display: block;
    max-width: 92%;
    width: auto;
    position: absolute;
    white-space: nowrap;
    background: #FFF;
    left: 0;
    top: 0;
    padding: 8px 16px;
    font-size: 1.2em;
    border: 1px solid #DDD;
    box-shadow: 0 0 16px rgb(0 0 0 / 70%);
    -webkit-transition: opacity .4s ease, top .4s ease;
    transition: opacity .4s ease, top .4s ease;

    &:focus {
        opacity: 1;
        top: 16px;
        left: 104%;
    }
  
`;

const Accessibility = () => {

    function forceElementFocus(id){
        let ele = document.getElementById(id);
        const removeTabIndex = (ele) => {
            ele.removeAttribute('tabindex');
            ele.removeEventListener('blur focusout', removeTabIndex);
        }
        ele.setAttribute('tabindex', -1);
        ele.focus();
        ele.addEventListener('blur focusout', removeTabIndex(ele));
    }
    return (
        <React.Fragment>
            <Container>
                <Skip tabIndex="0" href="#header-nav" onClick={() => forceElementFocus("header-nav")}>Skip to Menu</Skip>
                <Skip tabIndex="0" href="#content-container" onClick={() => forceElementFocus("content-container")}>Skip to Content</Skip>
            </Container>
        </React.Fragment>
    );
}
 
export default Accessibility;