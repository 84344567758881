import styled from 'styled-components';

const Wrapper = styled.div`
    @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
        h1 {
            font-size: 60px;
        }
        h2 {
            font-size: 47px;
        }
        h3 {
            font-size: 37px;
        }
        h4 {
            font-size: 29px;
        }
        h5 {
            font-size: 24px;
        }
        h6 {
            font-size: 18px;
        }
    }
`;

export { Wrapper };