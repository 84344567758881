
import React, { useEffect } from 'react';

   //app configuration
window.jsWebAppConfig = {
    containerId: 'app-container',
    cacheBreak: '<%= Date.now().toString() %>',
    appPath: '/resources/scripts/impact/static/',
    assetPath: '/resources/scripts/impact/static/',
    analytics: ({
        action /* string */ ,
        category /* string */ ,
        value /* ?string */
    }) => {
        console.log(
            `ANALYTICS STUB: action: ${action}, category: ${category}${value !== undefined ? `, value: ${value}`:''}`
            )
    }
};

const ImpactHub = ({ cms }) => {
    
  useEffect(() => {

    // Create promise to detect script loaded
    const scriptPromise = new Promise((resolve, reject) => {
      const script = document.createElement('script');
      document.body.appendChild(script);
      script.onload = resolve;
      script.onerror = reject;
      script.async = true;
      script.src = "/resources/scripts/impact/app.326ec46018df1af1ed5b.js";
    });

    scriptPromise.then(() => {
      window.impactHubStartup();
    });
    
  }, []);

   return (
      <React.Fragment>
         <div id="app-container">

         </div>
      </React.Fragment>
   );
}
 
export default ImpactHub;
