import React from 'react';
import Grid from '@material-ui/core/Grid';

const Col = (props) => {
    const {children} = props
    return ( 
        <Grid item {...props}>
            {children}
        </Grid>
    );
}
 
export default Col;