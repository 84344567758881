import styled, {css} from 'styled-components';
import { checkNested } from 'components/utils';

const baseTextStyles = css`
  display: ${(props) => props.display};
  font-family: ${(props) => props.fontFamily};
  text-align: ${(props) => props.align};
  color: ${(props) => props.color || 'inherit'};
  font-weight: ${(props) => props.fontWeight};
  margin-top: ${(props) => props.marginTop};
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  margin-bottom: ${(props) => props.marginBottom || (props.tag === 'p' ? '18px' : 0)};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ${(props) =>
    props.truncate &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

const StyledText = styled.span`
  ${baseTextStyles}
  font-size: ${(props) =>
    (checkNested(props.theme, 'typography', 'text', props.level, 'fontSize') &&
      props.theme.typography.text[props.level].fontSize.xxs) ||
    ''};
  line-height: ${(props) =>
    (checkNested(props.theme, 'typography', 'text', props.level, 'lineHeight') &&
      props.theme.typography.text[props.level].lineHeight.xxs) ||
    ''};
  font-weight: ${(props) =>
    (checkNested(props.theme, 'typography', 'heading', props.level, 'fontWeight') &&
        props.theme.typography.heading[props.level].fontWeight.xxs) ||
    ''};
  @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.xs}) {
    font-size: ${(props) =>
      (checkNested(props.theme, 'typography', 'text', props.level, 'fontSize') &&
        props.theme.typography.text[props.level].fontSize.xs) ||
      ''};
    line-height: ${(props) =>
      (checkNested(props.theme, 'typography', 'text', props.level, 'lineHeight') &&
        props.theme.typography.text[props.level].lineHeight.xs) ||
      ''};
    font-weight: ${(props) =>
      (checkNested(props.theme, 'typography', 'heading', props.level, 'fontWeight') &&
          props.theme.typography.heading[props.level].fontWeight.xs) ||
      ''};
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.sm}) {
    font-size: ${(props) =>
      (checkNested(props.theme, 'typography', 'text', props.level, 'fontSize') &&
        props.theme.typography.text[props.level].fontSize.sm) ||
      ''};
    line-height: ${(props) =>
      (checkNested(props.theme, 'typography', 'text', props.level, 'lineHeight') &&
        props.theme.typography.text[props.level].lineHeight.sm) ||
      ''};
    font-weight: ${(props) =>
      (checkNested(props.theme, 'typography', 'heading', props.level, 'fontWeight') &&
          props.theme.typography.heading[props.level].fontWeight.sm) ||
      ''};
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.md}) {
    font-size: ${(props) =>
      (checkNested(props.theme, 'typography', 'text', props.level, 'fontSize') &&
        props.theme.typography.text[props.level].fontSize.md) ||
      ''};
    line-height: ${(props) =>
      (checkNested(props.theme, 'typography', 'text', props.level, 'lineHeight') &&
        props.theme.typography.text[props.level].lineHeight.md) ||
      ''};
    font-weight: ${(props) =>
      (checkNested(props.theme, 'typography', 'heading', props.level, 'fontWeight') &&
          props.theme.typography.heading[props.level].fontWeight.md) ||
      ''};
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.lg}) {
    font-size: ${(props) =>
      (checkNested(props.theme, 'typography', 'text', props.level, 'fontSize') &&
        props.theme.typography.text[props.level].fontSize.lg) ||
      ''};
    line-height: ${(props) =>
      (checkNested(props.theme, 'typography', 'text', props.level, 'lineHeight') &&
        props.theme.typography.text[props.level].lineHeight.lg) ||
      ''};
    font-weight: ${(props) =>
      (checkNested(props.theme, 'typography', 'heading', props.level, 'fontWeight') &&
          props.theme.typography.heading[props.level].fontWeight.lg) ||
      ''};
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.xl}) {
    font-size: ${(props) =>
      (checkNested(props.theme, 'typography', 'text', props.level, 'fontSize') &&
        props.theme.typography.text[props.level].fontSize.xl) ||
      ''};
    line-height: ${(props) =>
      (checkNested(props.theme, 'typography', 'text', props.level, 'lineHeight') &&
        props.theme.typography.text[props.level].lineHeight.xl) ||
      ''};
    font-weight: ${(props) =>
      (checkNested(props.theme, 'typography', 'heading', props.level, 'fontWeight') &&
          props.theme.typography.heading[props.level].fontWeight.xl) ||
      ''};
  }
  &&& {
    font-size: ${(props) => props.size};
  }
`;

export default StyledText;