import { getUserCookieName, getCookie } from '../utils/helper'

const getDataLayer = () => {
    if(!window.dataLayer){
        window.dataLayer = [];
    }

    const loggedInUser = getLoggedInUserInfo();
    if(loggedInUser && !window.dataLayer.some((data: any)=> (typeof data === 'object' && data?.user))){
        // Add user info to data layer if not already present in the data layer
        window.dataLayer.push(loggedInUser);
    }

    return window.dataLayer;
}


export const pushPageViewEvent = (eventData?: { currentUri?: string, pageTitle?: string, lastUri?: string }) => {
    const dataLayer = getDataLayer();
    const pageTitle = eventData?.pageTitle || document.title;
    const currentUri = eventData?.currentUri || window.location.href;
    const lastUri = eventData?.lastUri || document.referrer || window.history.state?.previousUrl;
    
    const pushEvent = {
        event: 'page_view',
        current_uri: currentUri, 
        last_uri: lastUri, 
        page_title: pageTitle,
    };

    dataLayer.push(pushEvent);
}

export const pushAccordionEvent = (eventData: { id: string, name: string, currentUri?: string, action: "open" | "close" }) => {
    const dataLayer = getDataLayer();
    const currentUri = eventData?.currentUri || window.location.href;
    const id = eventData.id;
    const name = eventData.name;
    const type = eventData.action;

    const pushEvent = {
        event: 'click_accordion',
        location: currentUri,
        id: id,
        name: name,
        type: type,
    }

    dataLayer.push(pushEvent);
}

export const pushCarouselEvent = (eventData: { index: number, id: string, name:string, currentUri?: string, action: "forward" | "back"  }) => {
    const dataLayer = getDataLayer();
    const currentUri = eventData?.currentUri || window.location.href;
    const id = eventData.id;
    const name = eventData.name;
    const type = eventData.action;
    const index = eventData.index + 1; // turn the index from 0 to 1

    const pushEvent = {
        event: 'click_carousel',
        location: currentUri,
        id: id,
        index: index,
        name: name,
        type: type,
    }

    dataLayer.push(pushEvent);
}

export const pushStartFormEvent = (eventData: { formName: string, stepLabel?: string, stepNumber?: number, currentUri?: string }) => {
    const dataLayer = getDataLayer();
    const currentUri = eventData?.currentUri || window.location.href;
    const formName = eventData.formName || document.title;
    const formId = formName;
    const stepLabel = eventData.stepLabel || 'Start';
    const stepNumber = eventData.stepNumber || 1;

    const pushEvent = {
        event: 'start_form',
        form_id: formId,
        form_name: formName,
        location: currentUri,
        step_label: stepLabel,
        step_number: stepNumber,
    }

    dataLayer.push(pushEvent);
}

export const pushEnterFieldFormEvent = (eventData: { formName: string, fieldId: string, stepLabel?: string, stepNumber?: number, currentUri?: string }) => {
    const dataLayer = getDataLayer();
    const currentUri = eventData?.currentUri || window.location.href;
    const formName = eventData.formName || document.title;
    const fieldId = eventData.fieldId;
    const formId = formName;
    const stepLabel = eventData.stepLabel || 'Start';
    const stepNumber = eventData.stepNumber || 1;

    const pushEvent = {
        event: 'enter_field_form',
        form_id: formId,
        field_id: fieldId,
        form_name: formName,
        location: currentUri,
        step_label: stepLabel,
        step_number: stepNumber,
    }
    
    dataLayer.push(pushEvent);
}

export const pushFormSubmitEvent = (eventData: { formName: string, submitText: string, stepLabel?: string, stepNumber?: number, currentUri?: string }) => {
    const dataLayer = getDataLayer();
    const currentUri = eventData?.currentUri || window.location.href;
    const formName = eventData.formName || document.title;
    const formId = formName;
    const answerText = eventData.submitText ;
    const stepLabel = eventData.stepLabel || 'Start';
    const stepNumber = eventData.stepNumber || 1;

    const pushEvent = {
        event: 'submit_form',
        form_id: formId,
        form_name: formName,
        location: currentUri,
        step_label: stepLabel,
        step_number: stepNumber,
        answer_text: answerText,
    }

    dataLayer.push(pushEvent);
}

export const pushClickEvent = (eventData: { linkText: string, linkUrl?: string, currentUri?: string }) => {
    const dataLayer = getDataLayer();
    const currentUri = eventData?.currentUri || window.location.href;
    const linkText = eventData.linkText;
    const linkUrl = eventData.linkUrl;

    const pushEvent = {
        event: 'click',
        link_text: linkText,
        link_url: linkUrl,
        location: currentUri,
    }

    dataLayer.push(pushEvent);
}

export  const pushClickCtaEvent = (eventData: { linkText: string, linkUrl?: string, currentUri?: string }) => {
    const dataLayer = getDataLayer();
    const currentUri = eventData?.currentUri || window.location.href;
    const linkText = eventData.linkText;
    const linkUrl = eventData.linkUrl;

    const pushEvent = {
        event: 'click_cta',
        link_text: linkText,
        link_url: linkUrl,
        location: currentUri,
    }

    dataLayer.push(pushEvent);
}

export const pushAnyClickEvent = (eventData: { linkText: string, linkUrl?: string, currentUri?: string }) => {
    // for the moment we only track events that have a link url
    if(eventData.linkUrl){
        const [ isExternalLink, fullLinkUrl ] = getParsedUrlInfo(eventData.linkUrl);
        
        if(isExternalLink){
            pushClickEvent(eventData);
        }else{
            //Update internal links with full link url
            eventData.linkUrl = fullLinkUrl;
            pushClickCtaEvent(eventData);
        }
    }
}

const getParsedUrlInfo = (url: string): [boolean, string] => {
    const parsedUrl = new URL(url, window.location.origin);
    const fullLinkUrl = parsedUrl.href;
    // Compare the parsed hostname with the current location's hostname, if they don't match, it's an external link
    const isExternalLink =  parsedUrl.hostname !== window.location.hostname;

    return [isExternalLink, fullLinkUrl];
}

const getLoggedInUserInfo = () => {
    // Get user info from cookie
    let userInfo = null;
    const memberId = getCookie(getUserCookieName('id'));
    if(memberId){
        userInfo = {
            user : {
                memberId: memberId
            }
        }
    }
    
    return userInfo;
}