import styled from 'styled-components';

const Container = styled.div`
`;

const ReadTime = styled.div`
    opacity: .7;
`;

const Content = styled.div`
    font-family: Overpass;
    font-weight: 300;
    text-align: left;
    margin: 24px 0;
`;

const Tag = styled.div`
    a {
        display: inline-block;
        padding: 10px 15px;
        margin: 10px 10px 0 0;
        background-color: ${props => props.theme.palette.link.normal};;
        border-radius: 25px;
        color: #fff;
        font-size: 14px;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 1px;
        white-space: nowrap;
        cursor: pointer;
        
        &:hover, &:active, &:focus {
            background-color: ${props => props.theme.palette.link.hover};;
        }
    }
`;

export { Container, ReadTime, Content, Tag };