import styled from "styled-components";

const Container = styled.div`
    padding: 0 12px;
`;

const Amount = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-bottom: 8px;
    line-height: 1;
`;

const Raised = styled.div`
    font-family: Anton;
    font-size: 50px;
    font-weight: 400;
    color: ${props => props.theme.palette.mo.charcoal};
`;

const Target = styled.div`
    opacity: .5;
`;

const ProgressBar = styled.div`
    border: solid 2px #000;
    height: 20px;
    background: #FFF;
`;

const InnerBar = styled.div<{width: number}>`
    width: ${props => props.width ? props.width + '%' : '0%'};
    max-width: 100%;
    height: 100%;
    background: #000;
    transition: width 5s ease;
`;


export { Container, Amount, Raised, Target, ProgressBar, InnerBar };