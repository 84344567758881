import { useEffect, useState } from 'react';
import { Row, Col } from 'themes/MoGrid';
import { PartnerRow, TitleUnderline, LogoContainer } from './Partners.styles';
import { useInterval } from 'components/utils';
import { PartnerCategory } from './Partners.models'
import PartnerLogo from './PartnerLogo';

function PartnersRow({ items, title, partnerRenderConfig }: PartnerCategory) {
  const { displayLogoCount } = partnerRenderConfig;
  const [activeLogoIndexes, setActiveLogoIndexes] = useState<number[]>([]);
  const renderingLogoCount = items.length >= displayLogoCount? displayLogoCount : items.length;

  useEffect(() => {
    // create indexes for logos
    const initializingArray = Array.from(Array(displayLogoCount).keys())
    setActiveLogoIndexes(initializingArray);
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useInterval(() => {
    // Only run randomization if there more logos than the dislay count
    if (items.length > displayLogoCount) {
      randomPicker(items.length);
    }
  }, 3000);


  // [1,2,3,4,5,6] - actual items
  // [1,2,3,4] - display count
  // [6,4,2,1] - random order
  function randomPicker(partnerCount: Number) {
    //Elemnts indexes
    const indexes = Array.from(Array(partnerCount).keys())
    //indexes of logos which is not visible
    const raminingIndexes = indexes.filter((element) => !activeLogoIndexes.includes(element));
    //get random item from array
    const randomItemId = Math.floor(Math.random() * raminingIndexes.length);
    // select random position to update in view
    const randomPositionInvisibleItems = Math.floor(Math.random() * activeLogoIndexes.length);
    activeLogoIndexes[randomPositionInvisibleItems] = raminingIndexes[randomItemId];

    setActiveLogoIndexes([...activeLogoIndexes]);
  }

  // will render the all the logos in one container but only one acive item is visible
  return (
    <PartnerRow>
      <TitleUnderline>{title}</TitleUnderline>

      <Row>
         {[...Array(renderingLogoCount)].map((ele, index) =>
          <Col sm={3} xs={6} key={index}>
            <LogoContainer>
              {items.map((item, i) => {
                let isCurrentLogoIsActive = false;
                if (items.length <= displayLogoCount) {
                  isCurrentLogoIsActive = i === index;
                } else {
                  isCurrentLogoIsActive = i === activeLogoIndexes[index];
                }

                return (<PartnerLogo key={item.id} {...item} isActive={isCurrentLogoIsActive} />);
              }
              )}
            </LogoContainer>
          </Col>
        )}
      </Row>
    </PartnerRow>);
}

export default PartnersRow