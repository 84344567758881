import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F7EBEB;
    color: #B13B38;
    padding: 2rem;
    font-weight: bold;
    font-size: 24px;
`;

const Icon = styled.img`
    margin-right: 1rem;
    width: 50px;
`;

const ComponentNotFound = (props) => {
    return ( 
        <Container>
            <Icon src="/resources/images/icons/not-found.svg" data-aos="zoom-in" data-aos-delay="500" />
            <span data-aos="fade-left">{"Component " + props.componentId + " is not found."}</span>
        </Container>  
    );
}

export default ComponentNotFound;
