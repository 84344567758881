import styled from 'styled-components';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
    background-color: rgba(0,0,0,0.6);
    box-shadow: 0px 0px 8px rgba(0,0,0,0.35);
    height: 115px;
    padding-top: 75px;
    z-index: 1997;
    transition: opacity .4s ease, visibility .4s ease, margin-top .4s ease, top .6s ease, height .4s ease;

    opacity: ${props => props.isVisible ? '1' : '0'};
    visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
    margin-top: ${props => props.isVisible ? '60px' : '20px'};

    @media screen and (min-width: ${props => props.theme.breakpoints && props.theme.breakpoints.sm}){
        margin-top: ${props => props.isVisible ? '0px' : '-40px'};
    }
`;

const Bar = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    line-height: 1.5;
`;

const Language = styled.a`
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 4px;
    padding: 0 8px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    background-color: ${props => props.isSelected ? 'rgba(193,194,195,0.55)' : 'transparent'};
    transition: background .3s ease;
    cursor: pointer;

    &:hover {
        background-color: rgba(193,194,195,0.55);
    }
`;

const Close = styled.a`
    position: absolute;
    right: 8px;
    width: 50px;
    height: 100%;
    background-image: url(/resources/images/icons/close.svg);
    background-position: center;
    background-repeat: no-repeat;
    filter: invert(1);
    cursor: pointer;
`;

export { Container, Bar, Language, Close };
