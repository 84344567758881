import React from 'react';
import { Container } from 'themes/MoGrid';
import { Wrapper, QuickLinks, Link, Separator } from './More.style';
import { pushAnyClickEvent } from '../../tracking/events';

const More = ({copyright, more, acknowledgement}) => {
    return (
        <Wrapper>
            <Container>
                <div>{copyright}</div>
                {acknowledgement && <div>{acknowledgement}</div>}
                <QuickLinks>
                    {(typeof more === 'object') && Object.keys(more).map((item, index) =>
                        <React.Fragment key={index}>
                            {index !== 0 && <Separator/>}
                            <Link href={more[item].link} onClickCapture={() => pushAnyClickEvent({linkUrl: more[item].link, linkText: more[item].text})}>{more[item].text}</Link>
                        </React.Fragment>
                    )}
                </QuickLinks>
            </Container>
        </Wrapper>
    );
}
 
export default More;
