import styled from 'styled-components';

const MessageContainer = styled.div`
    padding: 4rem 2rem;
    background-color: #e3e5e4;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const MessageMinor = styled.div`
    font-family: Overpass;
    font-size: 14.2px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #001eff;
    margin-bottom: 16px;
`;

const MessageMajor = styled.div`
    font-family: Anton;
    font-size: 68.1px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: -1.5px;
    text-align: center;
    color: #000000;
    margin: auto;
    padding-top: 0;
    max-width: 560px;

    @media screen and (max-width: 480px) {
      font-size: 40px;
    }
`;


export { MessageContainer, MessageMinor, MessageMajor };