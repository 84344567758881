/**
* @param modalsCms: Cms translation for modals
* @param socialList: Array of types of social applications to share in the list
* @param shareUrl: Url to share (required in copy URL)
* @param location: the page this component get added
* @param entityType: edit url in member or team page
* @param viewState: view state of user (private or public)
* @param onSelect: trigger onSelect function in parent component
* @param getSocialContext: get social share data
*/

import React, { useState } from 'react';
import { Title, UrlContainer, UrlContent, UrlDisplay, UrlEdit, EditIcon, EditInput, EditContent, CharacterLeft, Actions, Error, EditButtons, EditButton, ButtonContainer, SocialListContainer, Button, SocialIcon } from "./ShareSocials.style";
import { shareModal, copyToClipBoard } from 'components/utils';

const ShareSocials = ({modalsCms, socialList, shareUrl, location, entityType, viewState, onSelect, getSocialContext }) => {
    const [ editingUrl, setEditingUrl ] = useState(false);
    const [ urlPostfix, setUrlPostfix ] = useState(shareUrl.substring(shareUrl.lastIndexOf('/')+1) || '');
    const [ initUrlPostfix ] = useState(urlPostfix);
    const maxUrlPostfixLength = 32;
    const [ char, setChar] = useState(Math.max(0, maxUrlPostfixLength - urlPostfix.length));
    const [ showError, setShowError ] = useState(false);

    const defaultSocialList = [
        {
            type: "copyUrl",
            iconClass: "fas fa-clone",
            translation: modalsCms.share.options.url
        },
        {
            type: "whatsapp",
            iconClass: "fab fa-whatsapp",
            translation: modalsCms.share.options.whatsapp
        },
        {
            type: "facebook",
            iconClass: "fab fa-facebook-f",
            translation: modalsCms.share.options.facebook
        },
        {
            type: "twitter",
            iconClass: "fab fa-twitter",
            translation: modalsCms.share.options.twitter
        },
        {
            type: "linkedin",
            iconClass: "fab fa-linkedin-in",
            translation: modalsCms.share.options.linkedin
        },
        {
            type: "mail",
            iconClass: "fas fa-envelope",
            translation: modalsCms.share.options.email
        },
    ].filter(a=>a);

    let optionList = socialList ? defaultSocialList.filter(i => socialList.includes(i.type)) : defaultSocialList;
    const thisShareModal = socialNetwork => {
        const { title, desc, url } = getSocialContext(socialNetwork);
        shareModal(socialNetwork, url, desc, title);
    }
    
    function thisCopyToClipBoard() {
        let stringToCopy;
        if (getSocialContext && !(entityType === 'team' || entityType === 'member')) {
            stringToCopy = getSocialContext('copyUrl').url;
        } else {
            stringToCopy = `${shareUrl.substring(0, shareUrl.lastIndexOf('/')+1)}${urlPostfix}?mc=1`;
        }
        copyToClipBoard(stringToCopy);
    }

    function getDataAnalyticAttributes(shareType) {
        switch (location){
            case "mospace":
                return {
                    "data-dl-category": "mo space",
                    "data-dl-location": "hero area",
                    "data-dl-label": "Share Mospace On " + shareType
                };
            case "events":
                return {
                    "data-dl-category": "events",
                    "data-dl-location": "hero area",
                    "data-dl-label": "Share Event On " + shareType
                };
            case "donate":
                return {
                    "data-dl-category": "donate",
                    "data-dl-location": "thank you",
                    "data-dl-label": "Share Donation On " + shareType
                };
            case "story":
                return {
                    "data-dl-category": "content",
                    "data-dl-location": "stories",
                    "data-dl-label": "Share Story On " + shareType
                };
            default:
                return null;
        }
    }

    function onChangeUrl(e){
        let value = e.target.value;
        let urlPostfixLength = value.length;
        if(urlPostfixLength <= maxUrlPostfixLength){
            setChar(Math.max(0, maxUrlPostfixLength - urlPostfixLength));
            setUrlPostfix(value);
            setShowError(false);
        }
      
    }

    function onClickSave(){}

    function onClickCancel(){
        setUrlPostfix(initUrlPostfix);
        setChar(Math.max(0, maxUrlPostfixLength - initUrlPostfix.length));
        setEditingUrl(false);
        setShowError(false);
    }

    function getDynamicStyle(which){
        const hide = { style:{display:"none"}},
        error = { style:{border: 'solid 1px #FF0000',  backgroundColor: 'rgba(0, 0, 0, 0.1)'}};
        switch(which){
            case 'display':
                return editingUrl ? hide : null;
            case 'edit':
                return !editingUrl ? hide : null;
            case 'icon':
                return (viewState === 'private' && (entityType === 'team' || entityType === 'member')) ? null : hide;
            case 'error':
                return !showError ? hide : null;
            case 'input':
                return showError ? error : null;
            default:
                return;
        }
    }

    function onClickSocial(type){
        switch (type) {
            case "copyUrl":
                thisCopyToClipBoard();
                break;
            case "whatsapp":
                thisShareModal('whatsapp');
                break;
            case "facebook":
                thisShareModal('facebook');
                break;
            case "twitter":
                thisShareModal('twitter');
                break;
            case "linkedin":
                thisShareModal('linkedin');
                break;
            case "mail":
                thisShareModal('email');
                break;
            default:
                return;
        }
        onSelect();
    }

    return ( 
        <SocialListContainer>
            <Title>{modalsCms.share.titleStory}</Title>
            <UrlContainer>
                <UrlDisplay {...getDynamicStyle('display')}>
                    <UrlContent>{shareUrl.substring(0, shareUrl.lastIndexOf('/')+1) + urlPostfix}</UrlContent>
                    <EditIcon onClick={() => setEditingUrl(true)}
                        {...getDynamicStyle('icon')}
                        alt-text="Edit Profile URL"
                        data-dl-category="mo space"
                        data-dl-location="hero area"
                        data-dl-label="edit profile url"
                        data-test="share-url-editLink">
                        <img src="/assets/images/icons/pen.png" alt="edit-url"/>
                    </EditIcon>
                </UrlDisplay>
                <UrlEdit {...getDynamicStyle('edit')}>
                    <EditContent>
                        <UrlContent>{shareUrl.substring(0, shareUrl.lastIndexOf('/')+1)}</UrlContent>
                        <EditInput value={urlPostfix} onChange={onChangeUrl} {...getDynamicStyle('input')}/>
                    </EditContent>
                    <Actions>
                        <CharacterLeft>
                        {char}{" "}{"text-mospace-member-title-character-left"}
                        </CharacterLeft>
                        <Error {...getDynamicStyle('error')}
                        dangerouslySetInnerHTML={{__html:"text-error-member-personalurl-notvalid"}}/>
                        <EditButtons>
                            <EditButton isPrimary={true} onClick={onClickSave} {...(initUrlPostfix === urlPostfix) && {disabled:true}}>
                            {"text-mospace-member-title-save"}
                            </EditButton>
                            <EditButton onClick={onClickCancel}>
                            {"text-mospace-member-title-cancel"}
                            </EditButton>
                        </EditButtons>
                    </Actions>
                </UrlEdit>
            </UrlContainer>
            { 
                optionList.map((social, index) => (
                    <ButtonContainer key={index}>
                        <Button {...getDataAnalyticAttributes(social.type)}
                                onClick={() => onClickSocial(social.type)}
                                marginTop={(index===0) ? "8px": null} marginBottom={(index===0) ? "16px": null}>
                            <SocialIcon className={social.iconClass}></SocialIcon>
                            {social.translation}
                        </Button>
                    </ButtonContainer>
                ))
            }
        </SocialListContainer>
    );
}
 
export default ShareSocials;
