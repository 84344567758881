import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { getApiUrl, getCurrentCountryCode } from 'components/utils/helper';


interface Props {
    htmlCode: string
}

const HtmlBlock = ({ htmlCode } : Props) => {
    const [code , setCode] = useState(htmlCode);
    const [hasTableau, setHasTableau] = useState(false);
    const tableauKeywordInCode = 'tableau_token_replace';

    useEffect(() => {
        if (code?.includes(tableauKeywordInCode)) {
            setHasTableau(true);
            // Retrieve Tableau token
            const countryCode = getCurrentCountryCode();
            let url = getApiUrl('/service-token?tokens=tableau', countryCode);
            const fetchToken = async () => {
                try {
                    const response = await fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({})
                    });

                    const data = await response.json();

                    if (data?.tokens?.tableau?.success) {
                        const token = data.tokens.tableau.token;
                        if (token) {
                            setCode(code.replace(tableauKeywordInCode, token));
                        }
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            };

            fetchToken();
        }

    }, []);

    return (
        <div data-component="html-block">
             {hasTableau &&
                <Helmet>
                    <script key="tableau-script" type="module" src="https://public.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js"></script>
                </Helmet>
            }
            <div dangerouslySetInnerHTML={{ __html: code }} />
        </div>
    );
};

export default HtmlBlock;
