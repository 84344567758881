import styled from "styled-components";

const Overlay = styled.div`
    position: fixed;
    inset: 0;
    z-index: 2000;
    background-color: rgba(0,0,0,0.85);
    backdrop-filter: blur(10px);

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 75px;
`;

const Container = styled.div`
`;

const Close = styled.div`
    position: absolute;
    top: 24px;
    right: 24px;
    width: 24px;
    height: 24px;
    background-image: url(/resources/images/icons/close.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: invert(1);
`;

interface Props {
    content: React.ReactNode;
    onClick: React.MouseEventHandler<HTMLElement>;
}

const MoOverlay: React.FC<Props> = ({content, onClick}) => {

    return (
        <Overlay>
            <Close onClick={onClick}/>
            <Container>
                {content}
            </Container>
        </Overlay>
    );
}

export default MoOverlay;