import React, { useState } from 'react';
import { Button } from 'components/common';
import { renderParagraph, renderImage } from 'components/utils/render';
import { formatVideoUrl } from 'components/utils';
import PlayFillIcon from 'remixicon-react/PlayFillIcon';
import { 
    Container, 
    Media, 
    Image, 
    Video, 
    Play, 
    VideoContainer, 
    Content, 
    Title, 
    Description, 
    SubHeading, 
    ButtonContainerTop, 
    ButtonContainerBottom,
    ContentContainer,
    MediaContainer,
    BackgroundVideoContainerMobile,
    BackgroundVideoContainerDesktop,
    BackgroundImageDesktop,
    BackgroundImageMobile, 
} from './HeroBanner.style';

interface Props {
    title: string;
    description: string;
    subHeading?: string;
    ctaButton: any;
    isVideo: boolean;
    image: string;
    imageAlignment: string;
    video: any;
    background?: string;
    backgroundVideoMobile?: string;
    backgroundVideoDesktop?: string;
    fallbackImageMobile?: string;
    fallbackImageDesktop?: string;
}


const HeroBanner: React.FC<Props> = ({ 
    title, 
    description, 
    subHeading, 
    ctaButton, 
    isVideo = false, 
    image, 
    video, 
    background = 'light', 
    imageAlignment = 'default',
    backgroundVideoMobile,
    backgroundVideoDesktop,
    fallbackImageMobile,
    fallbackImageDesktop }) => {
    
    const [playVideo, setPlayVideo] = useState(false);
    const [isMobileBackgroundVideoLoaded, setMobileIsBackgroundVideoLoaded] = useState(false);
    const [isDesktopBackgroundVideoLoaded, setDesktopBackgroundVideoLoaded] = useState(false);

    let media = (
        <Media backgroundColor={background} isVideo={isVideo} imageAlignment={imageAlignment}>
            { isVideo ?
                <Video backgroundColor={background} onClick={() => setPlayVideo(!playVideo)}>
                    {
                        playVideo ?
                        <VideoContainer src={formatVideoUrl(video.videoURL)} allow='autoplay'/> :
                        <React.Fragment>
                            {renderImage(video)}
                            <Play backgroundColor={background}><PlayFillIcon /></Play>
                        </React.Fragment>
                    }
                </Video> :
                <Image  backgroundColor={background} imageAlignment={imageAlignment}>{renderImage(image)}</Image>
            }
            {ctaButton && ctaButton.btnText && <ButtonContainerTop backgroundColor={background} isVideo={isVideo} imageAlignment={imageAlignment}><Button {...ctaButton} /></ButtonContainerTop>}
        </Media>
    );

    let content = (
    <Content backgroundColor={background} isVideo={isVideo} hasMedia={!!image || !!video}>
        {background !== 'light' && subHeading && <SubHeading backgroundColor={background} isVideo={isVideo} hasMedia={!!image || !!video}>{subHeading}</SubHeading>}
        <Title backgroundColor={background} isVideo={isVideo} hasMedia={!!image || !!video}>{title}</Title>
        <Description backgroundColor={background} isVideo={isVideo} hasMedia={!!image || !!video}>{renderParagraph(description)}</Description>
        {ctaButton && ctaButton.btnText && <ButtonContainerBottom backgroundColor={background} isVideo={isVideo} imageAlignment={imageAlignment}><Button {...ctaButton} /></ButtonContainerBottom>}
    </Content>
    );

    if(background !== 'light'){
        content = (
        <ContentContainer backgroundColor={background} isVideo={isVideo} hasMedia={!!image || !!video}>
                {content}
        </ContentContainer>
        );

        media = (
        <MediaContainer backgroundColor={background} isVideo={isVideo} imageAlignment={imageAlignment}>
            {media}
        </MediaContainer>
        );
    }

    return (
       <Container backgroundColor={background} isVideo={isVideo} hasMedia={!!image || !!video}>
            {(image || video) && media}
            {content}
            {background === "campaign" && 
            <>
                <BackgroundImageDesktop isBackgroundVideoLoaded={isDesktopBackgroundVideoLoaded}>
                    {renderImage(fallbackImageDesktop)}     
                </BackgroundImageDesktop>
                <BackgroundImageMobile isBackgroundVideoLoaded={isMobileBackgroundVideoLoaded}>    
                    {renderImage(fallbackImageMobile)} 
                </BackgroundImageMobile>
                {backgroundVideoMobile && 
                <BackgroundVideoContainerMobile>
                    <video loop autoPlay muted onLoadedData={()=>setMobileIsBackgroundVideoLoaded(true)}>
                        <source src={backgroundVideoMobile} type="video/mp4" />
                    </video>
                </BackgroundVideoContainerMobile>
                }
                {backgroundVideoDesktop && 
                <BackgroundVideoContainerDesktop>
                    <video loop autoPlay muted onLoadedData={()=>setDesktopBackgroundVideoLoaded(true)}>
                        <source src={backgroundVideoDesktop} type="video/mp4" />
                    </video>
                </BackgroundVideoContainerDesktop>
                }
            </>}
       </Container>
    );
};

export default HeroBanner;