import React, { useState, useEffect } from 'react';
import { checkNested, getCookie, setCookie, renderParagraph } from 'components/utils';
import { Container, Form, Text, Button } from './CookieBar.style';

const CookieBar = ({cms, loggedIn}) => {
    const [isActive, setIsActive] = useState(false);
    const [bannerContent, setBannerContent] = useState();
    const [buttonText, setButtonText] = useState();
    const cookieName = 'cookies_notified'

    useEffect(() => {
        let cookiesNotified = getCookie(cookieName);
        if (cookiesNotified !== cookieName && checkNested(cms, 'siteSettings', 'cookieBanner')){
            let {cookieBannerBlock, cta } = cms.siteSettings.cookieBanner;
            setBannerContent(cookieBannerBlock);
            setButtonText(cta);
            setIsActive(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onAcceptCookie(){
        setCookie(cookieName, cookieName, 365);
        setIsActive(false);
    }

    return (
      <Container isVisible={isActive} loggedIn={loggedIn}>
          <Form>
              { bannerContent && <Text>{renderParagraph(bannerContent)}</Text>}
              <Button onClick={() => onAcceptCookie()}>
                  {buttonText}
              </Button>
          </Form>
      </Container>
    );
}
 
export default CookieBar;