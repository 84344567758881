import {useState, useEffect, useContext, createContext } from 'react';
import { useLocation } from 'react-router-dom';

const PreviousUrlContext = createContext();

export const PreviousUrlProvider = ({ children }) => {
  const [previousUrl, setPreviousUrl] = useState('');
  const location = useLocation();

  useEffect(() => {
    const currentPageUrl = window.location.href;
    // Update the previous URL state before the route changes
    return () => {
      setPreviousUrl(currentPageUrl);
    };
  }, [location]);

  return (
    <PreviousUrlContext.Provider value={previousUrl}>
      {children}
    </PreviousUrlContext.Provider>
  );
};

export const usePreviousUrl = () => useContext(PreviousUrlContext);